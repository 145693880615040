import { Injectable } from '@angular/core';
import { IntegrationsService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { GetIntegrationsDetails } from './integrations.action';

export class IntegrationsStateInfo {
  id?: number;
  integrationsData?: Array<any>;
}

@State<IntegrationsStateInfo>({
  name: 'integrations',
  defaults: {
    id: 0,
    integrationsData: [],
  },
})
@Injectable()
export class IntegrationsState {
  constructor(private integrationsService: IntegrationsService) {}

  @Action(GetIntegrationsDetails)
  getIntegrationsDetails(
    { patchState }: StateContext<IntegrationsStateInfo>,
    action: GetIntegrationsDetails
  ) {
    return this.integrationsService.getIntegrationsDetails().pipe(
      tap((res) => {
        patchState({
          integrationsData: res.valueResult,
        });
      })
    );
  }
}
