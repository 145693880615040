import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationHeader, NotificationTextMessage } from '@app/core/enums';
import { GlobalComponent, PaymentCardModel } from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import { LocalStorageService } from '@app/core/services';
import {
  SavePaymentCardDetails,
  SaveSmePaymentCardDetails,
  UpdatePaymentCardDetails,
  UpdateSmePaymentCardDetails,
} from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-card-form',
  templateUrl: './payment-card-form.component.html',
  styleUrls: ['./payment-card-form.component.scss'],
})
export class PaymentCardFormComponent implements OnInit {
  cardDetails: PaymentCardModel;
  paymentCardForm: FormGroup;
  paymentCardId: string;
  cardType = 0;
  isValidCard = true;
  showSetAsPrimary = true;
  isValidPeriod = true;
  isSME: boolean;

  environment = environment;

  @Output()
  readonly togglePaymentCardDetails = new EventEmitter<any>();

  constructor(
    public commonService: CommonService,
    private store: Store,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public globalComponent: GlobalComponent,
    private notifier: NotificationService,
    public storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');

    this.setForm();
  }

  setForm(): void {
    this.paymentCardForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      cardNumber: new FormControl('', [Validators.required]),
      cardPeriod: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(0[1-9]|1[0-2])\/?(\d{4}|\d{2})$/),
      ]),
      cvvNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(3),
        Validators.pattern(/^\d{3}$/),
      ]),
      setAsPrimary: new FormControl(true),
    });
  }

  setCardType(cardNumber: any): void {
    let visaRegEx = /^(?:4\d{12}(?:\d{3})?)$/;
    let mastercardRegEx = /^(?:5[1-5]\d{14})$/;
    let twoSerMasterRegx =
      /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let amexpRegEx = /^(?:3[47]\d{13})$/;

    let str = cardNumber.replace(/\s/g, '');

    this.isValidCard = false;
    if (visaRegEx.test(str)) {
      this.cardType = 1;
      this.isValidCard = true;
    } else if (mastercardRegEx.test(str) || twoSerMasterRegx.test(str)) {
      this.cardType = 2;
      this.isValidCard = true;
    } else if (amexpRegEx.test(str)) {
      this.isValidCard = true;
      this.cardType = 3;
    }
  }

  checkValidityPeriod(event: any): void {
    let cardPeriod = this.paymentCardForm.controls.cardPeriod.value.split('/');
    this.isValidPeriod =
      +cardPeriod[1] >= +new Date().getFullYear().toString().slice(-2);
  }

  checkValidCardNumber(event: any) {
    let cardNumber = event.target.value;

    this.setCardType(cardNumber);

    if (
      event.keyCode != 8 &&
      event.keyCode != 46 &&
      event.keyCode != 37 &&
      event.keyCode != 39
    ) {
      this.paymentCardForm.controls.cardNumber.setValue(
        cardNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')
      );
    }

    if (this.cardType == 3) {
      let str = cardNumber.replace(/\s/g, '');

      let x = str
        .substring(0, 4)
        .replace(/\W/gi, '')
        .replace(/(.{4})/g, '$1 ');

      let y = str
        .substring(4, 10)
        .replace(/\W/gi, '')
        .replace(/(.{6})/g, '$1 ');

      let z = str
        .substring(10)
        .replace(/\W/gi, '')
        .replace(/(.{5})/g, '$1 ');

      this.paymentCardForm.controls.cardNumber.setValue(x + y + z);
    }
    if (
      this.paymentCardForm.controls.cvvNumber.value != '' &&
      this.paymentCardForm.controls.cvvNumber.value != null
    ) {
      this.checkValidCVVNumber();
    }
  }

  checkValidCVVNumber() {
    if (this.cardType == 3) {
      this.paymentCardForm.controls.cvvNumber.setValidators(
        Validators.maxLength(4)
      );
    } else {
      this.paymentCardForm.controls.cvvNumber.setValidators(
        Validators.maxLength(3)
      );
    }

    this.paymentCardForm.controls.cvvNumber.updateValueAndValidity();
  }

  dataSubmit(): boolean {
    this.spinner.show();

    let cardPeriod = this.paymentCardForm.controls.cardPeriod.value.split('/');

    if (+cardPeriod[1] < +new Date().getFullYear().toString().slice(-2)) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.invalidPeriodicYear);
      return false;
    }

    try {
      if (this.paymentCardId === undefined) {
        this.cardDetails = {
          customerId: this.globalComponent.customerId(),
          firstName: this.paymentCardForm.controls.firstName.value,
          lastName: this.paymentCardForm.controls.lastName.value,
          setAsPrimary: this.paymentCardForm.controls.setAsPrimary.value,
          cardNumber: this.paymentCardForm.controls.cardNumber.value,
          cardCvv: this.paymentCardForm.controls.cvvNumber.value.toString(),
          cardExpiryYear: cardPeriod[1],
          cardExpiryMonth: cardPeriod[0],
        };
      } else {
        this.cardDetails = {
          paymentSourceId: this.paymentCardId,
          firstName: this.paymentCardForm.controls.firstName.value,
          lastName: this.paymentCardForm.controls.lastName.value,
          cardExpiryYear: cardPeriod[1],
          cardExpiryMonth: cardPeriod[0],
        };
      }
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  saveCardDetails(): void {
    let actionName;
    if (!this.isSME) {
      switch (this.paymentCardId === undefined) {
        case true:
          actionName = SavePaymentCardDetails;
          break;

        case false:
          actionName = UpdatePaymentCardDetails;
          break;
      }
    } else {
      switch (this.paymentCardId === undefined) {
        case true:
          actionName = SaveSmePaymentCardDetails;
          break;

        case false:
          actionName = UpdateSmePaymentCardDetails;
          break;
      }
    }

    if (actionName !== undefined) {
      this.store.dispatch(new actionName(this.cardDetails)).subscribe(
        (res) => {
          if (res.paymentMethod.isAddedSuccessful) {
            this.commonService.onSucess(
              this.paymentCardId === undefined
                ? NotificationTextMessage.cardSuccessMessage
                : NotificationTextMessage.cardUpdatedSuccessMessage
            );
            this.cancel();
          } else {
            this.commonService.onFailure(res.paymentMethod.errorMessage);
            this.paymentCardForm.controls.cardNumber.markAsTouched();
          }
        },
        (error) => {
          this.notifier.error(
            NotificationHeader.error,
            NotificationTextMessage.errorMessage + error.valueResult
          );
        }
      );
    }
  }

  send(): void {
    if (this.paymentCardForm.invalid || !this.isValidCard) {
      this.commonService.addValidation(this.paymentCardForm);
    } else {
      if (this.dataSubmit()) {
        this.saveCardDetails();
      }
    }
  }

  cancel(): void {
    this.togglePaymentCardDetails.emit();
  }
}
