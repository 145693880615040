import { Component, Input,Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NotificationTextMessage, modulesName } from '@app/core/enums';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  SendEmailForBookDemoModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import { SendEmailForBookADemo } from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @ViewChild('selectmodulename') selectmodulename: MatSelect;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  
  contactForm: FormGroup;
  sendEmailForBookADemo: SendEmailForBookDemoModel;
  environment = environment;
  userData: any;
  allSelected = false;

  moduleList = modulesName;
  selectedFile: File[] = [];
  isImageSelected = false;
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  fileList: any[] = [];
  selectedFiles: FileList;
  filesForm: FormGroup;
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;

  @Input()
  moduleId: Observable<any>;

  selectModuleId = 1;

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private store: Store,
    public globalComponent: GlobalComponent,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.setForm();

    this.moduleId?.subscribe((moduleId) => {
      this.selectModuleId = moduleId;
      this.contactForm.controls.modulename.setValue([this.selectModuleId]);
      this.contactForm.controls.modulename.disable();
    });
  }

  cancelForm(): void {
    this.contactForm.reset();
    this.contactForm.controls.customerid.setValue(this.userData?.CustomerId);
    this.contactForm.controls.customername.setValue(this.userData?.name);
    this.contactForm.controls.emailid.setValue(this.userData?.email);
    this.contactForm.controls.modulename.setValue([this.selectModuleId]);
    this.selectedFile = [];
    this.fileList = [];
  }
  connectWithUsCancelForm(): void {
    this.contactForm.reset();
    this.selectModuleId = 1;
    this.contactForm.controls.customerid.setValue(this.userData?.CustomerId);
    this.contactForm.controls.customername.setValue(this.userData?.name);
    this.contactForm.controls.emailid.setValue(this.userData?.email);
    this.contactForm.controls.modulename.setValue([this.selectModuleId]);
    this.contactForm.controls.modulename.enable();
   
  }

  setForm(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    this.userData = decoded;

    this.contactForm = new FormGroup({
      customerid: new FormControl(
        this.userData?.CustomerId,
        Validators.required
      ),
      customername: new FormControl(this.userData?.name, Validators.required),
      modulename: new FormControl(1, Validators.required),
      emailid: new FormControl(this.userData?.email, [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      phonenumber: new FormControl('', [Validators.required]),
      msg: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
    });

    this.contactForm.controls.customerid.disable();
    this.contactForm.controls.customername.disable();
    this.contactForm.controls.emailid.disable();
  }

  sendEmail(): void {
    
    if (this.contactForm.invalid) {
      this.commonService.addValidation(this.contactForm);
    } else if (this.dataSubmit()) {
      
      this.store
        .dispatch(new SendEmailForBookADemo(this.sendEmailForBookADemo))
        .subscribe(
          (x) => {
            
            if (x.product.isEmailSendForBookDemo) {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
              this.cancelForm();
              this.formSubmitted.emit(true);
            
            } else {
            
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );                         
            }
          },
          (error) => {                   
            this.spinner.hide();
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        );
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.selectmodulename.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectmodulename.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  addonsClick() {
    let newStatus = true;

    this.selectmodulename.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.sendEmailForBookADemo = {
        name: this.contactForm.controls.customername.value,
        emailId: this.contactForm.controls.emailid.value,
        phoneNumber: this.contactForm.controls.phonenumber.value,
        clientName: this.contactForm.controls.customername.value,
        supportTopic: 'Support Group',
        modules: this.contactForm.controls.modulename.value,
        subject: this.contactForm.controls.subject.value,
        message: this.contactForm.controls.msg.value,
        externalUserGuid:  this.userData.ExternalUserGUID,
        file: this.selectedFile,
      };
      console.log(this.sendEmailForBookADemo);    
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);     
      
      return false;
    }
    return true;
  }

  onBookADemoClick(): void {
    window.open(
      `https://liveengage247.myclients.io/site/6a8450de184e8b36/online-scheduling?action=etfv4etpwqb12giy`,
      '_blank'
    );
  }

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
        ) {
          if (file.size / 1024 / 1024 < 2) {
            this.fileList.push(file);
            this.selectedFile = this.fileList;
          } else {
            this.commonService.onFailure(NotificationTextMessage.fileSize2mb);
          }
        } else {
          this.commonService.onFailure(NotificationTextMessage.fileJpgPng);
        }
      });
    }
  }

  onCancel(): void {
    this.filesForm.reset();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  removeFile(index: any): void {
    this.fileList.splice(index, 1);
    this.fileUploadResponse.splice(index, 1);
  }
}
