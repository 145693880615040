import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payment-address-form',
  templateUrl: './payment-address-form.component.html',
  styleUrls: ['./payment-address-form.component.scss'],
})
export class PaymentAddressFormComponent {
  paymentAddressForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  setForm(): void {
    this.paymentAddressForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      country: [''],
      address: [''],
      city: [''],
      state: [''],
      postcode: [''],
    });
  }
}
