import { Injectable } from '@angular/core';
import { HighLightModel } from '@app/core/models/common/side-list';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HighlightRow {
  mainListHighlighted: HighLightModel;
  sideListHighlighted: HighLightModel;
  defaultColor: Subject<string>;

  constructor() {
    this.sideListHighlighted = new HighLightModel();
    this.mainListHighlighted = new HighLightModel();
    this.defaultColor = new Subject<string>();
  }
}
