import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationTextMessage } from '@app/core/enums';
import { GlobalComponent, PaymentBankModel } from '@app/core/models';
import { CommonService, LocalStorageService } from '@app/core/services';
import {
  GetCountryList,
  SaveBankDetails,
  SaveSmeBankDetails,
  UpdateBankDetails,
  UpdateSmeBankDetails,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, EMPTY } from 'rxjs';

@Component({
  selector: 'app-payment-bank-form',
  templateUrl: './payment-bank-form.component.html',
  styleUrls: ['./payment-bank-form.component.scss'],
})
export class PaymentBankFormComponent implements OnInit {
  bankDetails: PaymentBankModel;
  bankForm: FormGroup;
  paymentBankId = '';
  countryList: any;
  isSME: boolean;

  showSetAsPrimary = true;
  isCompany = false;

  @Output()
  readonly togglePaymentBankDetails = new EventEmitter<any>();

  constructor(
    public commonService: CommonService,
    private store: Store,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');
    this.setForm();
  }

  setForm(): void {
    this.bankForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      companyName: new FormControl(),
      email: new FormControl(
        '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ),
      country: new FormControl('', [Validators.required]),
      accountNumber: new FormControl('', [Validators.required]),
      branchCode: new FormControl(),
      setAsPrimary: new FormControl(false),
    });

    this.getCountryList();
  }

  getCountryList(): void {
    this.store.dispatch(new GetCountryList()).subscribe((x) => {
      this.countryList = x.paymentMethod.countryList;
      this.bankForm.controls.country.setValue('GB');
    });
  }

  isCompanySelected(event: any): void {
    this.isCompany = event.checked;

    if (this.isCompany) {
      this.bankForm.controls.firstName.clearValidators();
      this.bankForm.controls.lastName.clearValidators();
      this.bankForm.controls.companyName.setValidators([Validators.required]);
    } else {
      this.bankForm.controls.companyName.clearValidators();
      this.bankForm.controls.firstName.setValidators([Validators.required]);
      this.bankForm.controls.lastName.setValidators([Validators.required]);
    }

    this.bankForm.controls.firstName.updateValueAndValidity();
    this.bankForm.controls.lastName.updateValueAndValidity();
    this.bankForm.controls.companyName.updateValueAndValidity();
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      if (this.paymentBankId === undefined || this.paymentBankId === '') {
        this.bankDetails = {
          customerId: this.globalComponent.customerId(),
          firstName: this.bankForm.controls.firstName.value,
          lastName: this.bankForm.controls.lastName.value,
          setAsPrimary: this.bankForm.controls.setAsPrimary.value,
          companyName: this.bankForm.controls.companyName.value,
          issuingCountry: this.bankForm.controls.country.value,
          email: this.bankForm.controls.email.value,
          accountNumber: this.bankForm.controls.accountNumber.value,
          branchCode: this.bankForm.controls.branchCode.value,
          iscompany: this.isCompany,
        };
      } else {
        this.bankDetails = {
          paymentSourceId: this.paymentBankId,
          firstName: this.bankForm.controls.firstName.value,
          lastName: this.bankForm.controls.lastName.value,
          email: this.bankForm.controls.email.value,
        };
      }
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  saveBankDetails(): void {
    let actionName;
    if (!this.isSME) {
      switch (this.paymentBankId === undefined || this.paymentBankId === '') {
        case true:
          actionName = SaveBankDetails;
          break;

        case false:
          actionName = UpdateBankDetails;
          break;
      }
    } else {
      switch (this.paymentBankId === undefined || this.paymentBankId === '') {
        case true:
          actionName = SaveSmeBankDetails;
          break;

        case false:
          actionName = UpdateSmeBankDetails;
          break;
      }
    }

    if (actionName !== undefined) {
      this.store
        .dispatch(new actionName(this.bankDetails))
        .pipe(
          catchError(() => {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            return EMPTY;
          })
        )
        .subscribe((res) => {
          if (res.paymentMethod.isAddedSuccessful) {
            this.commonService.onSucess(
              this.paymentBankId === undefined || this.paymentBankId === ''
                ? NotificationTextMessage.bankSuccessMessage
                : NotificationTextMessage.bankUpdatedSuccessMessage
            );
            this.togglePaymentBankDetails.emit();
          } else {
            this.commonService.onFailure(res.paymentMethod.errorMessage);
          }
        });
    }
  }

  save(): void {
    if (this.bankForm.invalid) {
      this.commonService.addValidation(this.bankForm);
    } else {
      if (this.dataSubmit()) {
        this.saveBankDetails();
      }
    }
  }

  cancel(): void {
    this.togglePaymentBankDetails.emit();
  }
}
