export enum CardName {
  UnKnown = 0,
  Visa = 1,
  Mastercard = 2,
  AmericanExpress = 3,
  Discover = 4,
  Jcb = 5,
  DinersClub = 6,
  Other = 7,
  Bancontact = 8,
  NotApplicable = 9,
}
