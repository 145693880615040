<div class="heading mb-2">
  <h3>Add New Billing Address</h3>
</div>
<div class="card payment-address-form form-width">
  <div class="mat-dialog-wrapper form-list-content pb-0">
    <div class="contact-title">
      <h2>Enter New Billing Address</h2>
    </div>
    <form class="mt-1" [formGroup]="paymentAddressForm">
      <div>
        <div class="d-flex gap-30">
          <div class="form-field flex-1">
            <p>First Name</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="firstName"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="form-field flex-1">
            <p>First Name</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="lastName"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-30">
          <div class="form-field flex-1">
            <p>Country</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="country"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="form-field flex-1">
            <p>Address</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="address"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-30">
          <div class="form-field flex-1">
            <p>City</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="city"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="form-field flex-1">
            <p>State</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="state"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-30">
          <div class="form-field flex-1">
            <p>Postcode</p>
            <mat-form-field class="wp-100">
              <input
                matInput
                currencyMask
                formControlName="postcode"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-field">
          <mat-checkbox class="p-0"
            >Set this billing address as default
          </mat-checkbox>
        </div>
      </div>
    </form>
    <div class="contact-button text-align-center">
      <button
        class="action-button primary-button mr-1"
        mat-button
        disableRipple
      >
        Save
      </button>
      <button class="action-button secondary-button" mat-button disableRipple>
        Cancel
      </button>
    </div>
  </div>
</div>
