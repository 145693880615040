import { Component } from '@angular/core';
import { GetIntegrationsDetails } from '@app/core/store';
import { Store } from '@ngxs/store';
import { environment } from '@environments/environment';

export interface IntegrationsPeriodicElement {
  email: string;
  date: string;
  default: string;
  action: string;
}

const ELEMENT_DATA: IntegrationsPeriodicElement[] = [
  {
    email: 'john.capium@gmail.com',
    date: '09 Jan 2023',
    default: '',
    action: '',
  },
  {
    email: 'john.capium@gmail.com',
    date: '09 Jan 2023',
    default: '',
    action: '',
  },
  {
    email: 'john.capium@gmail.com',
    date: '09 Jan 2023',
    default: '',
    action: '',
  },
];

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss'],
})
export class IntegrationsListComponent {
  integrationList: any[];
  environment = environment;
  constructor(private store: Store) {}

  displayedColumns: string[] = ['email', 'date', 'default', 'action'];
  dataSource = ELEMENT_DATA;

  showForm = true;
  showGmailAccounts = false;
  showOutlookAccounts = false;
  showStripeAccounts = false;

  showGmailAccount() {
    this.showGmailAccounts = !this.showGmailAccounts;
  }

  showOutlookAccount() {
    this.showOutlookAccounts = !this.showOutlookAccounts;
  }

  showStripeAccount() {
    this.showStripeAccounts = !this.showStripeAccounts;
  }

  getList(): void {
    this.store.dispatch(new GetIntegrationsDetails()).subscribe((res) => {
      this.integrationList = res.integrations.integrationsData;
    });
  }
}
