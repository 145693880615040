<div class="payment-card-details" *ngIf="!addPaymentCard && !addBankDetails">
  <div>
    <div class="d-flex justify-space-between align-items-center heading">
      <div>
        <h3 class="no-border mb-0">Saved Credit / Debit Cards</h3>
      </div>
      <button mat-button class="primary-button mb-1" (click)="onAddCardClick()">
        <mat-icon>add</mat-icon>
        Add New Card
      </button>
    </div>

    <div class="payment-card-wrap">
      <div
        *ngFor="let item of paymentMethodList; let i = index"
        class="payment-card"
      >
        <div class="d-flex align-items-center gap-10 wp-33">
          <div *ngIf="cardNameEnum.AmericanExpress === item.card.brand">
            <img
              src="{{ environment.imagePath }}american-express.svg"
              alt="American Express"
            />
          </div>

          <div *ngIf="cardNameEnum.Visa === item.card.brand">
            <img src="{{ environment.imagePath }}/visa.svg" alt="Visa" />
          </div>

          <div *ngIf="cardNameEnum.Mastercard === item.card.brand">
            <img
              src="{{ environment.imagePath }}/master-card.svg"
              alt="Master Card"
            />
          </div>
        </div>
        <div class="wp-33 pl-4 ml-4">
          <p class="m-0 font-size-16">{{ item.card.maskedNumber }}</p>
        </div>
        <div class="wp-33 pl-4 ml-4">
          <p class="m-0 font-size-16">
            {{ item.card.expiryMonth }}/{{ item.card.expiryYear }}
          </p>
        </div>

        <div class="w-200 ml-4">
          <div class="action-buttons text-align-right">
            <button
              type="button"
              mat-button
              disableRipple
              (click)="onEditCardClick(item, true)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              type="button"
              mat-button
              disableRipple
              (click)="onDeleteCardClick(item)"
            >
              <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="norecord-data text-align-center mt-1"
      *ngIf="paymentMethodList.length <= 0"
    >
      <img src="assets/img/cardimage.svg" alt="Empty Cart" width="150px" />
      <h2 class="pt-1">No Credit / Debit card added.</h2>
      <p>Looks like you have not added any credit/debit card yet!</p>
    </div>
  </div>
  <div class="mt-2">
    <div class="d-flex justify-space-between align-items-center heading">
      <div>
        <h3 class="no-border mb-0">Direct Debit (Saved Banks)</h3>
      </div>
      <button mat-button class="primary-button mb-1" (click)="onAddBankClick()">
        <mat-icon>add</mat-icon>
        Add New Bank
      </button>
    </div>

    <div class="payment-card-wrap">
      <div *ngFor="let item of bankList" class="payment-card">
        <div class="d-flex align-items-center gap-10 wp-33">
          <img src="{{ environment.imagePath }}/barclays.svg" alt="Bank Logo" />
        </div>
        <div class="wp-33 pl-4 ml-4">
          <p class="m-0 font-size-16">{{ item.bankAccount.bankName }}</p>
        </div>
        <div class="wp-33 pl-4 ml-4">
          <p class="m-0 font-size-16">{{ item.bankAccount.last4 }}</p>
        </div>
        <div class="w-200 ml-4">
          <div class="action-buttons text-align-right">
            <button
              type="button"
              mat-button
              disableRipple
              (click)="onDeleteBankClick(item)"
            >
              <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="norecord-data text-align-center mt-1"
      *ngIf="bankList.length <= 0"
    >
      <img src="assets/img/bankimage.svg" alt="Empty Cart" width="120px" />
      <h2 class="pt-1 pr-3">No Bank account added</h2>
      <p class="pr-3">Looks like you have not added any bank account yet!</p>
    </div>
  </div>
</div>
<div *ngIf="addPaymentCard">
  <app-payment-card-form
    (togglePaymentCardDetails)="togglePaymentCardDetails()"
  ></app-payment-card-form>
</div>

<div *ngIf="addBankDetails">
  <app-payment-bank-form
    (togglePaymentBankDetails)="togglePaymentBankDetails()"
  ></app-payment-bank-form>
</div>
