import { PayNowModel } from '@app/core/models';

export class GetSmeProductTabDetails {
  static readonly type = '[smeproduct] Get Retrive Product';
}

export class GetSmeProductList {
  static readonly type = '[smeproduct] Get Retrive item List';
  constructor(public id: string, public frequency: number) {}
}

export class PayNowForSmeSubscription {
  static readonly type = '[smeproduct] Pay Now For Subscribtion';
  constructor(public payNowData: PayNowModel) {}
}

export class UpgradePlanForSmeSubscription {
  static readonly type = '[smeproduct] Upgrade Sme Subscription Plan';
  constructor(public param: any) {}
}
export class GetSmeSubscriptionPortalDetails {
  static readonly type = '[smeproduct] Get Sme Subscription Portal Details';
}
