export enum PeriodUnitType {
  Month = 3,
  Year = 4,
  Quarterly = 5,
}

export enum ProductTrailType {
  TrailPeriodUnitType = 1,
}

export enum PeriodUnitTextShort {
  Month = 'pm',
  Year = 'py',
  Quarterly = 'qty',
}

export enum PeriodUnitText {
  Month = 'Monthly Subscriptions',
  Year = 'Yearly Subscriptions',
  Quarterly = 'Quarterly Subscriptions',
}
