import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GlobalComponent,
  PaymentBankModel,
  PaymentCardModel,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    public globalComponent: GlobalComponent
  ) {}

  getPaymentList(): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }PaymentMethods/ListofCustomerPaymentSourceAsync?customerId=${this.globalComponent.customerId()}`
    );
  }

  getSmePaymentList(): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }SmeProducts/ListofSmeCustomerPaymentSourceAsync?customerId=${this.globalComponent.customerId()}`
    );
  }

  savePaymentCardDetails(queryParams: PaymentCardModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}PaymentMethods/CreateCustomerPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  updatePaymentCardDetails(queryParams: PaymentCardModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}PaymentMethods/UpdateCustomerPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  deletePaymentCard(paymentCardId: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiVersionUrl}PaymentMethods/DeleteCustomerPaymentSource?customerPaymentSourceId=${paymentCardId}`
    );
  }

  retrieveHostedPage(
    paymentMethodId: string,
    subscriptionId: string,
    isTrailPeriod: boolean,
    IsCancelTrial:boolean
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Products/RetrieveHostedPage?hostedPageId=${paymentMethodId}&SubscriptionId=${subscriptionId}&isTrial=${isTrailPeriod}&IsCancelTrial=${IsCancelTrial}`
    );
  }

  retrieveSmeHostedPage(
    paymentMethodId: string,
    subscriptionId: string,
    isTrailPeriod: boolean
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}SmeProducts/RetrieveSmeHostedPage?hostedPageId=${paymentMethodId}&SubscriptionId=${subscriptionId}&isTrial=${isTrailPeriod}`
    );
  }

  saveBankDetails(queryParams: PaymentBankModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}PaymentMethods/CreateCustomerBankPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  updateBankDetails(queryParams: PaymentBankModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}PaymentMethods/UpdateCustomerBankPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getCountryList(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Communication/GetCountryMaster`
    );
  }

  saveSmePaymentCardDetails(queryParams: PaymentCardModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/CreateSmeCustomerPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  updateSmePaymentCardDetails(queryParams: PaymentCardModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/UpdateSmeCustomerPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  deleteSmePaymentCard(paymentCardId: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiVersionUrl}SmeProducts/DeleteSmeCustomerPaymentSource?customerPaymentSourceId=${paymentCardId}`
    );
  }

  saveSmeBankDetails(queryParams: PaymentBankModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/CreateSmeCustomerBankPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }

  updateSmeBankDetails(queryParams: PaymentBankModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/UpdateSmeCustomerBankPaymentSource`,
      JSON.stringify(queryParams),
      headers
    );
  }
}
