export enum FilteredStatus {
  SelectAll = -1,
  Pending = 0,
  Paid = 1,
  Fail = 2,
}

export enum PaymentMethod {
  SelectAll = -1,
  Bank = 0,
  Cash = 1,
  Cheque = 2,
  Card = 3,
  Paypal = 4,
  Other = 5,
}

export enum PeriodicYear {
  ThisWeek = 1,
  Lastweek = 2,
  ThisMonth = 3,
  LastMonth = 4,
  ThisQuarter = 5,
  LastQuarter = 6,
  ThisYear = 7,
  LastYear = 8,
  Custom = 9,
}
