export enum PlanShortDescription {
  Small = 'To begin <br /> your journey',
  Medium = 'The journey continues <br /> to flourish',
  Large = 'Aiming for <br /> the stars',
}

export enum PlanFullDescription {
  Small = 'For the ambitious accountant whose  <br/> practice starts here',
  Medium = 'For the established and growing <br/>  accountancy practice',
  Large = 'For the firm whose success is inevitable',
}
