<div class="header">
  <div class="d-flex align-items-center justify-space-between wp-100">
    <div class="d-flex align-items-center">
      <div class="logo-container">
        <a>
          <img
            class="report-landing-hero-image"
            [src]="ecoSpaceLogo"
            alt="logo"
          />
        </a>
      </div>
      <div class="nav-container">
        <div class="navigation-menu">
          <div *ngFor="let menu of menuData">
            <a routerLinkActive="active" routerLink="{{ menu.url }}">
              <mat-icon fontSet="material-icons-outlined">{{
                menu.icon
              }}</mat-icon>
              <span>{{ menu.name }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="account-container">
      <div class="account-menu">
        <ul class="d-flex align-items-center">
          <li>
            <a
              routerLinkActive="active"
              routerLink="/home/products-cart"
              class="pr-10"
              title="Cart"
            >
              <mat-icon
                fontSet="material-icons-outlined"
                matBadge="{{ totalCartItem }}"
                matBadgeOverlap="false"
                >shopping_cart</mat-icon
              >
            </a>
          </li>
          <li class="separation pl-1">
            <a>
              <mat-icon title="Announcement">campaign</mat-icon>
            </a>
          </li>
          <li class="module-list">
            <a
              href="javascript:void(0)"
              [matMenuTriggerFor]="capiumMenu"
              title="Modules"
            >
              <div class="m-0">
                <img [src]="favIcon" width="22" height="22" alt="Capium Logo" />
              </div>
            </a>
            <mat-menu
              #capiumMenu="matMenu"
              class="action-menu dropdown-module-list"
              xPosition="after"
            >
              <div class="justify-content-between">
                <ul>
                  <ng-container
                    *ngFor="let subscriptionModule of subscriptionModuleList"
                  >
                    <li
                      class=""
                      [ngClass]="
                        subscriptionModule.isDisabled ? 'disable-box' : ''
                      "
                      *ngIf="subscriptionModule.isDisplay"
                    >
                      <a
                        [href]="appUrl ? appUrl : '#'"
                        (click)="redirectToModule(subscriptionModule, $event)"
                      >
                        <div class="top-card d-flex gap-10">
                          <img [src]="subscriptionModule.iconSrc" alt="logo" />
                          <div>
                            <h6>{{ subscriptionModule.externalName }}</h6>
                            <p>{{ subscriptionModule.content }}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ng-container>
                </ul>
                <div class="top-switch-button text-align-right">
                  <button
                    type="button"
                    class="action-button primary-button"
                    mat-button
                    disableRipple
                    (click)="redirectToHome()"
                  >
                    <img
                      src="assets/img/capihome.svg"
                      class="pr-5"
                      alt="Icon"
                    />
                    <span>Home </span>
                  </button>
                  <button
                    *ngIf="!isSME"
                    type="button"
                    class="action-button secondary-button ml-1"
                    mat-button
                    disableRipple
                    (click)="myAdminClick()"
                  >
                    <img src="assets/img/myadmin.svg" class="pr-5" alt="Icon" />
                    <span>My Admin</span>
                  </button>
                </div>
              </div>
            </mat-menu>
          </li>          
          <li>
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              title="Help"
              (click)="onHelpClick()"
            >
              <mat-icon>help_outline</mat-icon>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" [matMenuTriggerFor]="profileInfo">
              <div *ngIf="profileUrl !== null">
                <img
                  class="profile-image-thumb"
                  [src]="profileUrl"
                  alt="User photo"
                  title="User Image"
                />
              </div>
              <div
                *ngIf="profileUrl === null && userData?.name"
                class="rounded-border m-0"
                title="User Image"
              >
                {{ userData?.name | shortName: 2 }}
              </div>
            </a>
            <mat-menu
              #profileInfo="matMenu"
              class="action-menu profile-setting-menu"
              xPosition="after"
            >
              <div class="text-align-center profile-settings">
                <div class="mb-1" *ngIf="profileUrl !== null">
                  <img
                    class="profile-image"
                    [src]="profileUrl"
                    alt="User photo"
                  />
                </div>
                <div
                  *ngIf="profileUrl === null && userData?.name"
                  class="rounded-border"
                >
                  {{ userData?.name | shortName: 2 }}
                </div>

                <div>
                  <p
                    class="fw-bold text-transform-uppercase text-gray-100 font-size-16 mb-10 m-0"
                  >
                    {{ userData?.name }}
                  </p>
                </div>
                <div>
                  <p class="text-gray-200 mb-10 m-0">
                    User ID: {{ userData?.CustomerId }}
                  </p>
                </div>
                <div>
                  <p class="text-gray-200 mb-1 m-0">{{ userData?.email }}</p>
                </div>
                <div class="hr pt-1">

                  <a href="javascript:void(0)" (click)="myAdminClick()" *ngIf="!isSME">
                    <span class="text-gray-100 font-size-16">My Account</span>
                  </a>
                  <a  *ngIf="isSME">
                    <span class="text-gray-100 font-size-16">My Account</span>
                  </a>
                  <span class="pl-2 pr-2 text-gray-100">|</span>
                  <a href="javascript:void(0)" (click)="logout()">
                    <span class="text-danger-100 font-size-16">Sign Out</span>
                  </a>
                </div>
                <div class="theme-colors pt-1">
                  <mat-radio-group class="mb-10 display-inline">
                    <mat-radio-button
                      *ngFor="let color of colors"
                      [value]="color"
                      [(ngModel)]="colorSelected"
                      ngDefaultControl
                      (change)="onSelectionChange($event.value, true)"
                      [ngClass]="{
                        'mat-radio-checked': colorSelected === color
                      }"
                    >
                      <button
                        class="theme-selector"
                        [ngStyle]="{ 'background-color': color }"
                        type="button"
                        mat-button
                        disableRipple
                      >
                        <mat-icon>check</mat-icon>
                      </button>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
