<div class="mt-1 product-list-wrap">
  <div class="card-wrapper mb-1">
    <div
      class="card position-relative pl-0 pr-0"
      *ngFor="let item of smeProductList"
    >
      <div class="card-head pl-1 pr-1">
        <div class="d-flex align-items-center gap-10">
          <img
            src="/assets/images/time n fee.svg"
            alt="icon"
          />
          <div>
            <p class="m-0">{{ item.externalName }}</p>
            <span>{{ item.description }}</span>
          </div>
        </div>
      </div>
      <div class="card-list">
        <ul class="pl-1 pr-1">
          <li>
            <mat-icon class="success mr-5">check_circle</mat-icon>
            {{ item.lineItems1 }}
          </li>
          <li>
            <mat-icon class="success mr-5">check_circle</mat-icon>
            {{ item.lineItems2 }}
          </li>
          <li>
            <mat-icon class="success mr-5">check_circle</mat-icon>
            {{ item.lineItems3 }}
          </li>
        </ul>
        <div class="connect-button">
          <div class="plan-details">
            <div
              class="connect-button pt-1 pb-1 pl-1 pr-1"
              (click)="onConnectClick(item)"
            >
              <div class="connect-button-warapper watch-button mb-1">
                <!-- when module is not Subscribe readonly button -->
                <button
                  class="action-button primary-button readonly-button lock-button"
                  mat-button
                  disableRipple
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >play_circle_filled</mat-icon
                  >
                  <span>WATCH NOW</span>
                </button>
              </div>
              <div>
                <p class="pt-1">
                  {{ item.price / 100 }} /{{ item.frequencyText }}
                </p>
              </div>
              <p
                class="pt-1 danger"
                *ngIf="isTrailPeriod && item.noOfTrailsDaysLeft > 0"
              >
                {{ item.noOfTrailsDaysLeft }}
                {{ item.noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
                {{ headerText }} ends on :
                {{
                  (isTrialSubscription ? trialEndDate : item.nextBillingDate)
                    | date
                }}
              </p>

              <p
                class="pt-1 danger"
                *ngIf="!isTrailPeriod && item.isModuleSubscribed"
              >
                {{ item.noOfTrailsDaysLeft }}
                {{ item.noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
                {{ item.selectedPlanName }} | Subscription due:
                {{ item.nextBillingDate | date }}
              </p>
              <p
                class="pt-1 danger"
                *ngIf="isTrialSubscription && item.noOfTrailsDaysLeft <= 0"
              >
                {{ headerText }} ended on :
                {{ item.nextBillingDate | date }}
              </p>
              <p
                class="pt-1 danger"
                *ngIf="!isTrialSubscription && isSMECancelled"
              >
                {{ headerText }} ended on :
                {{ item.nextBillingDate | date }}
              </p>

              <p
                *ngIf="
                  !isSMEPurchased && !isTrailPeriod && !item.isModuleSubscribed
                "
              >
                No Subscription Yet!
              </p>
            </div>

            <div class="card-foot" *ngIf="item.showPlans">
              <div
                *ngIf="!item.isAddedToCart"
                class="card-foot-button d-flex justify-space-between"
              >
                <button
                  class="action-button text-primary"
                  mat-button
                  (click)="onBackClick(item)"
                  class="action-button text-primary"
                  mat-button
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >arrow_back</mat-icon
                  >
                  <span>Go Back</span>
                </button>

                <div class="connect-button-warapper">
                  <button
                    (click)="addToCart(item)"
                    class="action-button primary-button"
                    mat-button
                  >
                    <mat-icon class="m-0">shopping_cart_checkout</mat-icon>
                    <span>Add to cart</span>
                  </button>
                </div>
              </div>

              <!-- if added to cart -->
              <div
                *ngIf="item.isAddedToCart"
                class="cart-button d-flex justify-space-between"
              >
                <button
                  class="action-button primary-button"
                  (click)="goToCart()"
                  mat-button
                >
                  <mat-icon class="m-0">shopping_cart_checkout</mat-icon>
                  <span>Go to cart</span>
                </button>
                <button
                  (click)="removeFromCart(item)"
                  class="action-button close-cart text-primary"
                  mat-button
                >
                  <mat-icon>close</mat-icon>
                  <span>Cancel</span>
                </button>
              </div>
            </div>
            <div
              class="card-foot"
              *ngIf="(!item.showPlans && isTrailPeriod) || noSubscription"
            >
              <button
                *ngIf="!item.showPlans"
                class="action-button primary-button gap-20 upgrade-btn"
                mat-button
                disableRipple
                (click)="onUpgradeClick(item)"
              >
                <mat-icon fontSet="material-icons-outlined" class="m-0"
                  >shopping_bag</mat-icon
                >
                <span *ngIf="isTrailPeriod"> Subscribe now</span>
                <span *ngIf="!isTrailPeriod"> Buy It Now </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
