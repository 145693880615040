import { Injectable } from '@angular/core';
import { CommonService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { EMPTY, switchMap, tap } from 'rxjs';
import { Preview, UpdateThemeColor } from './common.action';

export class CommonStateInfo {
  primaryColor: string;
  exported?: boolean;
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {
    primaryColor: '',
  },
})
@Injectable()
export class CommonState {
  constructor(public commonService: CommonService) {}

  @Action(Preview)
  preview({ patchState }: StateContext<CommonStateInfo>, action: Preview) {
    return this.commonService.export(action.exportParams, action.moduleId).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(UpdateThemeColor)
  updateThemeColor(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: UpdateThemeColor
  ) {
    return this.commonService.updateThemeColor(action.colorCode).pipe(
      tap((res) => {
        patchState({
          primaryColor: res,
        });
      })
    );
  }
}
