import { Injectable } from '@angular/core';
import { ProductTabDetails } from '@app/core/models';
import { SmeProductService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { tap } from 'rxjs';
import {   
  GetSmeProductList,
  GetSmeProductTabDetails,
  GetSmeSubscriptionPortalDetails,
  PayNowForSmeSubscription,
  UpgradePlanForSmeSubscription,
} from './sme-product.action';

export class SmeProductStateInfo {
  id?: number;
  smeSubscriptionPortalData?: Array<any>;
  smeproductDetailData?: any;
  payNowResposeURL?: any;
  smeproductList?: ProductTabDetails;
  isValidCoupon?: boolean;
  isProductDisabled?: boolean;
  isPlanUpgraded?: any;
  isEmailSendForBookDemo?: boolean;
  isCancelSuccessfully?: boolean;
  connectLinkList?: any;
}

@State<SmeProductStateInfo>({
  name: 'smeproduct',
  defaults: {
    id: 0,
    smeSubscriptionPortalData: [],
  },
})
@Injectable()
export class SmeProductState {
  constructor(private SmeproductService: SmeProductService) {}

  @Selector()
  static smesubscriptionPortalData(state: SmeProductStateInfo): Array<any> {
    return state.smeSubscriptionPortalData!;
  }

  @Selector()
  static smeproductDetailData(state: SmeProductStateInfo): Array<any> {
    return state.smeproductDetailData;
  }

  @Action(GetSmeProductTabDetails)
  GetSmeProductTabDetails({ patchState }: StateContext<SmeProductStateInfo>) {
    return this.SmeproductService.GetSmeProductTabDetails().pipe(
      tap((res) => {
        patchState({
          smeproductList: res.valueResult,
        });
      })
    );
  }

  @Action(GetSmeProductList)
  GetSmeProductList(
    { patchState }: StateContext<SmeProductStateInfo>,
    action: GetSmeProductList
  ) {
    return this.SmeproductService.GetSmeProductList(action.id, action.frequency).pipe(
      tap((res) => {       
        let SMEData: any = [];       
        if (res.valueResult.length > 0) {
          res.valueResult.forEach((element, index) => {
            if (res.valueResult.length >= index) {
              SMEData.push(element);
            }
          });
        }

        patchState({
          smeproductDetailData: SMEData,
        });
      })
    );
  }

  @Action(PayNowForSmeSubscription)
  PayNowForSmeSubscribtion(
    { patchState }: StateContext<SmeProductStateInfo>,
    action: PayNowForSmeSubscription
  ) {
    return this.SmeproductService.PayNowForSmeSubscription(action.payNowData).pipe(
      tap((res) => {
        patchState({
          payNowResposeURL: JSON.parse(res.valueResult.cnt).hosted_page.url,
        });
      })
    );
  }

  @Action(UpgradePlanForSmeSubscription)
  upgradePlanForSmeSubscription(
    { patchState }: StateContext<SmeProductStateInfo>,
    action: UpgradePlanForSmeSubscription
  ) {
    return this.SmeproductService.UpgradePlanForSmeSubscription(action.param).pipe(
      tap((res) => {
        patchState({
          payNowResposeURL: JSON.parse(res.valueResult.cnt).hosted_page.url,
        });
      })
    );
  }

  @Action(GetSmeSubscriptionPortalDetails)
  getSubscriptionPortalDetails(
    { patchState }: StateContext<SmeProductStateInfo>,
    action: GetSmeSubscriptionPortalDetails
  ) {
    return this.SmeproductService.GetSmeSubscriptionPortalDetails().pipe(
      tap((res) => {        
        patchState({
          smeSubscriptionPortalData: res.valueResult,
        });
      })
    );
  }
}
