import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AuthResolverService } from 'src/app/core/guards/auth.resolver';
import {
  ContactFormComponent,
  HomeComponent,
  PaymentSuccessfullComponent,
  ProductCartComponent,
  ProductsComponent,
} from '..';

const routes: Routes = [
  {
    path: 'home',
    // canActivate: [AuthGuard],
    component: HomeComponent,
    resolve: {
      result: AuthResolverService,
    },

    children: [
      {
        path: 'products',
        // canActivate: [AuthGuard],
        component: ProductsComponent,
        resolve: {
          result: AuthResolverService,
        },
      },

      {
        path: 'lets-connect',
        component: ContactFormComponent,
        resolve: {
          result: AuthResolverService,
        },
      },

      {
        path: 'paymentSuccess',
        component: PaymentSuccessfullComponent,
        canActivate: [AuthGuard],
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'products-cart',
        component: ProductCartComponent,
        canActivate: [AuthGuard],
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./subscription/subscription.module').then(
            (m) => m.SubscriptionModule
          ),
        //data: { preload: true },
      },
      {
        path: 'paymentmethods',
        loadChildren: () =>
          import('./paymentmethods/paymentmethods.module').then(
            (m) => m.PaymentmethodsModule
          ),
        // data: { preload: true },
      },
      {
        path: 'billingistory',
        loadChildren: () =>
          import('./billinghistory/billinghistory.module').then(
            (m) => m.BillinghistoryModule
          ),
        //  data: { preload: true },
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./integrations/integrations.module').then(
            (m) => m.IntegrationsModule
          ),
        //  data: { preload: true },
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./invoices/invoices.module').then((m) => m.InvoicesModule),
        // data: { preload: true },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
