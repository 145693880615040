<div class="sidenav-body-content pt-0 pb-0">
  <div class="contact-form mb-2">
    <div class="mat-dialog-wrapper form-list-content">
      <form class="mt-1" [formGroup]="contactForm">
        <p class="mb-1">
          We are here to help you. Tell us How can we help you!
        </p>
        <div>
          <div class="form-field">
            <p>Name <span class="text-danger-300">*</span></p>
            <mat-form-field class="w-400">
              <input
                matInput
                readonly
                currencyMask
                formControlName="customername"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Module <span class="text-danger-300">*</span></p>

            <mat-form-field class="w-400">
              <mat-select
                #selectmodulename
                formControlName="modulename"
                panelClass="mat-multiple-selection"
                multiple
              >
                <div class="select-all pl-5">
                  <mat-checkbox
                    [(ngModel)]="allSelected"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="toggleAllSelection()"
                    >Select All</mat-checkbox
                  >
                </div>

                <mat-option
                  (click)="addonsClick()"
                  *ngFor="let ad of moduleList | useEnumValues"
                  [value]="ad.key"
                  >{{ ad.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Email <span class="text-danger-300">*</span></p>
            <mat-form-field class="w-400">
              <input
                matInput
                currencyMask
                formControlName="emailid"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Subject<span class="text-danger-300">*</span></p>
            <mat-form-field class="w-400">
              <input
                matInput
                currencyMask
                formControlName="subject"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Phone <span class="text-danger-300">*</span></p>
            <mat-form-field class="w-400">
              <input
                type="text"
                maxlength="15"
                oninput="this.value = this.value.replace(/[^0-9\+]/g, '').replace(/(\.*)\./g, '$1');"
                matInput
                formControlName="phonenumber"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <div class="form-field textarea">
            <p>Message <span class="text-danger-300">*</span></p>
            <mat-form-field class="w-400">
              <textarea
                matInput
                formControlName="msg"
                autocomplete="off"
                required
                maxlength="500"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Attachments</p>
            <div class="form-field attached mb-10 w-400">
              <div
                class="attachFile"
                [ngClass]="isImageSelected ? 'hidden' : ''"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >cloud_upload</mat-icon
                >
                <input
                  multiple
                  type="file"
                  [accept]="acceptedFileTypes"
                  #fileupload
                  (click)="fileupload.value = ''"
                  autocomplete="off"
                  maxlength="100"
                  id="file"
                  (change)="selectFiles($event)"
                  class="cursor-pointer"
                />
                <label for="file" class="cursor-pointer">
                  <strong>Choose a file</strong> or drag it here to attach a
                  file with ticket.
                </label>
              </div>
            </div>
            <div class="font-size-12 w-400">
              (Max file size - 2 MB) | Supported formats : .doc, .docx, .pdf,
              .jpg, .png, .jpeg, .txt, .xls, .xlsx, .csv
            </div>
            <div class="note-table mt-10">
              <div [ngClass]="fileList.length === 0 ? '' : 'table-border'">
                <div
                  class="clickable d-flex align-items-center justify-space-between"
                  *ngFor="let file of fileList; let i = index"
                >
                  <div>{{ file.name }}</div>
                  <div class="close mt-5">
                    <a href="javascript:void(0)" (click)="removeFile(i)">
                      <mat-icon>close</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="contact-button d-flex justify-space-between align-items-center pt-1"
      >
        <div>
          <button
            class="action-button primary-button mr-1"
            mat-button
            disableRipple
            (click)="sendEmail()"
          >
            <span>Send</span>
          </button>
          <button
            class="action-button secondary-button"
            mat-button
            disableRipple
            (click)="cancelForm()"
          >
            <span>Cancel</span>
          </button>
        </div>
        <button
          class="action-button primary-button book-demo mr-1"
          mat-button
          disableRipple
          title="Book a Demo"
          (click)="onBookADemoClick()"
        >
          <span>Book a Demo</span>
        </button>
      </div>
    </div>
    <app-custom-notifications></app-custom-notifications>
  </div>
</div>
