<div class="container-width">
  <div *ngIf="showSubcription">   
    <div
      class="module-list-wrapper"
      *ngIf="
        subscriptionList.length > 0 ||
        addonsSubscriptionList.length > 0 ||
        SMEsubscriptionList.length > 0 ||
        capium365SubscriptionList.length > 0
      "
    >
      <div class="module-list-item-wrapper mt-1">
        <div class="sub-details">
          <div class="sub-title">
            <h2 class="mb-0">Welcome {{ userData?.name }}</h2>
            <p class="font-size-14 dark-gray mb-0">SUBSCRIPTIONS</p>
          </div>
          <div class="sub-plan-details d-none mb-1">
            <h2
              class="font-size-18 text-gray mb-1"
              *ngIf="subscriptionList.length > 0"
            >
              {{ headerText }}
              Plan
            </h2>
            <div *ngFor="let list of subscriptionList" class="sub-card">
              <div class="d-flex align-items-center gap-10 wp-33">
                <img
                  src="{{ commonService.getImagePath(list.itemPrice.id) }}"
                  alt="icon"
                />
                <div>
                  <p class="m-0 font-size-16 fw-bold">
                    {{ list.externalName.replace("Individual", "") }}
                  </p>
                  <span class="dark-gray font-size-14">
                    {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                    {{ list.clientCount }} Clients | £ {{ list.price / 100 }}
                    {{
                      getFrequencyText(
                        list.itemPrice.periodUnit,
                        list.itemPrice.period
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="wp-50">
                <p class="m-0 font-size-16 fw-bold" *ngIf="isTrail">
                  {{
                    getText(
                      isTrail ? list.endDate : list.nextBillingDate,
                      isTrail
                    )
                  }}
                  :
                  {{ (isTrail ? list.endDate : list.nextBillingDate) | date }}
                </p>
                <span class="dark-gray fw-bold font-size-16" *ngIf="isTrail"
                  >£ {{ list.price / 100 }}
                  {{
                    getFrequencyText(
                      list.itemPrice.periodUnit,
                      list.itemPrice.period
                    )
                  }}</span
                >
                <span
                  class="text-gray font-size-14"
                  *ngIf="!isTrail && !list.contractTerm.isContractTermApplied"
                >
                  <div class="plan-view">
                    <div>
                      <span class="fw-bold">
                        {{
                          getText(
                            isTrail ? list.endDate : list.nextBillingDate,
                            isTrail
                          )
                        }}
                        :</span
                      >
                      {{
                        (isTrail ? list.endDate : list.nextBillingDate) | date
                      }}
                    </div>
                  </div>
                </span>
                <span
                  class="text-gray font-size-14"
                  *ngIf="!isTrail && list.contractTerm.isContractTermApplied"
                >
                  <div class="plan-view">
                    <div>
                      <span class="fw-bold">Contract Start :</span>
                      {{ list.contractTerm.contractTermStartDate | date }}
                    </div>
                    <div>
                      <span class="fw-bold">Contract End :</span>
                      {{ list.contractTerm.contractTermEndDate | date }}
                    </div>
                    <div>
                      <span class="fw-bold">Contract Remaining Days :</span>
                      {{
                        getNoOfDayscalculator(
                          list.contractTerm.contractTermEndDate
                        )
                      }}
                    </div>
                    <div>
                      <span class="fw-bold">
                        {{
                          getText(
                            isTrail ? list.endDate : list.nextBillingDate,
                            isTrail
                          )
                        }}
                        :</span
                      >
                      {{
                        (isTrail ? list.endDate : list.nextBillingDate) | date
                      }}
                    </div>
                    <div>
                      <span class="fw-bold">Total Billing Cycles :</span>
                      {{ list.contractTerm.totalBillingCycles }}
                    </div>
                    <div>
                      <span class="fw-bold">Remaining Billing Cycles :</span>
                      {{ list.contractTerm.remainingBillingCycles }}
                    </div>
                  </div>
                </span>
              </div>
              <div class="text-align-right w-150">
                <button
                  [ngClass]="
                    list.status === 3 ? 'success-button' : 'danger-button'
                  "
                  class="primary-button cursor-default"
                  mat-button
                  disableRipple
                >
                  <span
                    >{{ getStatus(list.status, list.nextBillingDate) }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div
            *ngIf="addonsSubscriptionList.length > 0"
            class="sub-plan-details d-none"
          >
            <h2 class="font-size-18 text-gray mb-1">Capium Addons Plan</h2>
            <div *ngFor="let list of addonsSubscriptionList" class="sub-card">
              <div class="d-flex align-items-center gap-10 wp-33">
                <img
                  src="{{ commonService.getImagePath(list.itemPrice.id) }}"
                  alt="icon"
                />
                <div>
                  <p class="m-0 font-size-16 fw-bold">
                    {{ list.externalName.replace("Individual", "") }}
                  </p>
                  <span
                    *ngIf="list.externalName !== 'BankFeeds'"
                    class="dark-gray font-size-14"
                    >{{ getPlanName(list.itemPrice, list.tier) }} Plan |
                    {{ list.clientCount }} Clients | £ {{ list.price / 100 }}
                    {{
                      getFrequencyText(
                        list.itemPrice.periodUnit,
                        list.itemPrice.period
                      )
                    }}</span
                  >

                  <span
                    *ngIf="list.externalName === 'BankFeeds'"
                    class="dark-gray font-size-14"
                    >{{ list.tier }}
                    {{ list.tier == 1 ? "Bank Feed" : "Bank Feeds" }} | £
                    {{ list.price / 100 }}
                    {{
                      getFrequencyText(
                        list.itemPrice.periodUnit,
                        list.itemPrice.period
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="wp-50">
                <span
                  class="text-gray font-size-14"
                  *ngIf="!isTrail && !list.contractTerm.isContractTermApplied"
                >
                  <div class="plan-view">
                    <div>
                      <span class="fw-bold">
                        {{
                          getText(
                            isTrail ? list.endDate : list.nextBillingDate,
                            isTrail
                          )
                        }}
                        :</span
                      >
                      {{
                        (isTrail ? list.endDate : list.nextBillingDate) | date
                      }}
                    </div>
                  </div>
                </span>
                <span
                  class="text-gray font-size-14"
                  *ngIf="!isTrail && list.contractTerm.isContractTermApplied"
                >
                  <div class="plan-view">
                    <div>
                      <span class="fw-bold">Contract Start :</span>
                      {{ list.contractTerm.contractTermStartDate | date }}
                    </div>
                    <div>
                      <span class="fw-bold">Contract End :</span>
                      {{ list.contractTerm.contractTermEndDate | date }}
                    </div>
                    <div>
                      <span class="fw-bold">Contract Remaining Days :</span>
                      {{
                        getNoOfDayscalculator(
                          list.contractTerm.contractTermEndDate
                        )
                      }}
                    </div>
                    <div>
                      <span class="fw-bold">
                        {{
                          getText(
                            isTrail ? list.endDate : list.nextBillingDate,
                            isTrail
                          )
                        }}
                        :</span
                      >
                      {{
                        (isTrail ? list.endDate : list.nextBillingDate) | date
                      }}
                    </div>
                    <div>
                      <span class="fw-bold">Total Billing Cycles :</span>
                      {{ list.contractTerm.totalBillingCycles }}
                    </div>
                    <div>
                      <span class="fw-bold">Remaining Billing Cycles :</span>
                      {{ list.contractTerm.remainingBillingCycles }}
                    </div>
                  </div>
                </span>
              </div>
              <div class="text-align-right w-150">
                <button
                  [ngClass]="
                    list.status === 3 ? 'success-button' : 'danger-button'
                  "
                  class="primary-button cursor-default"
                  mat-button
                  disableRipple
                >
                  <span
                    >{{ getStatus(list.status, list.nextBillingDate) }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="sub-plan-details mb-1">
            <mat-accordion [multi]="true">
              <mat-expansion-panel
                *ngFor="let list of subscriptionList"
                class="sub-card p-0 d-block box-shadow-none"
              >
                <mat-expansion-panel-header>
                  <div class="d-flex align-items-center wp-100">
                    <div class="d-flex align-items-center gap-10 wp-40">
                      <img
                        src="{{
                          commonService.getImagePath(list.itemPrice.id)
                        }}"
                        alt="icon"
                      />
                      <div *ngIf="isIndividual(list)">
                        <p class="m-0 font-size-16 fw-bold">
                          {{ getExternalName(list) }}
                        </p>
                        <span
                          class="dark-gray font-size-14"
                          *ngIf="isIndividualAddon(list)"
                        >
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxClients }} Clients |
                          {{ list.maxUsers }} Users |
                          {{ list.maxEmployees }} Employees
                        </span>
                        <span
                          class="dark-gray font-size-14"
                          *ngIf="
                            !isIndividualAddon(list) && !getIs_5_0Module(list)
                          "
                        >
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan
                        </span>
                        <span
                          class="dark-gray font-size-14"
                          *ngIf="
                            !isIndividualAddon(list) &&
                            getIsTimeAndFee_5_0Module(list)
                          "
                        >
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxTimeAndFeeClients }} Clients
                        </span>
                        <span
                          class="dark-gray font-size-14"
                          *ngIf="
                            !isIndividualAddon(list) &&
                            getIsCharity_5_0Module(list)
                          "
                        >
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxCharityClients }} Clients 
                                                                                               
                          </span                      
                        >
                        <span class="dark-gray font-size-14" *ngIf="!isIndividualAddon(list) && getIsCT_5_0Module(list)">
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxCorporationTaxClients }} Clients 
                                                                                               
                          </span                      
                        >
                        <span class="dark-gray font-size-14" *ngIf="!isIndividualAddon(list) && getIsBK_5_0Module(list)">
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxBookkeepingClients }} Clients 
                                                                                               
                          </span                      
                        >
                        <span class="dark-gray font-size-14" *ngIf="!isIndividualAddon(list) && getIsSamtd_5_0Module(list)">
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxSamtdClients }} Clients 
                                                                                               
                          </span                      
                        >
                      </div>

                      <div *ngIf="!isIndividual(list)">
                        <p class="m-0 font-size-16 fw-bold">
                          {{ getExternalName(list) }}
                        </p>
                        <span class="dark-gray font-size-14">
                          {{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.maxClients }} Clients |
                          {{ list.maxUsers }} Users |
                          {{ list.maxEmployees }} Employees |£
                          {{ list.price / 100 }}
                          {{
                            getFrequencyText(
                              list.itemPrice.periodUnit,
                              list.itemPrice.period
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="wp-40">
                      <span
                        class="text-gray font-size-16"
                        *ngIf="!isTrail && isIndividualAddon(list)"
                      >
                        <div class="plan-view">
                          <div>
                            <span class="fw-bold">
                              {{
                                getText(
                                  isTrail
                                    ? list.endDate
                                    : list.contractTerm.isContractTermApplied
                                    ? list.contractTerm.contractTermEndDate
                                    : list.nextBillingDate,
                                  isTrail
                                )
                              }}
                              :</span
                            >
                            {{
                              (isTrail
                                ? list.endDate
                                : list.contractTerm.isContractTermApplied
                                ? list.contractTerm.contractTermEndDate
                                : list.nextBillingDate
                              ) | date
                            }}
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="text-align-right wp-20 pr-1">
                      <button
                        [ngClass]="
                          list.status === 3 ? 'success-button' : 'danger-button'
                        "
                        class="primary-button cursor-default"
                        mat-button
                        disableRipple
                      >
                        <span
                          >{{ getStatus(list.status, list.nextBillingDate) }}
                        </span>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <ng-container
                  *ngIf="
                    !isTrail &&
                    list.contractTerm.isContractTermApplied &&
                    isIndividualAddon(list)
                  "
                >
                  <div class="hr-top"></div>
                  <div class="plan-details-view">
                    <div class="plan-details-head">
                      <mat-icon fontSet="material-icons-outlined"
                        >article</mat-icon
                      >
                      Plan Details
                    </div>
                    <div class="plan-details-body text-gray">
                      <div>
                        <span class="fw-bold">Start Date :</span>
                        {{ list.contractTerm.contractTermStartDate | date }}
                      </div>
                      <div>
                        <span class="fw-bold">End Date :</span>
                        {{ list.contractTerm.contractTermEndDate | date }}
                      </div>
                      <div>
                        <span class="fw-bold">Remaining Days :</span>
                        {{
                          getNoOfDayscalculator(
                            list.contractTerm.contractTermEndDate
                          )
                        }}
                      </div>
                    </div>
                    <div class="plan-details-body text-gray">
                      <div>
                        <span class="fw-bold">Next Billing Date :</span>
                        {{
                          (isTrail ? list.endDate : list.nextBillingDate) | date
                        }}
                      </div>
                      <div>
                        <span class="fw-bold">Total Billing Cycles :</span>
                        {{ list.contractTerm.totalBillingCycles }}
                      </div>
                      <div>
                        <span class="fw-bold">Remaining Billing Cycles :</span>
                        {{ list.contractTerm.remainingBillingCycles }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div
            class="sub-plan-details mb-1"
            *ngIf="addonsSubscriptionList.length > 0"
          >
            <h2 class="font-size-18 text-gray mb-1">Capium Addons Plan</h2>
            <mat-accordion [multi]="true">
              <mat-expansion-panel
                *ngFor="let list of addonsSubscriptionList"
                class="sub-card p-0 d-block box-shadow-none"
              >
                <mat-expansion-panel-header>
                  <div class="d-flex align-items-center wp-100">
                    <div class="d-flex align-items-center gap-10 wp-40">
                      <img
                        src="{{
                          commonService.getImagePath(list.itemPrice.id)
                        }}"
                        alt="icon"
                      />
                      <div>
                        <p class="m-0 font-size-16 fw-bold">
                          {{ list.externalName.replace("Individual", "") }}
                        </p>
                        <span
                          *ngIf="list.externalName !== 'BankFeeds'"
                          class="dark-gray font-size-14"
                          >{{ getPlanName(list.itemPrice, list.tier) }} Plan |
                          {{ list.clientCount }}
                          {{ getAddonsQuantityText(list.itemPrice) }} | £
                          {{ list.price / 100 }}
                          {{
                            getFrequencyText(
                              list.itemPrice.periodUnit,
                              list.itemPrice.period
                            )
                          }}</span
                        >

                        <span
                          *ngIf="list.externalName === 'BankFeeds'"
                          class="dark-gray font-size-14"
                          >{{ list.tier }}
                          {{ list.tier == 1 ? "Bank Feed" : "Bank Feeds" }} | £
                          {{ list.price / 100 }}
                          {{
                            getFrequencyText(
                              list.itemPrice.periodUnit,
                              list.itemPrice.period
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="wp-40">
                      <span class="text-gray font-size-16" *ngIf="!isTrail">
                        <div class="plan-view">
                          <div>
                            <span class="fw-bold">
                              {{
                                getText(
                                  isTrail
                                    ? list.endDate
                                    : list.contractTerm.isContractTermApplied
                                    ? list.contractTerm.contractTermEndDate
                                    : list.nextBillingDate,
                                  isTrail
                                )
                              }}
                              :</span
                            >
                            {{
                              (isTrail
                                ? list.endDate
                                : list.contractTerm.isContractTermApplied
                                ? list.contractTerm.contractTermEndDate
                                : list.nextBillingDate
                              ) | date
                            }}
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="text-align-right wp-20 pr-1">
                      <button
                        [ngClass]="
                          list.status === 3 ? 'success-button' : 'danger-button'
                        "
                        class="primary-button cursor-default"
                        mat-button
                        disableRipple
                      >
                        <span
                          >{{ getStatus(list.status, list.nextBillingDate) }}
                        </span>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <ng-container
                  *ngIf="!isTrail && list.contractTerm.isContractTermApplied"
                >
                  <div class="hr-top"></div>
                  <div class="plan-details-view">
                    <div class="plan-details-head">
                      <mat-icon fontSet="material-icons-outlined"
                        >article</mat-icon
                      >
                      Plan Details
                    </div>
                    <div class="plan-details-body text-gray">
                      <div>
                        <span class="fw-bold">Start Date :</span>
                        {{ list.contractTerm.contractTermStartDate | date }}
                      </div>
                      <div>
                        <span class="fw-bold">End Date :</span>
                        {{ list.contractTerm.contractTermEndDate | date }}
                      </div>
                      <div>
                        <span class="fw-bold">Remaining Days :</span>
                        {{
                          getNoOfDayscalculator(
                            list.contractTerm.contractTermEndDate
                          )
                        }}
                      </div>
                    </div>
                    <div class="plan-details-body text-gray">
                      <div>
                        <span class="fw-bold">Next Billing Date :</span>
                        {{
                          (isTrail ? list.endDate : list.nextBillingDate) | date
                        }}
                      </div>
                      <div>
                        <span class="fw-bold">Total Billing Cycles :</span>
                        {{ list.contractTerm.totalBillingCycles }}
                      </div>
                      <div>
                        <span class="fw-bold">Remaining Billing Cycles :</span>
                        {{ list.contractTerm.remainingBillingCycles }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div
          class="sub-plan-details mb-1"
          *ngIf="capium365SubscriptionList.length > 0"
        >
          <h2 class="font-size-18 text-gray mb-1">Capium 365 Plan</h2>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              *ngFor="let list of capium365SubscriptionList"
              class="sub-card p-0 d-block box-shadow-none"
            >
              <mat-expansion-panel-header>
                <div class="d-flex align-items-center wp-100">
                  <div class="d-flex align-items-center gap-10 wp-40">
                    <img
                      src="{{
                        commonService.getImagePath(list.itemPrice.id)
                      }}"
                      alt="icon"
                    />
                    <div>
                      <p class="m-0 font-size-16 fw-bold">
                        {{ list.externalName.replace("Individual", "") }}
                      </p>
                      <span
                        *ngIf="list.externalName !== '365 licenses'"
                        class="dark-gray font-size-14"
                        >{{ getPlanName(list.itemPrice, list.tier) }} Plan |
                        {{ list.clientCount }}
                        {{ getAddonsQuantityText(list.itemPrice) }} | £
                        {{ list.price / 100 }}
                        {{
                          getFrequencyText(
                            list.itemPrice.periodUnit,
                            list.itemPrice.period
                          )
                        }}</span
                      >

                      <span
                        *ngIf="list.externalName === '365 licenses'"
                        class="dark-gray font-size-14"
                        >{{ list.tier }}
                        {{ list.tier == 1 ? "License" : "Licenses" }} | £
                        {{ list.price / 100 }}
                        {{
                          getFrequencyText(
                            list.itemPrice.periodUnit,
                            list.itemPrice.period
                          )
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="wp-40">
                    <span class="text-gray font-size-16" *ngIf="!isTrail">
                      <div class="plan-view">
                        <div>
                          <span class="fw-bold">
                            {{
                              getText(
                                isTrail
                                  ? list.endDate
                                  : list.contractTerm.isContractTermApplied
                                  ? list.contractTerm.contractTermEndDate
                                  : list.nextBillingDate,
                                isTrail
                              )
                            }}
                            :</span
                          >
                          {{
                            (isTrail
                              ? list.endDate
                              : list.contractTerm.isContractTermApplied
                              ? list.contractTerm.contractTermEndDate
                              : list.nextBillingDate
                            ) | date
                          }}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="text-align-right wp-20 pr-1">
                    <button
                      [ngClass]="
                        list.status === 3 ? 'success-button' : 'danger-button'
                      "
                      class="primary-button cursor-default"
                      mat-button
                      disableRipple
                    >
                      <span
                        >{{ getStatus(list.status, list.nextBillingDate) }}
                      </span>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <ng-container
                *ngIf="!isTrail && list.contractTerm.isContractTermApplied"
              >
                <div class="hr-top"></div>
                <div class="plan-details-view">
                  <div class="plan-details-head">
                    <mat-icon fontSet="material-icons-outlined"
                      >article</mat-icon
                    >
                    Plan Details
                  </div>
                  <div class="plan-details-body text-gray">
                    <div>
                      <span class="fw-bold">Start Date :</span>
                      {{ list.contractTerm.contractTermStartDate | date }}
                    </div>
                    <div>
                      <span class="fw-bold">End Date :</span>
                      {{ list.contractTerm.contractTermEndDate | date }}
                    </div>
                    <div>
                      <span class="fw-bold">Remaining Days :</span>
                      {{
                        getNoOfDayscalculator(
                          list.contractTerm.contractTermEndDate
                        )
                      }}
                    </div>
                  </div>
                  <div class="plan-details-body text-gray">
                    <div>
                      <span class="fw-bold">Next Billing Date :</span>
                      {{
                        (isTrail ? list.endDate : list.nextBillingDate) | date
                      }}
                    </div>
                    <div>
                      <span class="fw-bold">Total Billing Cycles :</span>
                      {{ list.contractTerm.totalBillingCycles }}
                    </div>
                    <div>
                      <span class="fw-bold">Remaining Billing Cycles :</span>
                      {{ list.contractTerm.remainingBillingCycles }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

          <div
            class="sub-plan-details mb-1"
            *ngIf="SMEsubscriptionList.length > 0"
          >
            <h2 class="font-size-18 text-gray mb-1">SME Plan</h2>
            <mat-accordion [multi]="true">
              <mat-expansion-panel
                *ngFor="let list of SMEsubscriptionList"
                class="sub-card p-0 d-block box-shadow-none"
              >
                <mat-expansion-panel-header>
                  <div class="d-flex align-items-center wp-100">
                    <div class="d-flex align-items-center gap-10 wp-40">
                      <img
                        src="{{
                          commonService.getImagePath(list.itemPrice.id)
                        }}"
                        alt="icon"
                      />
                      <div>
                        <p class="m-0 font-size-16 fw-bold">
                          {{ list.externalName.replace("Individual", "") }}
                        </p>
                        <span class="dark-gray font-size-14"
                          >{{ getSmePlanName(list) }} Plan
                        </span>
                        <span *ngIf="!isTrail"
                          >| £ {{ list.price / 100 }}
                          {{
                            getFrequencyText(
                              list.itemPrice.periodUnit,
                              list.itemPrice.period
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="wp-40">
                      <span
                        class="text-gray font-size-14"
                        *ngIf="
                          !isTrail && !list.contractTerm.isContractTermApplied
                        "
                      >
                        <div class="plan-view">
                          <div>
                            <span class="fw-bold">
                              {{
                                getText(
                                  isTrail ? list.endDate : list.nextBillingDate,
                                  isTrail
                                )
                              }}
                              :</span
                            >
                            {{
                              (isTrail ? list.endDate : list.nextBillingDate)
                                | date
                            }}
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="text-align-right wp-20 pr-1">
                      <button
                        [ngClass]="
                          list.status === 3 ? 'success-button' : 'danger-button'
                        "
                        class="primary-button cursor-default"
                        mat-button
                        disableRipple
                      >
                        <span
                          >{{ getStatus(list.status, list.nextBillingDate) }}
                        </span>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <ng-container
                  *ngIf="!isTrail && list.contractTerm.isContractTermApplied"
                >
                  <div class="hr-top"></div>
                  <div class="plan-details-view">
                    <div class="plan-details-head">
                      <mat-icon fontSet="material-icons-outlined"
                        >article</mat-icon
                      >
                      Plan Details
                    </div>
                    <div class="plan-details-body text-gray">
                      <div>
                        <span class="fw-bold">Start Date :</span>
                        {{ list.contractTerm.contractTermStartDate | date }}
                      </div>
                      <div>
                        <span class="fw-bold">End Date :</span>
                        {{ list.contractTerm.contractTermEndDate | date }}
                      </div>
                      <div>
                        <span class="fw-bold">Remaining Days :</span>
                        {{
                          getNoOfDayscalculator(
                            list.contractTerm.contractTermEndDate
                          )
                        }}
                      </div>
                    </div>
                    <div class="plan-details-body text-gray">
                      <div>
                        <span class="fw-bold">Next Billing Date :</span>
                        {{
                          (isTrail ? list.endDate : list.nextBillingDate) | date
                        }}
                      </div>
                      <div>
                        <span class="fw-bold">Total Billing Cycles :</span>
                        {{ list.contractTerm.totalBillingCycles }}
                      </div>
                      <div>
                        <span class="fw-bold">Remaining Billing Cycles :</span>
                        {{ list.contractTerm.remainingBillingCycles }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>

    <div
      class="subscription-not-found-wrapper"
      *ngIf="
        subscriptionList.length === 0 &&
        addonsSubscriptionList.length === 0 &&
        SMEsubscriptionList.length === 0 &&
        capium365SubscriptionList.length==0
      "
    >
      <div class="subscription-not-found-content d-none">
        <h2 class="mb-2">No subscription yet!</h2>
        <mat-icon>error</mat-icon>
        <p class="font-size-16 mt-1 mb-2">
          <strong>Note:</strong> You can contact Capium support in case of any
          confusion here
        </p>

        <div class="contact-button text-align-center">
          <button
            class="action-button primary-button"
            mat-button
            disableRipple
            (click)="subscriptionNow()"
          >
            Subscribe Now
          </button>
        </div>
      </div>
      <div class="norecord-data text-align-center">
        <img
          src="assets/img/no-subscription.svg"
          alt="Empty Cart"
          width="260px"
        />
        <h2 class="pt-1">No subscription yet!</h2>
        <p class="font-size-16 mt-1 mb-2">
          <strong>Note:</strong> You can contact Capium support in case of
          <br />
          any confusion here
        </p>
        <div class="contact-button text-align-center">
          <button
            class="action-button primary-button"
            mat-button
            disableRipple
            (click)="subscriptionNow()"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
