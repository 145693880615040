<div class="sidenav-body-content content-body-scroll">
  <h2 class="text-primary font-size-18">Payment Integrations</h2>
  <div class="white-box">
    <div class="send-box">
      <div
        class="d-flex align-items-center justify-space-between send-box-details"
      >
        <div class="d-flex align-items-center justify-space-between gap-20">
          <img src="{{ environment.imagePath }}/stripe.png" alt="Stripe" />
          <div>
            <p>Stripe</p>
          </div>
        </div>
        <button mat-button disableRipple (click)="showStripeAccount()">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
      <div class="sidenav-table wp-100 mt-1" *ngIf="showStripeAccounts">
        <div class="stripe-detail">
          <div class="d-flex align-items-center stripe-title">
            <mat-icon>account_balance</mat-icon>
            <p class="text-gray font-size-18 pl-10 m-0">Select Your Account</p>
          </div>
          <div class="account-box">
            <div
              class="d-flex align-items-center justify-space-between user-detail"
            >
              <div
                class="d-flex align-items-center justify-space-between gap-20"
              >
                <mat-radio-button value="1" [checked]="true"></mat-radio-button>

                <img
                  class="rounded-img"
                  src="{{ environment.imagePath }}/user.jpg"
                  alt="User"
                />
                <span class="font-size-16">elinastone@gmail.com</span>
              </div>
              <div
                class="d-flex align-items-center justify-space-between gap-40 account-detail"
              >
                <span class="font-size-16">Default</span>
                <span class="font-size-16">06 July 2022</span>
                <button mat-button disableRipple>
                  <mat-icon class="success-icon text-success"
                    >brightness_1</mat-icon
                  >
                </button>
                <button mat-button disableRipple>
                  <mat-icon class="text-red">remove_circle_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="add-account pt-1">
            <button mat-button disableRipple>
              <mat-icon class="text-gray-300">add_circle_outline</mat-icon>
              <span class="text-gray-300 font-size-16"
                >Add Another Account</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="white-box">
      <div class="send-box">
        <div
          class="d-flex align-items-center justify-space-between send-box-details"
        >
          <div class="d-flex align-items-center justify-space-between gap-20">
            <img
              style="width: 34px; height: 34px"
              src="{{ environment.imagePath }}/favicon-96x96.png"
              alt="Capium"
            />
            <div>
              <p>Pay</p>
            </div>
          </div>
          <div>
            <p class="fw-bold text-primary m-0">Coming soon...</p>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-primary font-size-18">Email Integrations</h2>
    <div class="white-box">
      <div class="send-box">
        <div
          class="d-flex align-items-center justify-space-between send-box-details"
        >
          <div class="d-flex align-items-center justify-space-between gap-20">
            <img src="{{ environment.imagePath }}/gmail.svg" alt="Gmail" />
            <div>
              <p>Gmail</p>
            </div>
          </div>
          <button mat-button disableRipple (click)="showGmailAccount()">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <div class="sidenav-table wp-100 mt-1" *ngIf="showGmailAccounts">
          <div class="mat-table-wrapper contact-detail-table">
            <form>
              <div class="cust-table-wrapper">
                <table
                  mat-table
                  [dataSource]="dataSource"
                  aria-describedby="Billing History Table"
                >
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email ID</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.email }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.date }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="default">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Default
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button></mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-right"
                    >
                      <div class="grid-actions">
                        <button mat-button disableRipple title="Delete">
                          <mat-icon>delete_outline</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </form>
          </div>
          <div class="add-account pt-1">
            <button mat-button disableRipple>
              <mat-icon class="text-gray-300">person_pin</mat-icon>
              <span class="text-gray-300 font-size-16"
                >Use Another Account</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="white-box">
      <div class="send-box">
        <div
          class="d-flex align-items-center justify-space-between send-box-details"
        >
          <div class="d-flex align-items-center justify-space-between gap-20">
            <img src="{{ environment.imagePath }}/outlook.svg" alt="Outlook" />
            <div>
              <p>Outlook</p>
            </div>
          </div>
          <button mat-button disableRipple (click)="showOutlookAccount()">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <div class="sidenav-table wp-100 mt-1" *ngIf="showOutlookAccounts">
          <div class="mat-table-wrapper contact-detail-table">
            <form>
              <div class="cust-table-wrapper">
                <table
                  mat-table
                  [dataSource]="dataSource"
                  aria-describedby="Billing History Table"
                >
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email ID</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.email }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.date }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="default">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Default
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button></mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-right"
                    >
                      <div class="grid-actions">
                        <button mat-button disableRipple title="Delete">
                          <mat-icon>delete_outline</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </form>
          </div>
          <div class="add-account pt-1">
            <button mat-button disableRipple>
              <mat-icon class="text-gray-300">person_pin</mat-icon>
              <span class="text-gray-300 font-size-16"
                >Use Another Account</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
