import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModuleName } from '@app/core/enums';
import { ExportFormat } from '@app/core/enums/export-type';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent {
  @Input()
  totalSelectedRecords: number;

  exportType = ExportFormat;
  moduleName = ModuleName;

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  exportFormat = ExportFormat;

  export(format: number): void {
    this.exportClick.emit(format);
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }
}
