import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  PeriodUnitTextShort,
  PeriodUnitType,
  SubscriptionsEnum,
  RoutingPath,
  AddOnsId,
  PurchasedEnum,
  SMESubscriptionsEnum,
  Modules_5_0,
  CharityModules_5_0,
  TimeAndFeeModules_5_0,
  BookkeepingNewModules_5_0,
  SAMTDModules_5_0,
  CorporationTaxNewModules_5_0,
} from '@app/core/enums';
import { CommonService, LocalStorageService } from '@app/core/services';
import {
  GetSubscriptionPortalDetails,
  GetSubscriptionPortalForCapiumAddonsDetails,
  GetSmeSubscriptionPortalDetails,
  GetSubscriptionPortalForCapium365,
} from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import jwt_decode from 'jwt-decode';
import { OidcSecurityService } from 'angular-auth-oidc-client';
@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
})
export class SubscriptionListComponent implements OnInit {
  showSubcription = false;

  subscriptionList: any = [];
  addonsSubscriptionList: any = [];
  capium365SubscriptionList: any = [];
  SMEsubscriptionList: any = [];
  userData: any;
  headerText: any;
  isSME: boolean;
  frequencyText = '';
  environment = environment;
  isTrail = false;
  SubscriptionsEnum: SubscriptionsEnum;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private store: Store,
    public commonService: CommonService,
    private datepipe: DatePipe,
    public storage: LocalStorageService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    this.userData = decoded;
    this.isSME = this.storage.get('isSME');
    if (!this.isSME) {
      this.getList();
      this.getSubscriptionPortalForCapiumAddonsDetails();
      this.getSubscriptionPortalForCapium365Details();
    } else {
      this.getSmeSubscriptionDetails();
    }
  }

  subscriptionNow(): void {
    this.router.navigate([RoutingPath.Products]);
  }

  getFrequencyText(periodUnit, period): string {
    if (!this.isTrail) {
      let frequencyTextShort;
      if (periodUnit === PeriodUnitType.Month && period === 1) {
        frequencyTextShort = PeriodUnitTextShort.Month;
      } else if (periodUnit === PeriodUnitType.Year && period === 1) {
        frequencyTextShort = PeriodUnitTextShort.Year;
      } else {
        frequencyTextShort = PeriodUnitTextShort.Quarterly;
      }
      this.frequencyText = `/ ${frequencyTextShort}`;
    }
    return this.frequencyText;
  }

  getList(): void {
    this.store.dispatch(new GetSubscriptionPortalDetails()).subscribe((res) => {
      this.showSubcription = true;

      let subscriptionList = res.subscriptionPortal.subscriptionPortalData;

      subscriptionList.forEach((x) => {
        const words = x.endDate.split('T');
        if (words.length) {
          x.endDate = words[0];
        }
      });

      this.isTrail =
        +res.subscriptionPortal.subscriptionPortalData[0].itemPrice.itemId ===
        +SubscriptionsEnum.trail;
      this.subscriptionList = subscriptionList;

      this.headerText = this.commonService.getSubscriptionsName(
        subscriptionList[0].itemPrice.itemId
      );
      console.log(this.subscriptionList);
    });
  }

  getSubscriptionPortalForCapiumAddonsDetails(): void {
    this.store
      .dispatch(new GetSubscriptionPortalForCapiumAddonsDetails())
      .subscribe((res) => {
        this.addonsSubscriptionList =
          res.subscriptionPortal.subscriptionPortalDataForCapiumAddons;
      });
  }

  getSubscriptionPortalForCapium365Details(): void {
    let userName = this.userData.email;
    
    this.store
      .dispatch(new GetSubscriptionPortalForCapium365(userName))
      .subscribe((res) => {
        console.log(res);
        let subscriptionList =
          res.subscriptionPortal.subscriptionPortalDataForCapium365;

        this.capium365SubscriptionList = subscriptionList.filter((item) => {
          return +item.itemPrice.itemId !== +SubscriptionsEnum.capium365;
        });
       
      });
  }



  getSmeSubscriptionDetails(): void {
    this.showSubcription = true;
    this.store
      .dispatch(new GetSmeSubscriptionPortalDetails())
      .subscribe((res) => {
        this.SMEsubscriptionList = res.smeproduct.smeSubscriptionPortalData;
        console.log(this.SMEsubscriptionList);
        this.isTrail =
          +res.smeproduct.smeSubscriptionPortalData[0].itemPrice.itemId ===
          +SMESubscriptionsEnum.trail;
      });
  }

  getStatus(status: any, expireDate: any): string {
    let purchasedstatus = this.commonService.getEnumNameFromValue(
      +status,
      PurchasedEnum
    );
    return purchasedstatus!;
  }
  getIsCharity_5_0Module(item: any): boolean {
    let is5_0Module = Object.values(CharityModules_5_0)?.includes(
      item.itemPrice.id
    );
    return is5_0Module;
  }
  getIsTimeAndFee_5_0Module(item: any): boolean {
    let is5_0Module = Object.values(TimeAndFeeModules_5_0)?.includes(
      item.itemPrice.id
    );
    return is5_0Module;
  }
  getIsBK_5_0Module(item:any):boolean{    
    let is5_0Module = Object.values(BookkeepingNewModules_5_0)?.includes(item.itemPrice.id);
    return is5_0Module;
  }
  getIsSamtd_5_0Module(item:any):boolean{    
    let is5_0Module = Object.values(SAMTDModules_5_0)?.includes(item.itemPrice.id);
    return is5_0Module;
  }
  getIsCT_5_0Module(item:any):boolean{    
    let is5_0Module = Object.values(CorporationTaxNewModules_5_0)?.includes(item.itemPrice.id);
    return is5_0Module;
  }
  getIs_5_0Module(item:any):boolean{    
    let is5_0Module = Object.values(Modules_5_0)?.includes(item.itemPrice.id);
    return is5_0Module;
  }

  isExpried(expireDate: any): boolean {
    return new Date() > new Date(expireDate);
  }

  getNoOfDays(expireDate: any): number {
    if (new Date() > new Date(expireDate)) {
      return moment(new Date()).diff(expireDate, 'days');
    } else {
      return moment(expireDate).diff(new Date(), 'days');
    }
  }
  getNoOfDayscalculator(endDate: any): number {
    return moment(endDate).diff(new Date(), 'days');
  }

  getText(expireDate: any, isTrial: boolean): string {
    if (isTrial) {
      let date = new Date();
      let newdate;
      const words = date.toISOString().split('T');
      if (words.length) {
        newdate = words[0];
      }
      if (newdate >= expireDate) {
        return 'Trial ended on';
      } else {
        return 'Trial ends on';
      }
    } else {
      if (new Date() > new Date(expireDate)) {
        return 'Subscription ended on';
      } else {
        if (new Date() > new Date(expireDate)) {
          return 'Subscription ended on';
        } else {
          return 'Subscription end date';
        }
      }
    }
  }

  getPlanName(item, tier?: number): string {
    switch (+item.itemId) {
      case SubscriptionsEnum.trail:
      case SubscriptionsEnum.fullSuiteSmall:
      case SubscriptionsEnum.fullSuiteMedium:
      case SubscriptionsEnum.fullSuiteLarge:
      case SubscriptionsEnum.fullSuiteMicro:
      case SubscriptionsEnum.fullSuiteMicroBite:
      case SubscriptionsEnum.fullSuiteEnterprise:
      case SubscriptionsEnum.individual:
        return item.externalName;

      case AddOnsId.SelfAssessment:
      case AddOnsId.CorporationTax:
      case AddOnsId.AccountsProduction:
      case AddOnsId.PracticeManagement:
      case AddOnsId.AdvancePayroll:
      case AddOnsId.BookeepingMTD:            
      case AddOnsId.BankFeeds:      
        return this.commonService.getIndividualPlanName(tier);

      case AddOnsId.CapiumHubGBPMonthly:
        return this.commonService.getHUBPlanName(tier);

      case AddOnsId.CompanySecretarial:
        return this.commonService.getCSSPlanName(tier);

      case AddOnsId.SMS:
        return this.commonService.getSMSPlanName(tier);

      case AddOnsId.Charity:
        return this.commonService.getCharityPlanName(tier);

      case AddOnsId.TimeAndFee:
      case AddOnsId.Capisign:
      case AddOnsId.BookkeepingNew:
      case AddOnsId.CorporationTaxNew:
      case AddOnsId.SAMTD:
      case AddOnsId._365Licenses:
        return this.commonService.getTimeAndFeeAndCapisignPlanName(tier);

      default:
        return `Trial`;
    }
  }
  getAddonsQuantityText(item): string {
    switch (+item.itemId) {
      case AddOnsId.SMS:
        return `Count`;

      case AddOnsId.Capisign:
        return `Documents`;

      case AddOnsId.CompanySecretarial:
        return `Clients`;

      default:
        return `Clients`;
    }
  }

  getSmePlanName(item): string {
    switch (+item.planId) {
      case SMESubscriptionsEnum.trail:
        return item.externalName;

      case SMESubscriptionsEnum.capium365:
        return `Capium365`;

      default:
        return `Trial`;
    }
  }

  getExternalName(item): string {
    switch (+item.itemPrice.itemId) {
      case SubscriptionsEnum.individual:
        return item.externalName;

      default:
        return item.externalName.replace(/individual|new/gi, '').trim()
    }
  }
  isIndividual(item): boolean {
    switch (+item.planId) {
      case SubscriptionsEnum.individual:
      case SubscriptionsEnum.fullSuiteMicroBite:
      case SubscriptionsEnum.fullSuiteMicro:
      case SubscriptionsEnum.fullSuiteSmall:
      case SubscriptionsEnum.fullSuiteMedium:
      case SubscriptionsEnum.fullSuiteLarge:
      case SubscriptionsEnum.fullSuiteEnterprise:
        return true;

      default:
        return false;
    }
  }
  isIndividualAddon(item): boolean {
    switch (+item.itemPrice.itemId) {
      case SubscriptionsEnum.individual:
      case SubscriptionsEnum.fullSuiteMicroBite:
      case SubscriptionsEnum.fullSuiteMicro:
      case SubscriptionsEnum.fullSuiteSmall:
      case SubscriptionsEnum.fullSuiteMedium:
      case SubscriptionsEnum.fullSuiteLarge:
      case SubscriptionsEnum.fullSuiteEnterprise:
        return true;

      default:
        return false;
    }
  }
}
