import { Injectable } from '@angular/core';
import { SubscriptionPortalService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  GetSubscriptionPortalDetails,
  GetSubscriptionPortalForCapium365,
  GetSubscriptionPortalForCapiumAddonsDetails,
} from './subscription-portal.action';

export class SubscriptionPortalStateInfo {
  subscriptionPortalData?: Array<any>;
  subscriptionPortalDataForCapiumAddons?: Array<any>;
  subscriptionPortalDataForCapium365?: Array<any>;
  userName?:string;
}

@State<SubscriptionPortalStateInfo>({
  name: 'subscriptionPortal',
  defaults: {
    subscriptionPortalData: [],
    subscriptionPortalDataForCapiumAddons: [],
    subscriptionPortalDataForCapium365:[]
  },
})
@Injectable()
export class SubscriptionPortalState {
  constructor(private subscriptionPortalService: SubscriptionPortalService) {}

  @Selector()
  static subscriptionPortalData(
    state: SubscriptionPortalStateInfo
  ): Array<any> {
    return state.subscriptionPortalData!;
  }

  @Selector()
  static getSubscriptionPortalForCapium365(state: SubscriptionPortalStateInfo): any {
    return state.subscriptionPortalDataForCapium365;
  }

  @Action(GetSubscriptionPortalDetails)
  getSubscriptionPortalDetails(
    { patchState }: StateContext<SubscriptionPortalStateInfo>,
    action: GetSubscriptionPortalDetails
  ) {
    return this.subscriptionPortalService.getSubscriptionPortalDetails().pipe(
      tap((res) => {
        patchState({
          subscriptionPortalData: res.valueResult,
        });
      })
    );
  }

  @Action(GetSubscriptionPortalForCapiumAddonsDetails)
  getSubscriptionPortalForCapiumAddonsDetails(
    { patchState }: StateContext<SubscriptionPortalStateInfo>,
    action: GetSubscriptionPortalForCapiumAddonsDetails
  ) {
    return this.subscriptionPortalService
      .getSubscriptionPortalForCapiumAddonsDetails()
      .pipe(
        tap((res) => {
          patchState({
            subscriptionPortalDataForCapiumAddons:
              res.valueResult.subscriptionlist,
          });
        })
      );
  }

  @Action(GetSubscriptionPortalForCapium365)
  getSubscriptionPortalForCapium365(
    { patchState }: StateContext<SubscriptionPortalStateInfo>,
    action: GetSubscriptionPortalForCapium365
  ) {
    return this.subscriptionPortalService
      .getSubscriptionPortalForCapium365(action.userName)
      .pipe(
        tap((res) => {
          patchState({
            subscriptionPortalDataForCapium365:
              res.valueResult.subscriptionlist,
          });
        })
      );
  }
}
