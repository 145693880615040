<div class="sidenav-body-content invoice-preview content-body-scroll">
  <div class="d-flex justify-space-between preview-container">
    <div class="preview-line">
      <p>capium</p>
      <hr class="w-70 ml-0 mt-1" />
    </div>
    <div class="w-250">
      <h2 class="font-size-30">INVOICE</h2>
      <p>
        Invoice <span class="fw-bold"># {{ previewData.id }}</span>
      </p>
      <p>
        Invoice Date
        <span class="fw-bold">{{ previewData.date * 1000 | date }}</span>
      </p>
      <p>
        Invoice Amount
        <span class="fw-bold">£{{ previewData.amount_paid / 100 }} (GBP)</span>
      </p>
      <h3>{{ previewData.status | uppercase }}</h3>
      <hr class="w-70 ml-0 mt-1" />
    </div>
  </div>
  <br />
  <div class="d-flex justify-space-between preview-container preview-line">
    <div>
      <p class="fw-bold">BILLED TO</p>
      <p>
        {{ previewData.billing_address.first_name }}
        {{ previewData.billing_address.last_name }}
      </p>
      <p>
        {{ previewData.billing_address.line1 }} &nbsp;
        {{ previewData.billing_address.line2 }}
      </p>
      <p>
        {{ previewData.billing_address.city }} &nbsp;
        {{ previewData.billing_address.state }}
        {{ previewData.billing_address.zip }}
      </p>
      <p>{{ previewData.billing_address.country }}</p>
    </div>
    <div class="w-250">
      <p class="white-space-nowrap">
        Billing Period
        <span class="fw-bold ml-10">
          {{ previewData.line_items[0].date_from * 1000 | date }} to
          {{ previewData.line_items[0].date_to * 1000 | date }}</span
        >
      </p>
      <p>
        Next Billing Date
        <span class="fw-bold"> {{ nextBillingDate | date }}</span>
      </p>
    </div>
  </div>

  <br />
  <hr />
  <div class="d-flex justify-space-between">
    <p class="fw-bold mb-0 font-size-12 w-434 text-align-left">DESCRIPTION</p>
    <p class="fw-bold mb-0 font-size-12 w-80 text-align-right">UNITS</p>
    <p class="fw-bold mb-0 font-size-12 w-80 text-align-right">AMOUNT (GBP)</p>
  </div>
  <hr />
  <table
    aria-describedby="Invoice Preview"
    class="mb-10 table-details wp-100"
    *ngFor="let pr of previewData.line_items"
  >
    <th>
      <tr class="wp-100 d-flex justify-space-between">
        <td class="fw-bold w-434 text-align-left">
          {{ pr.description }}
          <div>
            <td
              class="fw-bold display-block"
              *ngFor="let pd of previewData.line_item_tiers"
            >
              <p
                class="text-muted mb-0 line-height-normal"
                *ngIf="pr.id === pd.line_item_id"
              >
                £{{ pd.unit_amount / 100 }} *
                {{ pd.quantity_used }}
                Units
              </p>
            </td>
          </div>
        </td>
        <td class="fw-bold w-80 text-align-right">{{ pr.quantity }}</td>
        <td class="fw-bold w-80 text-align-right">£{{ pr.amount / 100 }}</td>
      </tr>
    </th>
  </table>
  <hr />
  <div class="text-align-right mb-1 mt-10">
    <p class="fw-bold font-size-16">
      Total &nbsp;<span>£{{ previewData.total / 100 }}</span>
    </p>
    <p *ngIf="previewData.amount_paid > 0" class="font-size-16">
      Payments &nbsp;<span>-£{{ previewData.amount_paid / 100 }}</span>
    </p>
    <hr class="w-100 mr-0 mt-1" />
    <p class="font-size-20">
      Amount Due (GBP)
      <span class="fw-bold ml-10">£{{ previewData.amount_due / 100 }}</span>
    </p>
    <hr class="w-100 mr-0 mt-1" />
  </div>
  <div *ngIf="previewData.amount_paid > 0" class="text-align-left">
    <p class="fw-bold mb-0">PAYMENTS</p>
    <hr class="w-100 mt-1 ml-0" />
    <p *ngFor="let pl of previewData.linked_payments">
      <span class="fw-bold">£{{ pl.txn_amount / 100 }}</span> was paid on
      {{ pl.applied_at * 1000 | date }}
    </p>
  </div>
</div>
