import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  PeriodUnitText,
  PeriodUnitTextShort,
  PeriodUnitType,
  PurchasedEnum,
  SMESubscriptionsEnum,
} from '@app/core/enums';
import { CommonService, LocalStorageService } from '@app/core/services';
import { ConfirmationBoxComponent } from '@app/core/sharedmodules';
import { SetProductDetailAcrdngPlan } from '@app/core/store';
import { SmeProductState, GetSmeProductList, GetSmeSubscriptionPortalDetails } from '@app/core/store/sme-products';
import { environment } from '@environments/environment';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { GlobalComponent } from '@app/core/models';

@Component({
  selector: 'app-product-sme',
  templateUrl: './product-sme.component.html',
  styleUrls: ['./product-sme.component.scss'],
})
export class ProductSmeComponent implements OnInit {
  @Output()
  readonly toggleReviewOrder = new EventEmitter<any>();

  @Input()
  frequency: number;

  @Input()
  subscribtionsType: number;

  isTrailPeriod: boolean;
  isTrialSubscription: boolean;
  isSMEPurchased = false;
  isSMECancelled = false;
  noSubscription: boolean = true;
  headerText = '';
  subscriptionId = '';
  trialEndDate: Date;
  selectedSubscriptionList: Array<any> = [];

  @Select(SmeProductState.smesubscriptionPortalData)
  smesubscriptionPortalData$: Observable<Array<any>>;
  isSME: any;
  router: any;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService,
    public commonService: CommonService
  ) {}

  smeProductList: Array<any> = [];
  ngOnInit(): void {
    this.smeProductList = [];
  }
  removeFromCart(item: any): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          type: ConfirmationType.Delete,
          ids: item.id,
          moduleId: Modules.Products,
          headerText: NotificationHeader.removeFromCart,
          detailText: NotificationDetails.terminateDetailText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        console.log(result);
        if (result) {
          item.isAddedToCart = false;
          this.commonService.storeCartItemDataLocally(this.smeProductList);
        }
      });
  }
  onConnectClick(item?: any): void {
    if (+SMESubscriptionsEnum.diyboox == item.itemId) {
      const url = `${environment.diybooxDemoUrl}`;
      console.log(url);
      window.open(url, '_blank');
    }
  }

  getSmeSubscriptionData(): void {    
    this.store.dispatch(new GetSmeSubscriptionPortalDetails()).
    subscribe((x) => {
    let subscriptionList =x.smeproduct.smeSubscriptionPortalData;    
    this.noSubscription=subscriptionList.length===0?true:false;
    console.log(subscriptionList);

        if (
          subscriptionList[0] !== undefined &&
          subscriptionList !== undefined
        ) {
          this.subscriptionId = subscriptionList[0].subscriptionId;
          this.storage.set('subscriptionId', this.subscriptionId);
        }

        this.isSMEPurchased = subscriptionList.some((item: any) => {
          return (
            +item.itemPrice.itemId === +SMESubscriptionsEnum.capium365 &&
            +item.status !== +PurchasedEnum.Cancelled
          );
        });

        this.isSMECancelled = subscriptionList.some((item: any) => {
          const isTrue =
            +item.itemPrice.itemId === +SMESubscriptionsEnum.capium365 &&
            +item.status === +PurchasedEnum.Cancelled;
          if (isTrue) {
            this.headerText = 'Subscription';
          }
          return isTrue;
        });

        this.isTrailPeriod = subscriptionList.some((item: any) => {
          const isTrue =
            +item.itemPrice.itemId === +SMESubscriptionsEnum.trail ||
            +item.status === +PurchasedEnum.Cancelled;

          if (+item.itemPrice.itemId === +SMESubscriptionsEnum.trail) {
            this.headerText = 'Trial';
            this.isTrialSubscription = true;
          }
          if (
            +item.status === +PurchasedEnum.Cancelled &&
            parseInt(item.itemPrice.itemId) !== +SMESubscriptionsEnum.trail
          ) {
            this.isSMECancelled = true;
            this.headerText = 'Subscription';
          }

          return isTrue;
        });
        let endDate = subscriptionList[0].endDate;
        const words = endDate.split('T');
        if (words.length) {
          this.trialEndDate = new Date(words[0]);
        }

        // this.smesubscriptionPortalData$.subscribe((res) => {
        //   let subscriptionList = res;
        //   this.isTrailPeriod = subscriptionList.some((item: any) => {
        //     const isTrue =
        //       +item.itemPrice.itemId === +SubscriptionsEnum.trail ||
        //       +item.status === +PurchasedEnum.Cancelled;

        //     if (+item.itemPrice.itemId === +SubscriptionsEnum.trail) {
        //       this.headerText = 'Trial';
        //       this.isTrialSubscription=true;
        //     }
        //     if (
        //       +item.status === +PurchasedEnum.Cancelled &&
        //       parseInt(item.itemPrice.itemId) !== +SubscriptionsEnum.trail
        //     ) {
        //       this.isSubscriptionCancelled = true;
        //       this.headerText = 'Subscription';
        //     }
        //     return isTrue;
        //   });
        //   console.log(this.isTrailPeriod);
        //   let endDate = res[0].endDate;
        //   const words = endDate.split('T');
        //     if (words.length) {
        //       this.trialEndDate =new Date(words[0]) ;
        //     }
        //   console.log(res);
        // });
      });
    this.getListOfSmeProducts();
  }

  getListOfSmeProducts() {
    this.spinner.show();
    this.smeProductList = [];
    this.selectedSubscriptionList = [];
    this.store
    .dispatch(
      new GetSmeProductList('1',this.frequency)
    ).subscribe((res) => {
      console.log(res);
      let smeproductList= res.smeproduct.smeproductDetailData;       
      this.selectedSubscriptionList =res.smeproduct.smeSubscriptionPortalData;     

        let addonsList: any = [];
        let isModuleSubscribed = false;
        smeproductList.forEach((x) => {
          x.addons.forEach((y) => {
            this.selectedSubscriptionList.forEach((element) => {
              if (element.itemPrice.id === y.item.id) {
                isModuleSubscribed = true;
              }
            });

            let headerText;
            if (this.frequency === PeriodUnitType.Month) {
              headerText = PeriodUnitText.Month;
            } else if (this.frequency === PeriodUnitType.Year) {
              headerText = PeriodUnitText.Year;
            } else {
              headerText = PeriodUnitText.Quarterly;
            }

            this.smeProductList.push({
              lineItems1: y.cardContent.split('\n')[0],
              lineItems2: y.cardContent.split('\n')[1],
              lineItems3: y.cardContent.split('\n')[2],

              externalName: y.item.externalName,
              description:
                y.item.description === null ? 'Addons' : y.description,
              itemId: y.item.itemId,
              planId: x.plan.id,
              frequencyText: this.getFrequencyText(),
              addOnsId: y.item.id,
              price: y.item.price,
              noOfTrailsDaysLeft: this.commonService.getNoOfDays(
                this.isTrailPeriod,
                this.isSMECancelled,
                this.selectedSubscriptionList.length > 0 &&
                  this.selectedSubscriptionList !== undefined
                  ? this.selectedSubscriptionList[0].nextBillingDate
                  : 0,
                this.selectedSubscriptionList
              ),
              nextBillingDate: this.commonService.getNextBillingDate(
                this.isTrailPeriod,
                this.isSMECancelled,
                this.selectedSubscriptionList !== undefined &&
                  this.selectedSubscriptionList.length > 0
                  ? this.selectedSubscriptionList[0].nextBillingDate
                  : new Date(),
                this.selectedSubscriptionList
              ),
              subscriptionList: this.selectedSubscriptionList,
              isModuleSubscribed: isModuleSubscribed,
              showPlans: false,
              isAddedToCart: false,
              headerText: headerText, //needed in order page
              isTrailPeriod: this.isTrailPeriod, //needed in order page
              planData: x.plan, //needed in order page
              isSMEPurchased: this.isSMEPurchased, //needed in order page
              isSMECancelled: this.isSMECancelled,
              subscribtionsType: this.subscribtionsType, //needed in order page
            });
            console.log(this.smeProductList);
          });
        });
      });
  }
  getFrequencyText(): string {
    if (this.frequency === PeriodUnitType.Month) {
      return PeriodUnitTextShort.Month;
    } else if (this.frequency == PeriodUnitType.Year) {
      return PeriodUnitTextShort.Year;
    } else {
      return PeriodUnitTextShort.Quarterly;
    }
  }

  onUpgradeClick(item: any): void {
    item.showPlans = true;
    this.noSubscription = false;
  }

  onBackClick(item: any): void {
    item.showPlans = false;
    this.noSubscription = true;
  }

  addToCart(item: any): void {
    this.storage.set('fullSuiteAddonList', null);
    this.storage.set('isUpgrade', false);
    this.storage.set('subscribtionsType', null);
    item.isAddedToCart = true;
    this.commonService.storeCartItemDataLocally(this.smeProductList);
  }

  goToCart(): void {
    let isPlanUpgraded = false;
    this.store.dispatch(new SetProductDetailAcrdngPlan()).subscribe();
    this.commonService.storeCartItemDataLocally(this.smeProductList);
    console.log(this.smeProductList);
    this.toggleReviewOrder.emit(isPlanUpgraded);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.smeProductList = [];
    if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
      if (this.storage.get('cartDetails')) {
        this.getSmeSubscriptionData();
        this.storage.removeItem('cartDetails');
        this.globalComponent.totalCartItem.next(0);
      } else {
        if (changes['frequency']) {
          this.getListOfSmeProducts();
        }
      }
    } else {
      if (changes['frequency']) {
        this.getSmeSubscriptionData();
      }
    }
  }
}
