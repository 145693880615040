import { Modules } from '@app/core/enums';
import { ExportParams } from '@app/core/models/common';

export class UpdateThemeColor {
  static readonly type = '[COLOR] Update Theme Color';

  constructor(public colorCode: string) {}
}
export class Preview {
  static readonly type = '[PREVIEW] Preview';

  constructor(public exportParams: ExportParams, public moduleId: Modules) {}
}
