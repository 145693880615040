import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';
import { CommonService } from '../services';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  isLogin = false;
  appDomain = environment.appDomain;
  domain = 'capium.co.uk';
  uiUrl = environment.uiUrl;
  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    public commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(next);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const userId = this.cookieService.get('userId') as unknown as Guid;
    if (
      userId === ('' as unknown as Guid) ||
      this.document.location.href.includes('code')
    ) {
      this.spinner.show();
    }
    return this.oidcSecurityService.checkAuth().pipe(
      map((isAuthenticated) => {
        this.isLogin = isAuthenticated;

        return this.handleTokenRedirection();
      }),
      catchError((err) => {
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }

  notLoggedIn(): void {
    const url = window.location.href;

    localStorage.clear();
    sessionStorage.clear();

    this.deleteAllCookies();

    if (url.split('/')[url.split('/').length - 1] === 'register') {
      this.isLogin = false;
    } else {
      this.login();
    }
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    this.oidcSecurityService.logoff();
  }

  deleteAllCookies(): void {
    const cookies = document.cookie.split(';');

    for (const element of cookies) {
      const cookie = element;
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  private handleTokenRedirection(): boolean {
    const token = this.oidcSecurityService.getToken();

    this.isLogin = false;

    if (!token) {
      this.notLoggedIn();

      return false;
    }

    const decoded: any = jwt_decode(token);
    if (!decoded.tenantName) {
      return false;
    }

    this.cookieService.set('userId', decoded.userId);
    this.cookieService.set('isLoggedin', !!decoded.userId ? 'true' : 'false');

    this.navigateToDashboard();

    return true;
  }

  navigateToDashboard(): void {
    if (this.document.location.href === this.uiUrl) {
      this.document.location.href =
        this.document.location.origin + '/home/products';
    } else {
      let route = `${this.uiUrl}${this.commonService.currentURl}`;
      this.router.navigate[route];
    }
  }
}
