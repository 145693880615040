import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ConfirmationType,
  AddOnsName,
  Modules,
  modulesName,
  NotificationDetails,
  NotificationHeader,
  PeriodUnitType,
  PlanName,
  SubscriptionsEnum,
  PeriodUnitText,
  PeriodUnitTextShort,
  IsBankFeed,
  IsAllocate,
  CapiumAddons,
  SubscribtionsType,
  PurchasedEnum,
  NotificationTextMessage,
  AddOnsId,
} from '@app/core/enums';
import { GlobalComponent } from '@app/core/models';
import { CommonService, LocalStorageService } from '@app/core/services';
import {
  GetProductDetailAddonsData1,
  GetSubscriptionPortalForCapiumAddonsDetails,
  ProductState,
  SetProductDetailAcrdngPlan,
  SubscriptionPortalState,
} from '@app/core/store';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { ConfirmationBoxComponent } from '@app/core/sharedmodules';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-product-addons',
  templateUrl: './product-addons.component.html',
  styleUrls: ['./product-addons.component.scss'],
})
export class ProductAddonsComponent implements OnInit {
  @Output()
  readonly toggleReviewOrder = new EventEmitter<any>();

  @Input()
  subscribtionsType: number;

  @Input()
  globalFrequency: number;

  @Select(ProductState.connectLinkList)
  connectLinkList$: Observable<Array<any>>;

  productIndividualList: Array<any> = [];
  addonsSubscriptionList: Array<any> = [];
  selectedSubscriptionList: Array<any> = [];

  planListMonth: Array<any>;
  planListYearly: Array<any>;
  planListQuarterly: Array<any>;
  bankFeedPrice: Array<any>;
  connectLinkList: any;
  noOfBankFeed = 1;

  isAddedToCart: boolean;
  isAddOnPurshed: boolean;
  isTrailPeriod = false;
  isSubscriptionCancelled = false;
  disableButton: boolean;
  selectedTab: number;

  environment = environment;
  addOnsName = AddOnsName;
  capiumAddons = CapiumAddons;
  periodUnitType = PeriodUnitType;
  addonsId = AddOnsId;
  modulesName = modulesName;

  today = moment();

  @Select(SubscriptionPortalState.subscriptionPortalData)
  subscriptionPortalData$: Observable<Array<any>>;
  isTrialSubscription: boolean;
  trialEndDate: Date;

  constructor(
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService,
    public commonService: CommonService,
    private dialog: MatDialog,
    private store: Store,
    public spinner: NgxSpinnerService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    if (this.subscribtionsType === SubscribtionsType.CapiumAddons) {
      this.getSubscriptionData();
      this.connectLinkList$.subscribe((x) => {
        this.connectLinkList = x;
      });
    }
  }

  getSubscriptionData(): void {
    this.spinner.show();

    //for testing purpose code commented do not remove
    // this.subscriptionPortalData$.subscribe((res) => {
    //   if (res.length > 0) {
    //     this.selectedSubscriptionList = res;
    //     this.isTrailPeriod = res.some((item: any) => {
    //       const isTrue =
    //         +parseInt(item.itemPrice.itemId) === +SubscriptionsEnum.trail ||
    //         +parseInt(item.status) === +PurchasedEnum.Cancelled;

    //       if (+parseInt(item.status) === +PurchasedEnum.Cancelled) {
    //         this.isSubscriptionCancelled = true;
    //       }
    //       if (+parseInt(item.itemPrice.itemId) === +SubscriptionsEnum.trail) {
    //         this.isTrialSubscription = true;
    //       }
    //       let endDate = res[0].endDate;
    //       const words = endDate.split('T');
    //       if (words.length) {
    //         this.trialEndDate = new Date(words[0]);
    //       }

    //       return isTrue;
    //     });
    //   }

    //   this.storage.set('isTrailPeriod', this.isTrailPeriod);
    //   this.storage.set('isSubscriptionCancelled', this.isSubscriptionCancelled);
    // });

    this.store
      .dispatch(new GetSubscriptionPortalForCapiumAddonsDetails())
      .subscribe((res) => {
        console.log(res);
        let subscriptionList =
          res.subscriptionPortal.subscriptionPortalDataForCapiumAddons;

        this.addonsSubscriptionList = subscriptionList.filter((item) => {
          return +item.itemPrice.itemId !== +SubscriptionsEnum.capiumAddOn;
        });

        this.isAddOnPurshed = this.addonsSubscriptionList.length > 0;

        this.getList();
      });
  }

  getList(): any {
    this.productIndividualList = [];

    if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
      if (
        this.storage
          .get('cartDetails')
          .some((e) => e.subscribtionsType === SubscribtionsType.CapiumAddons)
      ) {
        this.productIndividualList = this.storage.get('cartDetails');
        this.commonService.storeCartItemDataLocally(this.productIndividualList);
        this.spinner.hide();
      } else {
        this.getAddonsList();
      }
    } else {
      this.getAddonsList();
    }
  }

  getAddonsList(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);

    this.spinner.show();

    const userName = decoded.email;

    const externalUserGUID = decoded.ExternalUserGUID;

    this.store
      .dispatch(
        new GetProductDetailAddonsData1('1', userName, externalUserGUID)
      )
      .subscribe((res) => {
        console.log(res);
        let addonsList: any = [];
        let quarterlyAddons;
        let monthlyAddons;
        let yearlyAddons;
        res.product.productDetailAddonsData.forEach((element) => {
          if (element.frequency === PeriodUnitType.Month) {
            monthlyAddons = element.addons;
          } else if (element.frequency === PeriodUnitType.Year) {
            yearlyAddons = element.addons;
          } else {
            quarterlyAddons = element.addons;
          }
        });

        for (let i = 0; i < monthlyAddons.length; i++) {
          for (let j = 0; j < yearlyAddons.length; j++) {
            if (monthlyAddons[i].item.itemId === yearlyAddons[j].item.itemId) {
              let montly = monthlyAddons[i].item;
              montly['isDisable'] = monthlyAddons[i].isDisable;
              montly['cardContent'] = monthlyAddons[i].cardContent;

              let yearly = yearlyAddons[j].item;
              yearly['isDisable'] = yearlyAddons[j].isDisable;
              yearly['cardContent'] = yearlyAddons[j].cardContent;             


              let quarterly = quarterlyAddons.filter(
                (qitem) => qitem.item.itemId === monthlyAddons[i].item.itemId
              );

              if (quarterly.length > 0) {
                quarterly['isDisable'] = monthlyAddons[i].isDisable;
                quarterly['cardContent'] = monthlyAddons[i].cardContent;
              }

              addonsList.push({
                monthlyObj: montly,
                tiersYearly: yearlyAddons[j].item.tiers,
                yearlyObj: yearly,
                quarterlyObj: quarterly[0].item,
              });
            }
          }
        }
        console.log(addonsList);

        addonsList.forEach((y) => {
          this.planListMonth = [];
          this.planListYearly = [];
          this.planListQuarterly = [];
          this.bankFeedPrice = [];

          let subscriptionList;
          let isModuleSubscribed = false;
          let selectedPlanId = 1;
          let selectedRange = '';
          let frequency = this.globalFrequency;
          let isNonRenewing = false;

          let isBankFeed = Object.values(IsBankFeed)?.includes(y.monthlyObj.id);
          let isAllocate = Object.values(IsAllocate)?.includes(y.monthlyObj.id);
          if (this.isAddOnPurshed) {
            this.addonsSubscriptionList.forEach((element) => {
              const isMonthly =
                element.itemPrice.periodUnit === PeriodUnitType.Month &&
                element.itemPrice.period == 1;
              const isYearly =
                element.itemPrice.periodUnit === PeriodUnitType.Year &&
                element.itemPrice.period == 1;
              const selectedId = isMonthly
                ? y.monthlyObj.id
                : isYearly
                ? y.yearlyObj.id
                : y.quarterlyObj.id;

              let selectedPeriodUnit;
              if (isMonthly) {
                selectedPeriodUnit = PeriodUnitType.Month;
              } else if (isYearly) {
                selectedPeriodUnit = PeriodUnitType.Year;
              } else {
                selectedPeriodUnit = PeriodUnitType.Quarterly;
              }

              const isModuleSub = element.itemPrice.id === selectedId;

              if (isModuleSub) {
                isModuleSubscribed = true;
                selectedPlanId = element.tier;
                frequency = selectedPeriodUnit;
                isNonRenewing = element.status === PurchasedEnum.nonRenewing;
                //To set subscriptionList of the purchased subscribtion
                subscriptionList = element;
              }
            });
          }

          if (!isBankFeed) {
            y.monthlyObj.tiers.forEach((z, i) => {
              if (selectedPlanId === z.startingUnit) {
                if (
                  y.monthlyObj.id === AddOnsName.Charity ||
                  y.monthlyObj.id === AddOnsName.CharityYearly ||
                  y.monthlyObj.id === AddOnsName.CharityQuarterly
                ) {
                  selectedRange = `${z.startingUnit} - ${
                    z.endingUnit === null ? '100' : z.endingUnit
                  }`;
                } else {
                  selectedRange = `${z.startingUnit} - ${
                    z.endingUnit === null ? 'unlimited' : z.endingUnit
                  }`;
                }
              }

              this.planListMonth.push({
                price: z.price,
                planName: this.getPlanName(y.monthlyObj.id, i),
                startingUnit: z.startingUnit,
                clientsRange: this.getClientsRange(
                  y.monthlyObj.id,
                  z.startingUnit,
                  z.endingUnit
                ),
                disablePlan: this.disablePlanSelection(
                  subscriptionList,
                  z.startingUnit
                ),
              });
            });

            y.tiersYearly.forEach((z, i) => {
              if (
                selectedPlanId === z.startingUnit &&
                frequency === PeriodUnitType.Year
              ) {
                if (
                  y.monthlyObj.id === AddOnsName.Charity ||
                  y.monthlyObj.id === AddOnsName.CharityYearly ||
                  y.monthlyObj.id === AddOnsName.CharityQuarterly
                ) {
                  selectedRange = `${z.startingUnit} - ${
                    z.endingUnit === null ? '100' : z.endingUnit
                  }`;
                } else {
                  selectedRange = `${z.startingUnit} - ${
                    z.endingUnit === null ? 'unlimited' : z.endingUnit
                  }`;
                }
              }

              this.planListYearly.push({
                price: z.price,
                planName: this.getPlanName(y.monthlyObj.id, i),
                startingUnit: z.startingUnit,
                clientsRange: this.getClientsRange(
                  y.monthlyObj.id,
                  z.startingUnit,
                  z.endingUnit
                ),
                disablePlan: this.disablePlanSelection(
                  subscriptionList,
                  z.startingUnit
                ),
              });
            });

            y.quarterlyObj.tiers.forEach((z, i) => {
              if (selectedPlanId === z.startingUnit) {
                if (
                  y.quarterlyObj.id === AddOnsName.Charity ||
                  y.quarterlyObj.id === AddOnsName.CharityYearly ||
                  y.quarterlyObj.id === AddOnsName.CharityQuarterly
                ) {
                  selectedRange = `${z.startingUnit} - ${
                    z.endingUnit === null ? '100' : z.endingUnit
                  }`;
                } else {
                  selectedRange = `${z.startingUnit} - ${
                    z.endingUnit === null ? 'unlimited' : z.endingUnit
                  }`;
                }
              }

              this.planListQuarterly.push({
                price: z.price,
                planName: this.getPlanName(y.quarterlyObj.id, i),
                startingUnit: z.startingUnit,
                clientsRange: this.getClientsRange(
                  y.quarterlyObj.id,
                  z.startingUnit,
                  z.endingUnit
                ),
                disablePlan: this.disablePlanSelection(
                  subscriptionList,
                  z.startingUnit
                ),
              });
            });
          } else {
            this.bankFeedPrice.push({
              monthly: y.monthlyObj.price,
              yearly: y.yearlyObj.price,
              quarterly: y.quarterlyObj.price,
            });

            if (subscriptionList !== undefined) {
              this.noOfBankFeed = subscriptionList.tier;
            }
          }

          let frequencyText;
          console.log(frequency);
          if (frequency === PeriodUnitType.Month) {
            frequencyText = PeriodUnitTextShort.Month;
          } else if (frequency === PeriodUnitType.Year) {
            frequencyText = PeriodUnitTextShort.Year;
          } else {
            frequencyText = PeriodUnitTextShort.Quarterly;
          }

          let addOnsId;
          if (frequency === PeriodUnitType.Month) {
            addOnsId = y.monthlyObj.id;
          } else if (frequency === PeriodUnitType.Year) {
            addOnsId = y.yearlyObj.id;
          } else {
            addOnsId = y.quarterlyObj.id;
          }

          let planId;
          console.log(res.product.productDetailAddonsData);
          let addonsArray:any[]=res.product.productDetailAddonsData;
          addonsArray.forEach((Element)=>{
            if(frequency === Element.frequency){
              planId=Element.plan;
            }
          });
            

          // if (frequency === PeriodUnitType.Month) {
          //     planId = res.product.productDetailAddonsData[1].plan;
          // } else if (frequency === PeriodUnitType.Year) {
          //     planId = res.product.productDetailAddonsData[2].plan;
          // } else {
          //     planId = res.product.productDetailAddonsData[0].plan;
          // }

          let bankFeedPrice;
          if (isBankFeed) {
            if (frequency === PeriodUnitType.Month) {
              bankFeedPrice = this.bankFeedPrice[0].monthly;
            } else if (frequency === PeriodUnitType.Year) {
              bankFeedPrice = this.bankFeedPrice[0].yearly;
            } else {
              bankFeedPrice = this.bankFeedPrice[0].quarterly;
            }
          } else {
            bankFeedPrice = 0;
          }

          let headerText;
          if (frequency === PeriodUnitType.Month) {
            headerText = PeriodUnitText.Month;
          } else if (frequency === PeriodUnitType.Year) {
            headerText = PeriodUnitText.Year;
          } else {
            headerText = PeriodUnitText.Quarterly;
          }

          this.productIndividualList.push({
            lineItems1: y.monthlyObj.cardContent.split('\n')[0],
            lineItems2: y.monthlyObj.cardContent.split('\n')[1],
            lineItems3: y.monthlyObj.cardContent.split('\n')[2],
            // lineItems1:'1',
            // lineItems2: '2',
            // lineItems3: '3',
            externalName: y.monthlyObj.externalName.replace('Individual', ''),
            description:
              y.monthlyObj.description === null ? '' : y.monthlyObj.description,
            planListMonth: this.planListMonth,
            planListYearly: this.planListYearly,
            planListQuarterly: this.planListQuarterly,
            frequencyText: frequencyText,
            frequency: frequency,
            addOnsId: addOnsId,
            itemId: y.monthlyObj.itemId,
            planId: planId,
            selectedPlanId: selectedPlanId,
            selectedPlanName: !isBankFeed
              ? this.getSelectedPlanName(
                  isModuleSubscribed,
                  selectedPlanId,
                  frequency === PeriodUnitType.Month
                    ? this.planListMonth
                    : frequency === PeriodUnitType.Year
                    ? this.planListYearly
                    : this.planListQuarterly
                )
              : this.noOfBankFeed +
                (this.noOfBankFeed == 1 ? ' Bank Feed' : ' Bank Feeds'),
            selectedClientRange: selectedRange,
            noOfTrailsDaysLeft: this.commonService.getNoOfDays(
              this.isTrailPeriod,
              this.isSubscriptionCancelled,
              subscriptionList !== undefined
                ? subscriptionList.nextBillingDate
                : 0,
              this.selectedSubscriptionList
            ),
            nextBillingDate: this.commonService.getNextBillingDate(
              this.isTrailPeriod,
              this.isSubscriptionCancelled,
              subscriptionList !== undefined
                ? subscriptionList.nextBillingDate
                : new Date(),
              this.selectedSubscriptionList
            ),
            subscriptionList: subscriptionList,
            isModuleSubscribed: isModuleSubscribed,
            isBankFeed: isBankFeed,
            isAllocate: isAllocate,
            showPlans: false,
            isAddedToCart: false,
            isNonRenewing: isNonRenewing,
            isDisable: y.monthlyObj.isDisable,
            headerText: headerText,
            isTrailPeriod: this.isTrailPeriod, //needed in order page
            addOnPurshed: this.isAddOnPurshed, //needed in order page
            subscribtionsType: this.subscribtionsType, //needed in order page
            bankFeedPrice: bankFeedPrice,
            noOfBankFeed: this.noOfBankFeed, //needed in order page
          });
          console.log(this.productIndividualList, 'sample data');
          isModuleSubscribed = false;
          isNonRenewing = false;
        });
      });
  }

  onPlanChange(selectedPlanvalue: string, item: any) {
    item.selectedPlanId = +selectedPlanvalue;
  }

  getClientsRange(id: string, startingUnit: any, endingUnit: any) {
    let clientsRange = '';

    if (endingUnit !== null) {
      clientsRange = `${startingUnit} -  ${endingUnit}`;
    } else {
      clientsRange =
        id === AddOnsName.Charity || id === AddOnsName.CharityYearly
          ? '100'
          : 'unlimited';
    }

    return clientsRange;
  }

  getSelectedPlanName(
    isModuleSubscribed,
    selectedPlanId: number,
    planList: any
  ) {
    let planName;
    if (isModuleSubscribed) {
      planName = planList.find(
        (e) => e.startingUnit === +selectedPlanId
      ).planName;
    }
    return planName;
  }

  disablePlanSelection(subscriptionList: any, startingUnit: any): boolean {
    let disablePlan = false;

    if (subscriptionList !== undefined) {
      //#region  Set Plan Id disable For the lower plans from the selected plan
      disablePlan = startingUnit < subscriptionList.tier;
      //#endregion
    }

    return disablePlan;
  }

  //To disable Plan to upgrade it
  setAddToCartDisable(item: any): boolean {
    let isDisbaleAddToCart = false;
    if (item.subscriptionList !== undefined && !item.isBankFeed) {
      isDisbaleAddToCart = item.selectedPlanId === item.subscriptionList.tier;
    } else if (item.subscriptionList !== undefined && item.isBankFeed) {
      isDisbaleAddToCart = this.noOfBankFeed === item.subscriptionList.tier;
    }
    return isDisbaleAddToCart;
  }

  getPlanName(addonId: string, index: number, startingUnit?: number): any {
    if (
      addonId !== CapiumAddons.BankFeeds &&
      addonId !== CapiumAddons.BankFeedsYearly &&
      addonId !== AddOnsName.Charity &&
      addonId !== AddOnsName.CharityYearly
    ) {
      switch (index) {
        case 0:
          return PlanName.small;
        case 1:
          return PlanName.medium;
        case 2:
          return PlanName.large;
        case 3:
          return PlanName.enterprise;

        default:
          return PlanName.small;
      }
    } else if (
      addonId === AddOnsName.Charity ||
      addonId === AddOnsName.CharityYearly
    ) {
      switch (index) {
        case 0:
          return PlanName.small;
        case 1:
          return PlanName.medium;
        case 2:
          return PlanName.large;
        case 3:
          return PlanName.enterprise;
        case 4:
          return PlanName.xEnterprise;
        default:
          return PlanName.small;
      }
    } else if (
      addonId === CapiumAddons.BankFeeds ||
      addonId === CapiumAddons.BankFeedsYearly ||
      addonId === CapiumAddons.BankFeedsQuarterly
    ) {
      return `${startingUnit} Bank Feeds`;
    }
  }

  goToCart(isPlanUpgraded: boolean, item: any): void {
    item.isAddedToCart = true;
    this.setDisablePlan(item, true, true);

    this.productIndividualList.forEach((x) => {
      x.noOfBankFeed =
        x.addOnsId === CapiumAddons.BankFeeds ||
        x.addOnsId === CapiumAddons.BankFeedsYearly ||
        x.addOnsId === CapiumAddons.BankFeedsQuarterly
          ? this.noOfBankFeed
          : 0;
    });

    this.store.dispatch(new SetProductDetailAcrdngPlan()).subscribe();
    this.storage.set('subscribtionsType', this.subscribtionsType);
    this.commonService.storeCartItemDataLocally(this.productIndividualList);
    this.toggleReviewOrder.emit(isPlanUpgraded);
    console.log(this.productIndividualList);
  }

  onUpgradeClick(item: any): void {
    item.showPlans = true;
  }

  onCancelClick(item: any): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          type: ConfirmationType.CancelSubscriptions,
          ids: item.subscriptionList.subscriptionId,
          moduleId: Modules.Products,
          headerText: NotificationHeader.confirmCancellingSubscription,
          detailText:
            NotificationDetails.confirmCancellingSubscriptionDetailText,
          successMessage: NotificationTextMessage.subCancelledSuccessfully,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.ngOnInit();
        }
      });
  }

  onBackClick(item: any): void {
    item.showPlans = false;
  }

  setDisablePlan(item: any, isDisable: boolean, isAddToCart): void {
    this.productIndividualList.forEach((element) => {
      //Disable other plans apart from that plan id for the selected module
      if (item.addOnsId === element.addOnsId) {
        element.planListMonth.forEach((y) => {
          y.disablePlan = isAddToCart
            ? isDisable
            : element.subscriptionList !== undefined
            ? y.startingUnit < item.subscriptionList.tier
            : false;
        });
      }
    });
  }

  onConnectClick(item: any): void {
    let redirection = this.connectLinkList.find(
      (e) => e.individualAddonId === item.itemId
    ).demoVideoUrl;

    const url =
      redirection === undefined || redirection === null
        ? environment.stsServerUrl
        : redirection;
    window.open(url, '_blank');
  }
  AllocateRedirectUrl(item: any): void {
    let redirection = this.connectLinkList.find(
      (e) => e.individualAddonId === item.itemId
    ).appUrl;

    const url =
      redirection === undefined || redirection === null
        ? environment.stsServerUrl
        : redirection;
    window.open(url, '_blank');
  }

  removeFromCart(item: any): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          type: ConfirmationType.Delete,
          ids: item.id,
          moduleId: Modules.Products,
          headerText: NotificationHeader.removeFromCart,
          detailText: NotificationDetails.terminateDetailText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          item.isAddedToCart = false;
          this.setDisablePlan(item, false, false);
          this.commonService.storeCartItemDataLocally(
            this.productIndividualList
          );
        }
      });
  }

  onFrequencyChange(item: any, value) {
    
    item.frequency = parseInt(value.value);
    let frequencyTextShort;

    if (item.frequency === PeriodUnitType.Year) {
      frequencyTextShort = PeriodUnitTextShort.Year;
    } else {
      frequencyTextShort =
      item.frequency  === PeriodUnitType.Quarterly
          ? PeriodUnitTextShort.Quarterly
          : PeriodUnitTextShort.Month;
    }
   
    item.frequencyText = frequencyTextShort;

    let headerText;
    if (item.frequency === PeriodUnitType.Year) {
      headerText = PeriodUnitText.Year;
    } else {
      headerText =
        item.frequency === PeriodUnitType.Quarterly
          ? PeriodUnitText.Quarterly
          : PeriodUnitText.Month;
    }
    item.headerText = headerText;

    switch (item.frequency) {
      case PeriodUnitType.Month:
        item.addOnsId = item.addOnsId
          .replace('Yearly', 'Monthly')
          .replace('Every-3-months', 'Monthly');
        break;
      case PeriodUnitType.Year:
        item.addOnsId = item.addOnsId
          .replace('Monthly', 'Yearly')
          .replace('Every-3-months', 'Yearly');
        break;
      case PeriodUnitType.Quarterly:
        item.addOnsId = item.addOnsId
          .replace('Yearly', 'Every-3-months')
          .replace('Monthly', 'Every-3-months');
        break;
    }
    switch (item.frequency) {
      case PeriodUnitType.Month:
        item.planId = item.planId
          .replace('Yearly', 'Monthly')
          .replace('Every-3-months', 'Monthly');
        break;
      case PeriodUnitType.Year:
        item.planId = item.planId
          .replace('Monthly', 'Yearly')
          .replace('Every-3-months', 'Yearly');
        break;
      case PeriodUnitType.Quarterly:
        item.planId = item.planId
          .replace('Yearly', 'Every-3-months')
          .replace('Monthly', 'Every-3-months');
        break;
    }

    if (item.isBankFeed) {
      let bankFeedPrice;
      if (item.frequency === PeriodUnitType.Month) {
        bankFeedPrice = this.bankFeedPrice[0].monthly;
      } else if (item.frequency === PeriodUnitType.Year) {
        bankFeedPrice = this.bankFeedPrice[0].yearly;
      } else {
        bankFeedPrice = this.bankFeedPrice[0].quarterly;
      }
      item.bankFeedPrice = bankFeedPrice;
    }
  }

  handleMinus(item: any): void {
    if (this.noOfBankFeed > 1) {
      if (
        item.subscriptionList !== undefined &&
        this.noOfBankFeed !== item.subscriptionList.tier
      ) {
        this.lessNoOfBankFeed();
      } else if (item.subscriptionList === undefined) {
        this.lessNoOfBankFeed();
      }
    }
  }

  lessNoOfBankFeed(): void {
    this.noOfBankFeed--;
  }

  handlePlus(): void {
    this.noOfBankFeed++;
  }

  onConnectToSalesClick(item: any): void {
    this.commonService.triggerConnectUs.next(item.itemId);
  }

  checkingAddons(item: any): boolean {
    if (parseInt(item.itemId) !== this.modulesName['Company Secretarial']) {
      return true;
    } else if (
      parseInt(item.itemId) === this.modulesName['Company Secretarial'] &&
      item.isModuleSubscribed
    ) {
      return true;
    } else {
      return false;
    }
  }
}
