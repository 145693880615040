<div class="d-flex justify-space-between align-items-center heading">
  <div>
    <h3 class="pb-5 no-border">Add New Card</h3>
  </div>
  <button class="action-button" mat-button (click)="cancel()">
    <div>
      <mat-icon>close</mat-icon>
    </div>
  </button>
</div>

<div class="card payment-card-form form-width">
  <div class="mat-dialog-wrapper form-list-content pb-0">
    <div class="contact-title">
      <h2>Enter Card details</h2>
    </div>
    <form class="mt-1" [formGroup]="paymentCardForm">
      <div>
        <div class="form-field">
          <div class="flex-1">
            <p>First Name <span class="text-danger-300">*</span></p>
            <mat-form-field class="wp-100">
              <input
                matInput
                formControlName="firstName"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-field">
          <div class="flex-1">
            <p>Last Name <span class="text-danger-300">*</span></p>
            <mat-form-field class="wp-100">
              <input
                matInput
                formControlName="lastName"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="form-field">
          <div class="flex-1">
            <p>
              Credit / Debit Card Number<span class="text-danger-300">*</span>
            </p>
            <mat-form-field class="wp-100">
              <input
                matInput
                maxlength="100"
                formControlName="cardNumber"
                autocomplete="off"
                (keyup)="checkValidCardNumber($event)"
                required
              />
            </mat-form-field>
            <mat-error *ngIf="!isValidCard" class="line-height-normal">
              Invalid Credit Card Number
            </mat-error>
          </div>
          <div class="d-flex card-type gap-10">
            <img
              *ngIf="cardType == 1"
              src="{{ environment.imagePath }}/visa.png"
              alt="Visa"
            />
            <img
              *ngIf="cardType == 2"
              src="{{ environment.imagePath }}/mastercard.png"
              alt="Master Card"
            />
            <img
              *ngIf="cardType == 3"
              src="{{ environment.imagePath }}/american-express.png"
              alt="American Express"
            />
          </div>
        </div>
        <div class="form-field d-flex gap-20">
          <div class="flex-1">
            <p>Validity Period <span class="text-danger-300">*</span></p>
            <mat-form-field class="wp-100 mini-place">
              <input
                matInput
                formControlName="cardPeriod"
                autocomplete="off"
                required
                placeholder="DD/YYYY"
                (keyup)="checkValidityPeriod($event)"
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                paymentCardForm.get('cardPeriod').hasError('pattern') ||
                !isValidPeriod
              "
              class="line-height-normal"
            >
              Invalid validity period
            </mat-error>
          </div>
          <div class="flex-1">
            <p class="d-flex">
              CVV / CVC <span class="text-danger-300">*</span>
              <mat-icon
                class="text-primary ml-10"
                fontSet="material-icons-outlined"
                >info</mat-icon
              >
            </p>
            <mat-form-field class="wp-100">
              <input
                matInput
                maxlength="3"
                formControlName="cvvNumber"
                autocomplete="off"
                required
              />
            </mat-form-field>

            <mat-error
              *ngIf="paymentCardForm.get('cvvNumber').hasError('pattern')"
              class="line-height-normal"
            >
              Invalid CVV
            </mat-error>
          </div>
        </div>
        <div class="form-field" *ngIf="showSetAsPrimary">
          <mat-checkbox class="p-0" formControlName="setAsPrimary"
            >Set this card as default
          </mat-checkbox>
        </div>
      </div>
    </form>
    <div class="contact-button">
      <button
        class="action-button primary-button mr-1"
        mat-button
        disableRipple
        (click)="send()"
      >
        Save
      </button>
      <button
        class="action-button secondary-button"
        mat-button
        disableRipple
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
