import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from '@app/core/services';
import {
  ConfirmationType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { ConfirmationList } from '@app/core/models';
import { NotificationService } from '@app/core/services';
import {
  CancelSubscriptions,
  DeletePaymentCard,
  DeleteSmePaymentCard,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {
  confirmationList: ConfirmationList;
  isSME: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService,
    private store: Store,
    public storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');
    this.confirmationList = this.data;
  }

  onYesClick(): void {
    this.spinner.show();

    if (this.confirmationList.type === ConfirmationType.Delete) {
      this.onDeleteClick();
    }

    if (this.confirmationList.type === ConfirmationType.CancelSubscriptions) {
      this.onCancelSubscriptionsClick();
    }
  }

  onCancelSubscriptionsClick(): void {
    this.store
      .dispatch(new CancelSubscriptions(this.confirmationList.ids))
      .subscribe((res) => {
        if (res) this.onSuccess(this.confirmationList.type);
      });
  }

  onDeleteClick(): void {
    if (!this.isSME) {
      if (this.confirmationList.moduleId === Modules.Products) {
        this.spinner.hide();
        this.dialogRef.close(true);
      } else {
        this.store
          .dispatch(new DeletePaymentCard(this.confirmationList.ids))
          .subscribe((res) => {
            if (res) this.onSuccess(this.confirmationList.type);
          });
      }
    } else {
      if (this.confirmationList.moduleId === Modules.Products) {
        this.spinner.hide();
        this.dialogRef.close(true);
      } else {
        this.store
          .dispatch(new DeleteSmePaymentCard(this.confirmationList.ids))
          .subscribe((res) => {
            if (res) this.onSuccess(this.confirmationList.type);
          });
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onFailure(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccess(confirmationListType): void {
    let notificationHeader;
    let notificationDetail;

    if (ConfirmationType.Delete === confirmationListType) {
      notificationHeader = NotificationHeader.success;
      notificationDetail =
        this.confirmationList.successMessage === undefined
          ? NotificationTextMessage.recordDeletedSuccessfully
          : this.confirmationList.successMessage;
    }
    if (ConfirmationType.CancelSubscriptions === confirmationListType) {
      notificationHeader = NotificationHeader.confirmCancellingSubscription;
      notificationDetail = NotificationTextMessage.subCancelledSuccessfully;
    }

    this.notifier.success(notificationHeader, notificationDetail);
    this.dialogRef.close(true);
  }
}
