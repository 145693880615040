<div class="mt-1 product-list-wrap">
  <h2 class="text-primary font-size-20 mb-1 d-none">For Accountants</h2>
  <div class="card-wrapper mb-1">
    <div
      class="card position-relative pl-0 pr-0"
      *ngFor="let item of productIndividualList"
    >
      <h1>{{ item.id }}</h1>
      <div [ngClass]="{ 'readonly-card-warpper': item.isDisable }">
        <div [ngClass]="{ 'readonly-card': item.isDisable }">
          <div class="card-head pl-1 pr-1">
            <div class="d-flex align-items-center gap-10">
              <img
                src="{{ commonService.getImagePath(item.addOnsId) }}"
                alt="icon"
              />
              <div>
                <p class="m-0">{{ item.externalName }}</p>

                <span>{{ item.description }}</span>
              </div>
            </div>
          </div>
          <div class="card-list">
            <div class="d-flex align-items-center gap-10 pl-1 pr-1 mb-10 pb-5">
              <div class="package-selection">
                <mat-button-toggle-group
                  name="fontStyle"
                  value="{{ item.frequency }}"
                  (change)="onFrequencyChange(item, $event)"
                  aria-label="Font Style"
                  [disabled]="item.isModuleSubscribed"
                >
                  <mat-button-toggle value="{{ periodUnitType.Month }}"
                    >Bill monthly
                  </mat-button-toggle>
                  <mat-button-toggle value="{{ periodUnitType.Quarterly }}"
                    >Bill quarterly</mat-button-toggle
                  >
                  <mat-button-toggle value="{{ periodUnitType.Year }}"
                    >Bill yearly</mat-button-toggle
                  >
                </mat-button-toggle-group>
              </div>
            </div>
            <ul class="pl-1 pr-1">
              <li>
                <mat-icon class="success mr-5">check_circle</mat-icon>
                {{ item.lineItems1 }}
              </li>
              <li>
                <mat-icon class="success mr-5">check_circle</mat-icon>
                {{ item.lineItems2 }}
              </li>
              <li>
                <mat-icon class="success mr-5">check_circle</mat-icon>
                {{ item.lineItems3 }}
              </li>
            </ul>
            <div class="connect-button">
              <div class="plan-details">
                <!-- when module is not Subscribe then only toggle button will be visible -->
                <div
                  class="plan-list-view"
                  *ngIf="item.showPlans && item.frequency == 3"
                >
                  <mat-button-toggle-group value="{{ item.selectedPlanId }}">
                    <div
                      class="package-selection"
                      *ngFor="let pl of item.planListMonth; let i = index"
                    >
                      <mat-button-toggle
                        [ngClass]="{ 'toggle-disable': pl.disablePlan }"
                        value="{{ pl.startingUnit }}"
                        (change)="onPlanChange($event.value, item)"
                        checked
                        disableRipple
                        [disabled]="pl.disablePlan"
                        >{{ pl.planName }}
                      </mat-button-toggle>
                    </div>
                  </mat-button-toggle-group>

                  <!-- when module is not Subscribe then only toggle button will be visible -->
                  <div class="plan-list">
                    <div *ngFor="let pl1 of item.planListMonth">
                      £ <strong>{{ pl1.price / 100 }}</strong
                      >/{{ item.frequencyText }}
                      <br />
                      {{ pl1.clientsRange }}
                    </div>
                  </div>
                </div>

                <div
                  class="plan-list-view"
                  *ngIf="item.showPlans && item.frequency == 4"
                >
                  <mat-button-toggle-group value="{{ item.selectedPlanId }}">
                    <div
                      class="package-selection"
                      *ngFor="let pl of item.planListYearly; let i = index"
                    >
                      <mat-button-toggle
                        [ngClass]="{ 'toggle-disable': pl.disablePlan }"
                        value="{{ pl.startingUnit }}"
                        (change)="onPlanChange($event.value, item)"
                        checked
                        disableRipple
                        [disabled]="pl.disablePlan"
                        >{{ pl.planName }}
                      </mat-button-toggle>
                    </div>
                  </mat-button-toggle-group>

                  <!-- when module is not Subscribe then only toggle button will be visible -->
                  <div class="plan-list">
                    <div *ngFor="let pl1 of item.planListYearly">
                      £ <strong>{{ pl1.price / 100 }}</strong
                      >/{{ item.frequencyText }}
                      <br />
                      {{ pl1.clientsRange }}
                    </div>
                  </div>
                </div>

                <div
                  class="plan-list-view"
                  *ngIf="item.showPlans && item.frequency == 5"
                >
                  <mat-button-toggle-group value="{{ item.selectedPlanId }}">
                    <div
                      class="package-selection"
                      *ngFor="let pl of item.planListQuarterly; let i = index"
                    >
                      <mat-button-toggle
                        [ngClass]="{ 'toggle-disable': pl.disablePlan }"
                        value="{{ pl.startingUnit }}"
                        (change)="onPlanChange($event.value, item)"
                        checked
                        disableRipple
                        [disabled]="pl.disablePlan"
                        >{{ pl.planName }}
                      </mat-button-toggle>
                    </div>
                  </mat-button-toggle-group>

                  <!-- when module is not Subscribe then only toggle button will be visible -->
                  <div class="plan-list">
                    <div *ngFor="let pl1 of item.planListQuarterly">
                      £ <strong>{{ pl1.price / 100 }}</strong
                      >/{{ item.frequencyText }}
                      <br />
                      {{ pl1.clientsRange }}
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="item.isBankFeed"
                  class="pt-1 pl-1 pr-1 d-flex align-items-center justify-space-between gap-15"
                >
                  <div class="count-number">
                    <button class="plusminus" (click)="handleMinus(item)">
                      -
                    </button>
                    <input
                      matInput
                      type="number"
                      class="num"
                      readonly
                      min="1"
                      max="36"
                      [(ngModel)]="noOfBankFeed"
                    />
                    <button class="plusminus" (click)="handlePlus()">+</button>
                  </div>
                  <div
                    class="d-flex align-items-center d-none gap-5"
                    *ngIf="item.isAllocate && item.isModuleSubscribed"
                  >
                    <mat-icon
                      class="material-icons-outlined text-primary check-icon"
                      >verified</mat-icon
                    >
                    <div (click)="AllocateRedirectUrl(item)">
                      <a class="link">Allocate</a>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="!item.showPlans"
                  class="connect-button pl-1 pr-1 pt-10 pb-5"
                >
                  <div
                    *ngIf="!item.isBankFeed"
                    class="connect-button-warapper watch-button mb-5 wp-100"
                  >
                    <!-- when module is not Subscribe readonly button -->
                    <h4 class="cursor-pointer mb-0 pb-10">Product Overview</h4>
                    <div
                      [ngClass]="
                        item.isModuleSubscribed || isTrailPeriod
                          ? ''
                          : 'readonly-button lock-button no-border'
                      "
                    >
                      <div
                        class="d-flex align-items-center justify-space-between"
                      >
                        <div
                          (click)="onConnectClick(item)"
                          class="d-flex align-items-center gap-10 cursor-pointer"
                        >
                          <img src="assets/img/Thumbnail.svg" alt="Watch Now" />
                          <a class="link">Watch Now</a>
                        </div>
                        <div
                          class="d-flex align-items-center gap-5 d-none"
                          *ngIf="item.isAllocate && item.isModuleSubscribed"
                        >
                          <mat-icon
                            class="material-icons-outlined text-primary check-icon"
                            >verified</mat-icon
                          >
                          <div (click)="AllocateRedirectUrl(item)">
                            <a class="link">Allocate</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- if in trail plan -->
                  <p
                    class="danger"
                    *ngIf="
                      isTrailPeriod &&
                      item.noOfTrailsDaysLeft > 0 &&
                      (item.itemId == addonsId.TimeAndFee ||
                        item.itemId == addonsId.Charity) &&
                      !item.isModuleSubscribed
                    "
                  >
                    {{ item.noOfTrailsDaysLeft }}
                    {{ item.noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
                    Trial ends on :
                    {{
                      (isTrialSubscription
                        ? trialEndDate
                        : item.nextBillingDate
                      ) | date
                    }}
                  </p>
                  <p *ngIf="item.isModuleSubscribed">
                    {{ item.selectedPlanName }}
                    {{ item.isBankFeed ? "" : ":" }}
                    {{ item.selectedClientRange }} | Subscription due:
                    {{ item.nextBillingDate | date }}
                  </p>
                  <ng-container
                    *ngIf="
                      item.itemId != addonsId.TimeAndFee &&
                      item.itemId != addonsId.Charity
                    "
                  >
                    <p *ngIf="!isTrialSubscription && !item.isModuleSubscribed">
                      No Subscription Yet!
                    </p>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      item.itemId == addonsId.TimeAndFee ||
                      item.itemId == addonsId.Charity
                    "
                  >
                    <p *ngIf="!isTrialSubscription && !item.isModuleSubscribed">
                      No Subscription Yet!
                    </p>
                    <p
                      class="danger"
                      *ngIf="
                        isTrialSubscription &&
                        isSubscriptionCancelled &&
                        item.noOfTrailsDaysLeft <= 0 &&
                        (item.itemId == addonsId.TimeAndFee ||
                          item.itemId == addonsId.Charity) &&
                        !item.isModuleSubscribed
                      "
                    >
                      Trial ended on :
                      {{ item.nextBillingDate | date }}
                    </p>
                  </ng-container>

                  <p class="fw-bold" *ngIf="item.isBankFeed">
                    {{
                      globalFrequency == 4
                        ? "Yearly"
                        : globalFrequency == 3
                        ? "Monthly"
                        : "Quarterly"
                    }}
                    price per bank feed: £ {{ item.bankFeedPrice / 100 }}
                  </p>
                </div>

                <div
                  *ngIf="item.showPlans || item.isBankFeed"
                  class="card-foot text-align-center"
                >
                  <div
                    *ngIf="!item.isAddedToCart"
                    [ngClass]="
                      item.isBankFeed ? '' : 'd-flex justify-space-between'
                    "
                    class="card-foot-button"
                  >
                    <button
                      *ngIf="!item.isBankFeed"
                      (click)="onBackClick(item)"
                      class="action-button text-primary"
                      mat-button
                    >
                      <mat-icon fontSet="material-icons-outlined"
                        >arrow_back</mat-icon
                      >
                      <span>Go Back</span>
                    </button>

                    <div
                      *ngIf="checkingAddons(item)"
                      [ngClass]="{
                        'd-flex justify-content-space-evenly gap-20':
                          !isTrailPeriod &&
                          item.isModuleSubscribed &&
                          item.isBankFeed
                      }"
                      class="connect-button-warapper"
                    >
                      <button
                        (click)="goToCart(item.isModuleSubscribed, item)"
                        class="action-button primary-button"
                        [ngClass]="{
                          'readonly-button ': setAddToCartDisable(item)
                        }"
                        mat-button
                      >
                        <mat-icon class="m-0">shopping_cart_checkout</mat-icon>
                        <span>Go to cart</span>
                      </button>
                    </div>

                    <div
                      class="pt-10"
                      *ngIf="
                        (item.addOnsId ===
                          capiumAddons.CompanySecretarialYearly ||
                          item.addOnsId === capiumAddons.CompanySecretarial ||
                          item.addOnsId ===
                            capiumAddons.CompanySecretarialQuarterly) &&
                        !item.isModuleSubscribed
                      "
                    >
                      <a
                        class="gap-20 upgrade-btn"
                        (click)="onConnectToSalesClick(item)"
                      >
                        <span class="gap-20 justify-content-space-evenly">
                          Connect with sales team
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="!item.showPlans && !item.isBankFeed"
                  class="card-foot"
                  [ngClass]="{
                    'd-flex justify-content-space-evenly gap-20':
                      !isTrailPeriod && item.isModuleSubscribed,
                    'readonly-button cursor-not-allowed': item.isNonRenewing
                  }"
                >
                  <button
                    class="action-button primary-button gap-20 upgrade-btn"
                    mat-button
                    disableRipple
                    (click)="onUpgradeClick(item)"
                  >
                    <mat-icon fontSet="material-icons-outlined" class="m-0"
                      >shopping_bag</mat-icon
                    >
                    <span *ngIf="isTrailPeriod"> Subscribe now</span>

                    <span *ngIf="!isTrailPeriod && !item.isModuleSubscribed">
                      Buy Now
                    </span>

                    <span *ngIf="!isTrailPeriod && item.isModuleSubscribed">
                      Upgrade
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
