export class GetSubscriptionPortalDetails {
  static readonly type = '[subscriptionPortal] Get Subscription Portal Details';
}

export class GetSubscriptionPortalForCapiumAddonsDetails {
  static readonly type =
    '[subscriptionPortal] Get Subscription Portal For Capium Addons Details';
}

export class GetSubscriptionPortalForCapium365 {
  static readonly type = '[subscriptionPortal] Get Retrive item List for Capium 365 Addons';
  constructor(   
    public userName: string
  ) {}
}
