export const environment = {
  production: false,
  uiUrl: 'https://store.dev.capium.co.uk/',
  appDomain: 'store.dev',
  stsServerUrl: 'https://identity.dev.capium.co.uk',
  identityLoginUrl: 'https://identity.dev.capium.co.uk/Account/Login',
  clientId: 'capium_timeandfee_angular',
  // imagePath: 'https://cdn.dev.capium.co.uk/shared/subscriptionportal',
  imagePath: '/\assets/\images',
  // apiVersionUrl: 'https://localhost:44370/api/v1/',
  apiVersionUrl: 'https://storeapi.dev.capium.co.uk/api/v1/',
  //apiVersionUrl: 'https://api-subscriptionportal-dev.azurewebsites.net/api/v1/',
  commonapiVersionUrl: 'https://communicationframworkapi.dev.capium.co.uk/api/',
  helpDeskUrl: 'https://capium.freshdesk.com/support/home',
  diybooxDemoUrl: 'https://cdn.dev.capium.co.uk/shared/content/demovideos/AccountsProductionCorporationTax.mp4',
};
