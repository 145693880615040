import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingHistoryService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    public globalComponent: GlobalComponent
  ) {}

  getBillingHistoryBillDetails(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BillingHistory/RetrievingTransactionByCustomerId`,
      JSON.stringify(queryParams),
      headers
    );
  }
  getSmeBillingHistoryBillDetails(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/RetrievingSmeTransactionByCustomerId`,
      JSON.stringify(queryParams),
      headers
    );
  }
}
