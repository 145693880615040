export class GetInvoiceList {
  static readonly type = '[Invoice] Get Invoice Details';
  constructor(public queryParams: any) {}
}

export class DownloadInvoiceListByPDF {
  static readonly type = '[Invoice] Download Invoice List By PDF';
  constructor(public invoiceId: any) {}
}

export class DownloadCapiumInvoiceListByPDF {
  static readonly type = '[Invoice] Download Capium Invoice List By PDF';
  constructor(public invoiceId: any, public userId: any) {}
}

export class CollectPaymentForAnInvoice {
  static readonly type = '[Invoice] Get Payment';
  constructor(public redirectUrl: string) {}
}

export class GetSmeInvoiceList {
  static readonly type = '[Invoice] Get Sme Invoice Details';
  constructor(public queryParams: any) {}
}

export class DownloadSmeInvoiceListByPDF {
  static readonly type = '[Invoice] Download Sme Invoice List By PDF';
  constructor(public invoiceId: any) {}
}

export class CollectPaymentForSmeInvoice {
  static readonly type = '[Invoice] Get Sme Payment';
  constructor(public redirectUrl: string) {}
}
