import { Injectable } from '@angular/core';
import { PaymentCardModel } from '@app/core/models';
import { PaymentMethodService } from '@app/core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  DeletePaymentCard,
  DeleteSmePaymentCard,
  GetCountryList,
  GetPaymentList,
  GetSmePaymentList,
  RetrieveHostedPage,
  RetrieveSmeHostedPage,
  SaveBankDetails,
  SavePaymentCardDetails,
  SaveSmeBankDetails,
  SaveSmePaymentCardDetails,
  UpdateBankDetails,
  UpdatePaymentCardDetails,
  UpdateSmeBankDetails,
  UpdateSmePaymentCardDetails,
} from './payment-method.action';

export class PaymentMethodStateInfo {
  id?: number;
  cardData?: PaymentCardModel;
  bankData?: any;
  isPaymentSuccessful?: any;
  isAddedSuccessful?: boolean;
  countryList?: any;
  errorMessage?: any;
}

@State<PaymentMethodStateInfo>({
  name: 'paymentMethod',
  defaults: {
    id: 0,
  },
})
@Injectable()
export class PaymentMethodState {
  constructor(private paymentMethodService: PaymentMethodService) {}

  @Action(GetPaymentList)
  getPaymentList(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: GetPaymentList
  ) {
    return this.paymentMethodService.getPaymentList().pipe(
      tap((res) => {
        let cardData: any = [];
        let bankData: any = [];

        res.valueResult.forEach((element) => {
          if (element.paymentSourceType === 1) {
            cardData.push(element);
          }
          if (element.paymentSourceType === 4) {
            bankData.push(element);
          }
        });

        patchState({
          cardData: cardData,
          bankData: bankData,
        });
      })
    );
  }

  @Action(SavePaymentCardDetails)
  savePaymentCardDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: SavePaymentCardDetails
  ) {
    return this.paymentMethodService
      .savePaymentCardDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            isAddedSuccessful: JSON.parse(res.valueResult.status),
            errorMessage: res.valueResult.message,
          });
        })
      );
  }

  @Action(UpdatePaymentCardDetails)
  updatePaymentCardDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: UpdatePaymentCardDetails
  ) {
    return this.paymentMethodService
      .updatePaymentCardDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            isAddedSuccessful: res.isSuccess,
          });
        })
      );
  }

  @Action(DeletePaymentCard)
  deletePaymentCard(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: DeletePaymentCard
  ) {
    return this.paymentMethodService
      .deletePaymentCard(action.paymentCardId)
      .pipe(
        tap((res) => {
          patchState({
            cardData: res.valueResult,
          });
        })
      );
  }

  @Action(RetrieveHostedPage)
  retrieveHostedPage(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: RetrieveHostedPage
  ) {
    return this.paymentMethodService
      .retrieveHostedPage(
        action.paymentMethodId,
        action.subscriptionId,
        action.isTrailPeriod,
        action.IsCancelTrial
      )
      .pipe(
        tap((res) => {
          patchState({
            isPaymentSuccessful:
              JSON.parse(res.valueResult.cnt).hosted_page.state === 'succeeded',
          });
        })
      );
  }

  @Action(RetrieveSmeHostedPage)
  retrieveSmeHostedPage(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: RetrieveSmeHostedPage
  ) {
    return this.paymentMethodService
      .retrieveSmeHostedPage(
        action.paymentMethodId,
        action.subscriptionId,
        action.isTrailPeriod
      )
      .pipe(
        tap((res) => {
          patchState({
            isPaymentSuccessful:
              JSON.parse(res.valueResult.cnt).hosted_page.state === 'succeeded',
          });
        })
      );
  }

  @Action(SaveBankDetails)
  saveBankDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: SaveBankDetails
  ) {
    return this.paymentMethodService.saveBankDetails(action.queryParams).pipe(
      tap((res) => {
        patchState({
          isAddedSuccessful: res.isSuccess,
          errorMessage: res.valueResult.message,
        });
      })
    );
  }

  @Action(UpdateBankDetails)
  updateBankDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: UpdateBankDetails
  ) {
    return this.paymentMethodService.updateBankDetails(action.queryParams).pipe(
      tap((res) => {
        patchState({
          isAddedSuccessful: res.isSuccess,
        });
      })
    );
  }

  @Action(GetCountryList)
  getCountryList(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: GetCountryList
  ) {
    return this.paymentMethodService.getCountryList().pipe(
      tap((res) => {
        patchState({
          countryList: res.valueResult.countryList,
        });
      })
    );
  }

  @Action(GetSmePaymentList)
  getSmePaymentList(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: GetSmePaymentList
  ) {
    return this.paymentMethodService.getSmePaymentList().pipe(
      tap((res) => {
        let cardData: any = [];
        let bankData: any = [];

        res.valueResult.forEach((element) => {
          if (element.paymentSourceType === 1) {
            cardData.push(element);
          }
          if (element.paymentSourceType === 4) {
            bankData.push(element);
          }
        });

        patchState({
          cardData: cardData,
          bankData: bankData,
        });
      })
    );
  }

  @Action(SaveSmePaymentCardDetails)
  saveSmePaymentCardDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: SaveSmePaymentCardDetails
  ) {
    return this.paymentMethodService
      .saveSmePaymentCardDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            isAddedSuccessful: JSON.parse(res.valueResult.status),
            errorMessage: res.valueResult.message,
          });
        })
      );
  }

  @Action(UpdateSmePaymentCardDetails)
  updateSmePaymentCardDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: UpdateSmePaymentCardDetails
  ) {
    return this.paymentMethodService
      .updateSmePaymentCardDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            isAddedSuccessful: res.isSuccess,
          });
        })
      );
  }

  @Action(DeleteSmePaymentCard)
  deleteSmePaymentCard(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: DeleteSmePaymentCard
  ) {
    return this.paymentMethodService
      .deleteSmePaymentCard(action.paymentCardId)
      .pipe(
        tap((res) => {
          patchState({
            cardData: res.valueResult,
          });
        })
      );
  }

  @Action(SaveSmeBankDetails)
  saveSmeBankDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: SaveSmeBankDetails
  ) {
    return this.paymentMethodService
      .saveSmeBankDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            isAddedSuccessful: res.isSuccess,
            errorMessage: res.valueResult.message,
          });
        })
      );
  }

  @Action(UpdateSmeBankDetails)
  updateSmeBankDetails(
    { patchState }: StateContext<PaymentMethodStateInfo>,
    action: UpdateSmeBankDetails
  ) {
    return this.paymentMethodService
      .updateSmeBankDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            isAddedSuccessful: res.isSuccess,
          });
        })
      );
  }
}
