<div class="order-details mb-1">
  <div *ngIf="productAddOnList.length !== 0" class="heading">
    <h3>Review Order</h3>
  </div>
  <div *ngIf="productAddOnList.length !== 0" class="order-list mt-2">
    <div class="order-item-list">
      <div class="d-flex flex-column">
        <span class="font-size-18 text-gray mb-1">{{ headerText }}</span>
      </div>
      <div class="mat-expansion-panel-space">
        <div class="order-item-wrapper">
          <div
            class="order-item-detail d-flex justify-space-between align-items-center bg-primary text-white"
          >
            <span>Module</span>
            <span
              class="d-flex justify-content-end"
              *ngIf="
                subscribtionsType !== subscribtionsTypeEnum.FullSuite &&
                !isBankFeed
              "
              >Plan Type</span
            >
            <span
              class="d-flex justify-content-end"
              *ngIf="subscribtionsType === subscribtionsTypeEnum.Indidvidual"
              >No. of Clients</span
            >

            <span
              class="d-flex justify-content-end"
              *ngIf="subscribtionsType === subscribtionsTypeEnum.CapiumAddons || subscribtionsType === subscribtionsTypeEnum.Capium365"
              >Count</span
            >
            <span class="d-flex justify-content-end">Price</span>

            <span class="d-flex justify-content-end" class="w-25"></span>
          </div>
          <div *ngFor="let item of productAddOnList; let i = index">
            <div
              class="order-item-detail list d-flex justify-space-between align-items-center"
            >
              <span>
                <img
                  src="{{
                    subscribtionsType === subscribtionsTypeEnum.FullSuite
                      ? commonService.getImagePathForFullSuite(item.itemId)
                      : commonService.getImagePath(item.addOnsId)
                  }}"
                  alt="icon"
                />

                {{ item.externalName }}
              </span>
              <span
                *ngIf="
                  subscribtionsType !== subscribtionsTypeEnum.FullSuite &&
                  !isBankFeed
                "
                class="d-flex justify-content-end"
                >{{ item.planName }}</span
              >
              <span
                *ngIf="
                  subscribtionsType === subscribtionsTypeEnum.Indidvidual ||
                  subscribtionsType === subscribtionsTypeEnum.CapiumAddons ||
                  subscribtionsType === subscribtionsTypeEnum.Capium365
                "
                class="d-flex justify-content-end"
                >{{ item.clientsRange }}
              </span>
              <span class="d-flex justify-content-end"
                >£ {{ item.price / 100 }}</span
              >

              <span class="w-25">
                <button
                  *ngIf="
                    (((item.itemId === recommendedModules.TimeAndFee ||
                      item.itemId === recommendedModules.Charity) &&  subscribtionsType === subscribtionsTypeEnum.FullSuite) ||                 
                    subscribtionsType === subscribtionsTypeEnum.Indidvidual)
                  "
                  mat-button
                  class="ml-10 remove-item"
                  (click)="removeFromCart(item, i)"
                >
                  <mat-icon class="text-gray-300">close</mat-icon>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-payment-list">
      <div class="order-payment-item d-flex justify-space-between">
        <span class="fw-bold">Sub Total</span>
        <span class="fw-bold">£ {{ subTotal }}</span>
      </div>

      <hr />
      <div class="order-payment-item d-flex justify-space-between mt-1">
        <span class="font-size-16 fw-bold">Total(Excluding VAT)</span>
        <span class="fw-bold font-size-18">£ {{ total }}</span>
      </div>      
    </div>
    <div class="d-flex justify-space-between mt-1" *ngIf="!isPlanUpgraded&&!isOnlyBankFeed">
      <span > <mat-checkbox class="custom-checkbox"  (change)="onContract($event)">All subscriptions are 12-month contracts</mat-checkbox></span>      
    </div>
  </div>
  <div
    class="norecord-data text-align-center mt-3"
    *ngIf="productAddOnList.length === 0"
  >
    <img src="assets/img/empty-cart.svg" alt="Empty Cart" width="260px" />
    <h2 class="pt-1">Your cart is empty</h2>
    <p>
      Looks like you have not added anything to your cart. <br />
      Go ahead & explore top products.
    </p>
    <button
      (click)="goToProductPage()"
      class="action-button primary-button"
      mat-button
      disableRipple
    >
      Go to Product Page
    </button>
  </div>
</div>
<div *ngIf="productAddOnList.length !== 0" class="review-button">
  <div class="sidenav-body-content d-flex justify-space-between pr-0 pl-0 pt-0">
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onContinueShopping()"
    >
      Continue Shopping
    </button>

    <button    
    [ngClass]="readonlyButtonClass"
      class="action-button primary-button "
      type="button"
      mat-button
      disableRipple
      *ngIf="productAddOnList.length > 0"
      (click)="onPayNow()"
    >
      {{ isPlanUpgraded ? "Upgrade Plan" : "Pay Now" }}
    </button>    
  </div>
</div>
