import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '..';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    public commonService: CommonService,
    public globalComponent: GlobalComponent
  ) {}

  getInvoiceList(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoices/RetrieveInvListByCustomerId`,
      queryParams,
      headers
    );
  }

  getPayment(redirectUrl: string): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }Invoices/CollectPaymentForAnInvoice?customerId=${this.globalComponent.customerId()}&RedirectUrl=${redirectUrl}`
    );
  }

  downloadInvoiceListByPDF(invoiceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoices/RetrieveInvPDFById?invId=${invoiceId}`,
      headers
    );
  }

  downloadCapiumInvoiceListByPDF(invoiceId: any, userId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoices/RetrieveCapiumInvPDFById?invId=${invoiceId}&userId=${userId}`,
      headers
    );
  }

  getSmeInvoiceList(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/RetrieveSmeInvListByCustomerId`,
      queryParams,
      headers
    );
  }
  getSmePayment(redirectUrl: string): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }SmeProducts/CollectPaymentForSmeInvoice?customerId=${this.globalComponent.customerId()}&RedirectUrl=${redirectUrl}`
    );
  }

  downloadSmeInvoiceListByPDF(invoiceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/RetrieveSmeInvPDFById?invId=${invoiceId}`,
      headers
    );
  }
}
