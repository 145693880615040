import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NotificationType } from '@app/core/models/common';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public subject = new Subject<Notification>();
  public keepAfterRouteChange = true;

  constructor(public router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(header: string, message: string, keepAfterRouteChange = true): any {
    this.showNotification(
      header,
      NotificationType.Success,
      message,
      keepAfterRouteChange
    );
  }

  error(header: string, message: string, keepAfterRouteChange = true): any {
    this.showNotification(
      header,
      NotificationType.Error,
      message,
      keepAfterRouteChange
    );
  }

  info(header: string, message: string, keepAfterRouteChange = false): any {
    this.showNotification(
      header,
      NotificationType.Info,
      message,
      keepAfterRouteChange
    );
  }

  warn(header: string, message: string, keepAfterRouteChange = false): any {
    this.showNotification(
      header,
      NotificationType.Warning,
      message,
      keepAfterRouteChange
    );
  }

  showNotification(
    header: string,
    type: NotificationType,
    message: string,
    keepAfterRouteChange = false
  ): any {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type,
      message,
      header,
    } as unknown as Notification);
  }
}
