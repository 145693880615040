import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/services';
import {
  ExportFormat,
  InvoiceStatus,
  OldInvoiceStatus,
  Modules,
  NotificationTextMessage,
} from '@app/core/enums';
import { GlobalComponent, MainListParameters } from '@app/core/models/common';
import {
  CollectPaymentForAnInvoice,
  CollectPaymentForSmeInvoice,
  DownloadCapiumInvoiceListByPDF,
  DownloadInvoiceListByPDF,
  DownloadSmeInvoiceListByPDF,
  GetInvoiceList,
  GetSmeInvoiceList,
  InvoiceState,
} from '@app/core/store';
import { InvoicePreviewComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent {
  displayedColumns: string[] = [
    'invoice',
    'status',
    'dueOn',
    'paid',
    'total',
    'action',
  ];
  listParameters: MainListParameters = new MainListParameters();
  @ViewChild(MatSort) sort: MatSort;

  invoiceList: any;
  masterInvoiceList: any;
  ids: Array<string>;

  totalSelectedRecords = 0;
  statusValue = '0';
  invValue = '1';
  isSME: boolean;

  exportType = ExportFormat;
  statusList = InvoiceStatus;
  oldInvoiceList = OldInvoiceStatus;
  notificationTextMessage = NotificationTextMessage;

  moduleId: Modules.Invoice;

  @Select(InvoiceState.totalRecord)
  totalRecord$: Observable<number>;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    public globalComponent: GlobalComponent,
    private datePipe: DatePipe,
    private router: Router,
    public storage: LocalStorageService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');
    if (!this.isSME) {
      this.getList();
    } else {
      this.getSmeList();
    }
  }

  onDropdownChange(event: MatSelectChange): void {
    if (this.statusValue !== '0') {
      this.invoiceList = this.masterInvoiceList.filter(
        (item: any) => item.status === this.statusValue
      );
    } else {
      this.invoiceList = this.masterInvoiceList;
    }
    this.invoiceList = new MatTableDataSource(this.invoiceList);
    this.invoiceList.sort = this.sort;
  }
  onDropdownInvChange(event: MatSelectChange): void {
    this.listParameters.isNew = event.value;
    this.invValue = this.listParameters.isNew;
    if (!this.isSME) {
      this.getList();
    }
  }
  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    if (!this.isSME) {
      this.getList();
    } else {
      this.getSmeList();
    }
  }

  getParameters(): any {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    const userGuid1: Guid | null = this.globalComponent.externalUserGUID();
    const userGuidAsString: string | null = userGuid1
      ? userGuid1.toString()
      : null;
    const queryParams = {
      limit: this.listParameters.pageSize,
      offset: this.listParameters.pageNumber.toString(),
      status: +this.statusValue,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      customerId: this.globalComponent.customerId(),
      userGuid: userGuidAsString,
      isNew: this.listParameters.isNew === '1' ? true : false,
      email: decoded.email,
    };

    return queryParams;
  }
  getInvoiceStatusString(status: number): string {
    switch (+status) {
      case OldInvoiceStatus.Pending:
        return 'Pending';
      case OldInvoiceStatus.Processing:
        return 'Processing';
      case OldInvoiceStatus.Paid:
        return 'Paid';
      case OldInvoiceStatus.Cancelled:
        return 'Cancelled';
      case OldInvoiceStatus.Failed:
        return 'Failed';
      case OldInvoiceStatus.CarriedForward:
        return 'Carried Forward';
      case OldInvoiceStatus.Gocardlesscancel:
        return 'GoCardless Cancel';
      default:
        return 'Unknown';
    }
  }
  getList(): void {
    this.store
      .dispatch(new GetInvoiceList(this.getParameters()))
      .subscribe((res) => {
        let list: any = [];

        //This need to be done becoz of filtering data
        res.invoice.invoiceList.forEach((element) => {
          list.push({
            invoice: element.id,
            status:
              this.invValue === '1'
                ? element.status
                : this.getInvoiceStatusString(element.status),
            dueOn: this.datePipe.transform(element.dueOn),
            paid: this.datePipe.transform(element.paidOn),
            total: element.total,
            isSelected: false,
            invoiceAllList: element,
            userId: element.userId,
            invoiceId: element.invoiceId,
          });
        });
        this.masterInvoiceList = list;
        this.invoiceList = new MatTableDataSource(list);
        this.invoiceList.sort = this.sort;
      });
  }
  getSmeList(): void {
    this.store
      .dispatch(new GetSmeInvoiceList(this.getParameters()))
      .subscribe((res) => {
        let list: any = [];

        //This need to be done becoz of filtering data
        res.invoice.invoiceList.forEach((element) => {
          list.push({
            invoice: element.id,
            status: element.status,
            dueOn: this.datePipe.transform(element.dueOn),
            paid: this.datePipe.transform(element.paidOn),
            total: element.total,
            isSelected: false,
            invoiceAllList: element,
          });
        });
        this.masterInvoiceList = list;
        this.invoiceList = new MatTableDataSource(list);
        this.invoiceList.sort = this.sort;
      });
  }

  onSearch(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.invoiceList.filter = filterValue.trim().toLowerCase();
  }

  exportToPDF(invoiceId: string, isPrint: boolean): void {
    if (!this.isSME) {
      this.store
        .dispatch(new DownloadInvoiceListByPDF(invoiceId))
        .subscribe((res) => {
          window.open(res.invoice.invoiceDownloadUrl, '_self');
        });
    } else {
      this.store
        .dispatch(new DownloadSmeInvoiceListByPDF(invoiceId))
        .subscribe((res) => {
          window.open(res.invoice.invoiceDownloadUrl, '_self');
        });
    }
  }
  exportCapiumInvToPDF(invData: any, isPrint: boolean): void {
    this.store
      .dispatch(
        new DownloadCapiumInvoiceListByPDF(invData.invoiceId, invData.userId)
      )
      .subscribe((res) => {
        console.log(res);
        if (res.invoice.CapiumInvStatus) {
          let binaryString = window.atob(res.invoice.CapiumInvBytes);
          let binaryLen = binaryString.length;
          let bytes = new Uint8Array(binaryLen);
          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          let blob = new Blob([bytes], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          let fileName = res.invoice.CapiumInvName;
          link.download = fileName;
          link.click();
        }
      });
  }

  preview(element: any): void {
    this.dialog.open(InvoicePreviewComponent, {
      panelClass: 'custom-dialog-preview-container',
      data: {
        invoiceList: element.invoiceAllList,
      },
    });
  }

  payNow(): void {
    if (!this.isSME) {
      this.store
        .dispatch(
          new CollectPaymentForAnInvoice(
            window.location.origin.toString() + this.router.url
          )
        )
        .subscribe((res) => {
          window.open(res.invoice.payNowUrl, '_blank');
        });
    } else {
      this.store
        .dispatch(
          new CollectPaymentForSmeInvoice(
            window.location.origin.toString() + this.router.url
          )
        )
        .subscribe((res) => {
          window.open(res.invoice.payNowUrl, '_blank');
        });
    }
  }

  selectedIds(): void {
    this.ids = [];

    this.invoiceList.data.forEach((x) =>
      x.isSelected ? this.ids.push(x.invoice ?? '') : ''
    );
  }
}
