<div
  class="alert alert-{{ cssClass(item) }}"
  *ngFor="let item of notifications"
>
  <div class="d-flex align-items-center">
    <mat-icon class="close-icon text-{{ cssClass(item) }}-100" (click)="close()"
      >close</mat-icon
    >
    <div>
      <mat-icon class="check-icon text-{{ cssClass(item) }}-100">{{
        icon
      }}</mat-icon>
    </div>
    <div>
      <p
        class="font-size-18 text-{{
          cssClass(item)
        }}-100 fw-bold text-align-left"
      >
        {{ item.header }}
      </p>
      <p class="text-black text-align-left">{{ item.message }}</p>
    </div>
  </div>
</div>
