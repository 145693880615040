import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
  SubscribtionsType
} from '@app/core/enums';
import { LocalStorageService } from '@app/core/services';
import { RetrieveHostedPage, RetrieveSmeHostedPage } from '@app/core/store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-successfull',
  templateUrl: './payment-successfull.component.html',
  styleUrls: ['./payment-successfull.component.scss'],
})
export class PaymentSuccessfullComponent implements OnInit {
  paymentMethodId: string;
  subscriptionId: string;
  isPaymentSuccessful: boolean = false;
  headerText: string;
  detailText: string;
  isPaymentShow: boolean = false;
  isTrailPeriod = false;
  isSubscriptionCancelled = false;
  isSME: boolean;

  @Input()
  subscribtionsType: number;
  
  constructor(
    private spinner: NgxSpinnerService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    public storage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.paymentMethodId = this.activatedRoute.snapshot.queryParams['id'];
    this.isSME = this.storage.get('isSME');
   
    if (!this.isSME) {
      this.getRetrieveHostedPage();
    } else {
      this.getRetrieveSmeHostedPage();
    }
  }

  getRetrieveHostedPage(): void {
    this.subscribtionsType=this.storage.get('subscribtionsType');    
    this.isTrailPeriod = this.storage.get('isTrailPeriod');
    this.subscriptionId = this.storage.get('subscriptionId');
    this.isSubscriptionCancelled = this.storage.get('isSubscriptionCancelled');

    let isTrial = this.isTrailPeriod;
    if (this.isSubscriptionCancelled) {
      isTrial = false;
    }
    let IsCancelTrial=(this.subscribtionsType!==SubscribtionsType.Capium365 && this.subscribtionsType!==SubscribtionsType.CapiumAddons )?true:false;

    this.store
      .dispatch(
        new RetrieveHostedPage(
          this.paymentMethodId,
          Object.keys(this.subscriptionId).length === 0
            ? ''
            : this.subscriptionId,
          Object.keys(this.subscriptionId).length === 0 ? false : isTrial,
          IsCancelTrial
        )
      )
      .subscribe((res) => {
        this.storage.removeItem('cartDetails');
        this.isPaymentSuccessful = res.paymentMethod.isPaymentSuccessful;
        this.isPaymentShow = true;
        this.setText();
      });
  }

  getRetrieveSmeHostedPage(): void {
    this.isTrailPeriod = this.storage.get('isTrailPeriod');
    this.subscriptionId = this.storage.get('subscriptionId');
    this.isSubscriptionCancelled = this.storage.get('isSubscriptionCancelled');

    let isTrial = this.isTrailPeriod;
    if (this.isSubscriptionCancelled) {
      isTrial = false;
    }

    this.store
      .dispatch(
        new RetrieveSmeHostedPage(
          this.paymentMethodId,
          Object.keys(this.subscriptionId).length === 0
            ? ''
            : this.subscriptionId,
          Object.keys(this.subscriptionId).length === 0 ? false : isTrial
        )
      )
      .subscribe((res) => {
        this.storage.removeItem('cartDetails');
        this.isPaymentSuccessful = res.paymentMethod.isPaymentSuccessful;
        this.isPaymentShow = true;
        this.setText();
      });
  }

  setText(): void {
    this.headerText = this.isPaymentSuccessful
      ? NotificationHeader.success
      : NotificationHeader.failure;

    this.detailText = this.isPaymentSuccessful
      ? NotificationTextMessage.paymentSuccessful
      : NotificationTextMessage.paymentFailed;

    window.setTimeout(() => {
      this.router.navigateByUrl(RoutingPath.Subscription);
    }, 5000);
  }
}
