export enum RoutingPath {
  Products = '/home/products',
  Subscription = '/home/subscriptions',
  BillingHistory = '/home/billingistory',
  PaymentMethod = '/home/paymentmethods',
  Invoices = '/home/invoices',
  Integrations = '/home/integrations',
  Cart = '/home/products-cart',
  BuyNow = '/home/products/buy-now',
  letsConnect = '/home/lets-connect',
}
