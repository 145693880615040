import { Injectable } from '@angular/core';
import { ProductTabDetails } from '@app/core/models';
import { ProductService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { tap } from 'rxjs';
import {
  CancelSubscriptions,
  CheckProductDisable,
  CheckValidCoupon,
  GetConnectLink,
  GetProductDetailAddonsData1,
  GetCapium365ProductDetailAddonsByData,
  GetProductList,
  GetAddonListByPlanId,
  GetIndividualProductList,
  GetProductTabDetails,
  PayNowForSubscribtion,
  SendEmailForBookADemo,
  SetProductDetailAcrdngPlan,
  UpgradePlanForSubscribtion,
} from './product.action';
import { SubscriptionsEnum } from '@app/core/enums';

export class ProductStateInfo {
  id?: number;
  productDetailData?: Array<any>;
  productDetailIndividualData?: any;
  productDetailAddonsData?: any;
  Capium365productDetailAddonsData?: any;
  AddonListByPlanIdData?: any;
  payNowResposeURL?: any;
  couponAmount?: any;
  productList?: ProductTabDetails;
  productDetailAccordingPlan?: Array<any>;
  isValidCoupon?: boolean;
  isProductDisabled?: boolean;
  isPlanUpgraded?: any;
  isEmailSendForBookDemo?: boolean;
  isCancelSuccessfully?: boolean;
  connectLinkList?: any;
  profilePic?: any;
  ecoSpaceLogo?: any;
  favIcon?: any;
}

@State<ProductStateInfo>({
  name: 'product',
  defaults: {
    id: 0,
    productDetailData: [],
    isEmailSendForBookDemo: false,
  },
})
@Injectable()
export class ProductState {
  constructor(private productService: ProductService) {}

  @Selector()
  static productDetailData(state: ProductStateInfo): Array<any> {
    return state.productDetailData!;
  }

  @Selector()
  static productDetailIndividualData(state: ProductStateInfo): any {
    return state.productDetailIndividualData;
  }

  

  @Selector()
  static AddonListByPlanIdData(state: ProductStateInfo): any {
    return state.AddonListByPlanIdData;
  }


  @Selector()
  static connectLinkList(state: ProductStateInfo): any {
    return state.connectLinkList;
  }

  @Selector()
  static productDetailAddonsData(state: ProductStateInfo): any {
    return state.productDetailAddonsData;
  }

  @Selector()
  static productDetailAccordingPlan(state: ProductStateInfo): any {
    return state.productDetailAccordingPlan!;
  }

  @Action(GetProductTabDetails)
  getProductTabDetails({ patchState }: StateContext<ProductStateInfo>) {
    return this.productService.getProductTabDetails().pipe(
      tap((res) => {
        patchState({
          productList: res.valueResult,
        });
      })
    );
  }

  @Action(GetProductDetailAddonsData1)
  getProductDetailAddonsData1(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetProductDetailAddonsData1
  ) {
    return this.productService
      .getProductDetailAddonsData1(
        action.id,
        action.userName,
        action.externalUserGUID
      )
      .pipe(
        tap((res) => {
          patchState({
            productDetailAddonsData: res.valueResult,
          });
        })
      );
  }

  @Action(GetCapium365ProductDetailAddonsByData)
  getCapium365ProductDetailAddonsData(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetCapium365ProductDetailAddonsByData
  ) {
    return this.productService
      .getCapium365ProductDetailAddonsData(
        action.itemFamilyId      
      )
      .pipe(
        tap((res) => {
          patchState({
            Capium365productDetailAddonsData: res.valueResult,
          });
        })
      );
  }

  @Action(CancelSubscriptions)
  CancelSubscriptions(
    { patchState }: StateContext<ProductStateInfo>,
    action: CancelSubscriptions
  ) {
    return this.productService.cancelSubscriptions(action.subscriptionId).pipe(
      tap((res) => {
        patchState({
          isCancelSuccessfully: res.valueResult,
        });
      })
    );
  }

  @Action(SetProductDetailAcrdngPlan)
  setAddOnList(
    { patchState }: StateContext<ProductStateInfo>,
    action: SetProductDetailAcrdngPlan
  ) {
    patchState({
      productDetailAccordingPlan: action.productDetailAccordingPlan,
    });
  }

  @Action(PayNowForSubscribtion)
  payNowForSubscribtion(
    { patchState }: StateContext<ProductStateInfo>,
    action: PayNowForSubscribtion
  ) {
    return this.productService.payNowForSubscribtion(action.payNowData).pipe(
      tap((res) => {
        patchState({
          payNowResposeURL: JSON.parse(res.valueResult.cnt).hosted_page.url,
        });
      })
    );
  }

  @Action(CheckValidCoupon)
  checkValidCoupon(
    { patchState }: StateContext<ProductStateInfo>,
    action: CheckValidCoupon
  ) {
    return this.productService.checkValidCoupon(action.couponCode).pipe(
      tap((res) => {
        patchState({
          isValidCoupon: res.valueResult,
        });
      })
    );
  }

  @Action(CheckProductDisable)
  checkProductDisable(
    { patchState }: StateContext<ProductStateInfo>,
    action: CheckProductDisable
  ) {
    return this.productService.checkProductDisable(action.username).pipe(
      tap((res) => {
        patchState({
          isProductDisabled: res.valueResult.isBlocked,
        });
      })
    );
  }

  @Action(GetProductList)
  getProductList(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetProductList
  ) {
    return this.productService.getProductList(action.id, action.frequency).pipe(
      tap((res) => {
        let fullsuiteData: any = [];
        // let individualData: any = [];

        if (res.valueResult.length > 0) {
          res.valueResult.forEach((element, index) => {
            if(element.plan.itemId == SubscriptionsEnum.fullSuiteSmall
              ||element.plan.itemId == SubscriptionsEnum.fullSuiteMedium
              ||element.plan.itemId == SubscriptionsEnum.fullSuiteLarge){
              fullsuiteData.push(element);            
            }
            fullsuiteData.sort((a, b) => a.plan.itemId - b.plan.itemId);
            
          });
          // res.valueResult.forEach(element => {
          //   if(element.plan.itemId == SubscriptionsEnum.individual){
          //     individualData = element;
          //   }            
          // });         
        }

        patchState({
          productDetailData: fullsuiteData,
          // productDetailIndividualData: individualData,
        });
      })
    );
  }

  @Action(GetIndividualProductList)
  getIndividualProductList(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetProductList
  ) {
    return this.productService.getIndividualProductList(action.id, action.frequency).pipe(
      tap((res) => {        
        let individualData: any = [];

        if (res.valueResult.length > 0) {
         
          res.valueResult.forEach(element => {
            if(element.plan.itemId == SubscriptionsEnum.individual){
              individualData = element;
            }            
          });         
        }
        patchState({       
          productDetailIndividualData: individualData,
        });
      })
    );
  }

  @Action(UpgradePlanForSubscribtion)
  upgradePlanForSubscribtion(
    { patchState }: StateContext<ProductStateInfo>,
    action: UpgradePlanForSubscribtion
  ) {
    return this.productService.upgradePlanForSubscribtion(action.param).pipe(
      tap((res) => {
        patchState({
          payNowResposeURL: JSON.parse(res.valueResult.cnt).hosted_page.url,
        });
      })
    );
  }

  @Action(SendEmailForBookADemo)
  sendEmailForBookDemo(
    { patchState }: StateContext<ProductStateInfo>,
    action: SendEmailForBookADemo
  ) {
    return this.productService
      .sendEmailForBookDemo(action.sendEmailForBookDemo)
      .pipe(
        tap((res) => {
          patchState({
            isEmailSendForBookDemo: res.valueResult.status,
          });
        })
      );
  }

  @Action(GetConnectLink)
  getConnectLink(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetConnectLink
  ) {
    return this.productService.getConnectLink(action.username).pipe(
      tap((res) => {
        patchState({
          connectLinkList: res.valueResult.listofAddons,
          profilePic: res.valueResult.picture,
          ecoSpaceLogo: res.valueResult.ecoSpaceLogo,
          favIcon: res.valueResult.favIcon,
        });
      })
    );
  }

  @Action(GetAddonListByPlanId)
  getAddonListByPlanId(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetAddonListByPlanId
  ) {
    return this.productService.getAddonListByPlanId(action.id,action.frequency).pipe(
      tap((res) => {
        patchState({
          AddonListByPlanIdData: res.valueResult        
        });
      })
    );
  }
}
