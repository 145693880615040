import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalComponent {
  totalCartItem: Subject<number>;

  constructor(private cookieService: CookieService) {
    this.totalCartItem = new Subject<number>();
  }

  public themecolor(): string {
    const themecolor = this.cookieService.get('themecolor');
    return themecolor;
  }

  public customerId(): string {
    const customerId = this.cookieService.get('customerId');
    return customerId;
  }

  public userId(): Guid {
    const userId = this.cookieService.get('userId');
    return userId as unknown as Guid;
  }

  public externalUserGUID(): Guid {
    const externalUserGUID = this.cookieService.get('externalUserGUID');
    return externalUserGUID as unknown as Guid;
  }
}
