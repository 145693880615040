import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalComponent } from '../models';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  service_count = 0;


  constructor(
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    @Inject(DOCUMENT) private document: Document
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.service_count++;

    return next.handle(request).pipe(
      finalize(() => {
        this.service_count--;
        if (
          this.service_count === 0 //&&
          // this.globalComponent.userId() !== (Guid.EMPTY as unknown as Guid)
        ) {
          let apiUrl:string=request.url;  
          let uiUrl:string=this.document.location.href
          
          if(apiUrl.includes('RetrieveCapiumAddonsSubscriptionByCustomerIdAsync'))  {
           if(!uiUrl.includes('products')){
            this.spinner.hide();
           }
          }else
          {
            this.spinner.hide();
          }           
        }
      })
    );
  }
}
