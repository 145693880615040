export class PayNowModel {
  customerId?: string;
  planPriceId?: string;
  redirectionUrl: string;
  addonPriceId?: any;
  subscribtionsType: number;
  cancelUrl?: string;
  subscriptionId?: string;
  cf_employees?: number;
  cf_users?: number;
  cf_clients?: number;
  cf_charityclients?: number;
  cf_timenfeeclients?: number;
  cf_bookkeepingclients?:number;
  cf_corporationtaxclients?:number;
  cf_samtdclients?:number;
  frequency?: number;
}
