import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CardName,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { ConfirmationBoxComponent } from '@app/core/sharedmodules';
import { GetPaymentList, GetSmePaymentList } from '@app/core/store';
import { LocalStorageService } from '@app/core/services';
import {
  PaymentBankFormComponent,
  PaymentCardFormComponent,
} from '@app/modules';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-payment-card-details',
  templateUrl: './payment-card-details.component.html',
  styleUrls: ['./payment-card-details.component.scss'],
})
export class PaymentCardDetailsComponent implements OnInit {
  paymentMethodList: any[];
  bankList: any[];
  isSME: boolean;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  addPaymentCard = false;
  addBankDetails = false;

  cardNameEnum = CardName;
  environment = environment;

  @ViewChild(PaymentCardFormComponent, { static: false })
  paymentCard;

  @ViewChild(PaymentBankFormComponent, { static: false })
  paymentBank;

  constructor(
    private dialog: MatDialog,
    private store: Store,
    public storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');
    if (!this.isSME) {
      this.getList();
    } else {
      this.getSmeList();
    }
  }

  onAddCardClick(): void {
    this.addPaymentCard = true;
  }

  togglePaymentCardDetails(): void {
    this.addPaymentCard = false;
    if (!this.isSME) {
      this.getList();
    } else {
      this.getSmeList();
    }
  }

  togglePaymentBankDetails(): void {
    this.addBankDetails = false;
    if (!this.isSME) {
      this.getList();
    } else {
      this.getSmeList();
    }
  }

  onEditCardClick(item: any, showPaymentForm: boolean): void {
    this.addPaymentCard = showPaymentForm;

    setTimeout(() => {
      this.paymentCard.paymentCardForm.controls.cvvNumber.clearValidators();
      this.paymentCard.paymentCardForm.controls.cvvNumber.updateValueAndValidity();

      this.paymentCard.paymentCardId = item.id;
      this.paymentCard.paymentCardForm.patchValue({
        firstName: item.card.firstName,
        lastName: item.card.lastName,
        cardNumber: item.card.maskedNumber,
        cardPeriod: item.card.expiryMonth + '/' + item.card.expiryYear,
        setAsPrimary: item.card.isPrimary,
        cvvNumber: '*'.repeat(3),
      });

      this.paymentCard.paymentCardForm.controls.cardNumber.disable();
      this.paymentCard.paymentCardForm.controls.cvvNumber.disable();

      this.paymentCard.showSetAsPrimary = false;
    }, 500);
  }

  getList(): void {
    this.store.dispatch(new GetPaymentList()).subscribe((res) => {
      this.paymentMethodList = res.paymentMethod.cardData;
      this.bankList = res.paymentMethod.bankData;
    });
  }
  q;

  getSmeList(): void {
    this.store.dispatch(new GetSmePaymentList()).subscribe((res) => {
      this.paymentMethodList = res.paymentMethod.cardData;
      this.bankList = res.paymentMethod.bankData;
    });
  }

  onDeleteCardClick(item: any): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          type: ConfirmationType.Delete,
          ids: item.id,
          moduleId: Modules.PaymentMethods,
          headerText: NotificationHeader.deleteConfirmation,
          detailText: NotificationDetails.deletePaymentCardDetailText,
          successMessage: NotificationTextMessage.cardDeletedSuccessfully,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (!this.isSME) {
            this.getList();
          } else {
            this.getSmeList();
          }
        }
      });
  }

  onAddBankClick(): void {
    this.addBankDetails = true;
  }

  onEditBankClick(item: any, showBankForm: boolean): void {
    this.addBankDetails = showBankForm;

    setTimeout(() => {
      this.paymentBank.paymentBankId = item.id;

      this.paymentBank.bankForm.patchValue({
        firstName: item.bankAccount.firstName,
        lastName: item.bankAccount.lastName,
        isCompany: item.bankAccount.maskedNumber,
        companyName: item.bankAccount.maskedNumber,
        email: item.bankAccount.email,
      });
    }, 500);
  }

  onDeleteBankClick(item: any): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          type: ConfirmationType.Delete,
          ids: item.id,
          moduleId: Modules.PaymentMethods,
          headerText: NotificationHeader.deleteConfirmation,
          detailText: NotificationDetails.deletePaymentBankDetailText,
          successMessage: NotificationTextMessage.bankDeletedSuccessfully,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (!this.isSME) {
            this.getList();
          } else {
            this.getSmeList();
          }
        }
      });
  }
}
