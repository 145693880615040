import { PayNowModel, SendEmailForBookDemoModel } from '@app/core/models';

export class GetProductTabDetails {
  static readonly type = '[PRODUCT] Get Retrive Product';
}

export class GetProductList {
  static readonly type = '[PRODUCT] Get Retrive item List';
  constructor(public id: string, public frequency: number) {}
}

export class GetIndividualProductList {
  static readonly type = '[PRODUCT] Get Retrive individual item List';
  constructor(public id: string, public frequency: number) {}
}

export class GetAddonListByPlanId {
  static readonly type = '[PRODUCT] Get Addon List By Plan Id';
  constructor(public id: string, public frequency: number) {}
}

export class SetProductDetailAcrdngPlan {
  static readonly type = '[PRODUCT] Set Product Detail Accorrding To Plan';
  constructor(public productDetailAccordingPlan?: Array<any>) {}
}

export class PayNowForSubscribtion {
  static readonly type = '[PRODUCT] Pay Now For Subscribtion';
  constructor(public payNowData: PayNowModel) {}
}

export class CheckValidCoupon {
  static readonly type = '[PRODUCT] Check Valid Coupon';
  constructor(public couponCode: string) {}
}

export class UpgradePlanForSubscribtion {
  static readonly type = '[PRODUCT] Upgrade Full Suite Plan';
  constructor(public param: any) {}
}

export class SendEmailForBookADemo {
  static readonly type = '[PRODUCT] Send Email For Book A Demo';
  constructor(public sendEmailForBookDemo: SendEmailForBookDemoModel) {}
}

export class GetProductDetailAddonsData1 {
  static readonly type = '[PRODUCT] Get Retrive item List for Addons';
  constructor(
    public id: string,
    public userName: string,
    public externalUserGUID: string
  ) {}
}
export class GetCapium365ProductDetailAddonsByData {
  static readonly type = '[PRODUCT] Get Retrive item List for Capium 365 Addons';
  constructor(public itemFamilyId: string) {}
}

export class CancelSubscriptions {
  static readonly type = '[PRODUCT] Cancel Subscriptions';
  constructor(public subscriptionId: string) {}
}

export class GetConnectLink {
  static readonly type = '[PRODUCT] Get Connect Link';
  constructor(public username: string) {}
}

export class CheckProductDisable {
  static readonly type = '[PRODUCT] Check Product Disable';
  constructor(public username: string) {}
}
