import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent, PayNowModel } from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SmeProductService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    private globalComponent: GlobalComponent
  ) {}

  GetSmeProductTabDetails(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}SmeProducts/RetrieveSmeItemFamilies`
    );
  }

  GetSmeProductList(id: string, frequency: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}SmeProducts/RetrieveSmeItemsList?itemFamilyId=${id}&frequency=${frequency}`
    );
  }

  PayNowForSmeSubscription(payNowData: PayNowModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/CreateCheckoutForSmeSubscription`,
      JSON.stringify(payNowData),
      headers
    );
  }

  UpgradePlanForSmeSubscription(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SmeProducts/CreateCheckoutForUpdateSmeSubscription`,
      JSON.stringify(param),
      headers
    );
  }
 
  GetSmeSubscriptionPortalDetails(): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }SmeProducts/RetrieveSmeSubscriptionByCustomerIdAsync?customerId=${this.globalComponent.customerId()}`
    );
  }
}
