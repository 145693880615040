import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscribtionsType } from '@app/core/enums';
import {
  GlobalComponent,
  PayNowModel,
  SendEmailForBookDemoModel,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    private globalComponent: GlobalComponent
  ) {}

  getProductTabDetails(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Products/RetrieveItemFamilies`
    );
  }

  getProductList(id: string, frequency: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Products/RetrieveItemsList?itemFamilyId=${id}&frequency=${frequency}`
    );
  }

  getIndividualProductList(id: string, frequency: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Products/RetrieveIndividualItemsList?itemFamilyId=${id}&frequency=${frequency}`
    );
  }

  getAddonListByPlanId(id: string, frequency: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Products/RetrieveItemsListByPlanId?planId=${id}&frequency=${frequency}`
    );
  }


  getProductDetailAddonsData1(
    id: string,
    userName: string,
    externalUserGUID: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CapiumAddons/RetrieveCapiumAddonsListofAll?itemFamilyId=${id}&userName=${userName}&externalUserGUID=${externalUserGUID}`
    );
  }

  getCapium365ProductDetailAddonsData(
    itemFamilyId: string  
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Capium/RetrieveCapium365List?itemFamilyId=${itemFamilyId}`
    );
  }


  cancelSubscriptions(subscriptionId: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Subscriptions/CancelSubscriptionAsync?subscriptionId=${subscriptionId}`,
      headers
    );
  }

  checkValidCoupon(couponCode: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Products/RetrieveCoupon?couponId=${couponCode}`
    );
  }

  checkProductDisable(username: string): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }Customer/GetCustomerAccess?userName=${username}&chargebeeCustomerId=${this.globalComponent.customerId()}&externalUserGUID=${this.globalComponent.externalUserGUID()}`
    );
  }

  payNowForSubscribtion(payNowData: PayNowModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getPayNowSericeName(
        payNowData.subscribtionsType
      )}`,
      JSON.stringify(payNowData),
      headers
    );
  }

  getUpgradeServiceName(subscribtionsType: number): string {
    let serviceName;
    switch (subscribtionsType) {
      case SubscribtionsType.FullSuite:
        serviceName = 'Products/CreateCheckoutForUpdateSubscription';
        break;
      case SubscribtionsType.Indidvidual:
        serviceName = 'Products/CreateCheckoutForUpdateIndividualSubscription';
        break;
      case SubscribtionsType.CapiumAddons:
      case SubscribtionsType.Capium365:
        serviceName =
          'CapiumAddons/CreateCheckoutForUpdateCapiumAddonsSubscription';
        break;
    }

    return serviceName;
  }

  getConnectLink(username: string): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }Subscriptions/RetrieveSubscriptionForIdentityByCustomerIdAsync?username=${username}
 &externalUserGUID=${this.globalComponent.externalUserGUID()}`
    );
  }

  getPayNowSericeName(subscribtionsType: number): string {
    let serviceName;
    switch (subscribtionsType) {
      case SubscribtionsType.FullSuite:
        serviceName = 'Products/CreateCheckoutForSubscription';
        break;
      case SubscribtionsType.Indidvidual:
        serviceName = 'Products/CreateCheckoutForIndividualSubscription';
        break;
      case SubscribtionsType.CapiumAddons:
      case SubscribtionsType.Capium365:
        serviceName = 'CapiumAddons/CreateCheckoutForCapiumAddonsSubscription';
        break;
    }
    return serviceName;
  }

  upgradePlanForSubscribtion(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getUpgradeServiceName(
        param.subscribtionsType
      )}`,
      JSON.stringify(param),
      headers
    );
  }

  sendEmailForBookDemo(
    sendEmailForBookDemo: SendEmailForBookDemoModel
  ): Observable<any> {
    const formData = new FormData();
    formData.append('name', sendEmailForBookDemo.name);
    formData.append('emailId', sendEmailForBookDemo.emailId);
    formData.append('phoneNumber', sendEmailForBookDemo.phoneNumber);
    formData.append('clientName', sendEmailForBookDemo.clientName);
    formData.append('supportTopic', sendEmailForBookDemo.supportTopic);
    formData.append('modules', sendEmailForBookDemo.modules);
    formData.append('subject', sendEmailForBookDemo.subject);
    formData.append('message', sendEmailForBookDemo.message);
    formData.append(
      'externalUserGuid',
      sendEmailForBookDemo.externalUserGuid.toString()
    );
    for (let file of sendEmailForBookDemo.file) {
      formData.append('files', file);
    }

    return this.http.post<any>(
      `${environment.commonapiVersionUrl}FreshDeskTicket/LetsConnectRequestAsync`,
      formData
    );
  }
}
