import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthModule,
  LogLevel,
  OidcConfigService,
  LoggerService,
} from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomHttpInterceptor, LoaderInterceptor } from './core/interceptors';
import { CommonService, DummyService } from './core/services';
import { AuthResolverService } from './core/guards/auth.resolver';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HomeModule } from './modules/home/home.module';
import { NgxsModule } from '@ngxs/store';
import { AngularMaterialModule } from './core/sharedmodules';
import { GlobalErrorHandler } from './core/interceptors/global-error-handler ';
export function configureAuth(oidcConfigService: OidcConfigService): any {
  return () =>
    oidcConfigService.withConfig({
      stsServer: `${environment.stsServerUrl}`,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: `${environment.identityLoginUrl}`,
      clientId: `${environment.clientId}`,
      scope: 'capium_myadmin_api openid',
      responseType: 'code',
      silentRenew: false,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      logLevel: LogLevel.Debug,
      historyCleanupOff: true,
      autoCleanStateAfterAuthentication: true,
      postLoginRoute: '/home/products',
      unauthorizedRoute: `${environment.identityLoginUrl}`,
      autoUserinfo : false
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
    NgxsModule.forRoot([]),
  ],
  providers: [
    CommonService,
    OidcConfigService,
    LoggerService,
    DummyService,
    AuthResolverService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
