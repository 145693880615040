import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { DummyService } from '../services';

@Injectable()
export class AuthResolverService implements Resolve<any> {
  constructor(
    private dummyService: DummyService,
    private cookieService: CookieService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    return this.dummyService
      .getLoggedInUser()
      .then((value) => {
        if (!value) {
          this.cookieService.deleteAll();
          this.oidcSecurityService.logoff();
        }
        return value ? true : false;
      })
      .catch((error) => {
        this.cookieService.deleteAll();
        this.oidcSecurityService.logoff();
      });
  }
}
