import { ExportFormat } from '@app/core/enums';

export class QueryParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: number;
  sortBy?: string;
  sortOrder?: boolean;
  search?: string;
  startDate?: string;
  endDate?: string;
  moduleId?: number;
}

export class ExportParams extends QueryParams {
  format?: ExportFormat;
  fileName?: string;
  ids?: Array<any>;
  isPrint? = false;
}

export class MainListParameters {
  pageNumber = '';
  pageSize = 20;
  sortBy? = '';
  sortOrder? = true;
  search? = '';
  ids?: Array<any>;
  startDate?: string;
  endDate?: string;
  format?: ExportFormat;
  isPrint?: boolean;
  isRowHighlighted?: false;
  filter = 0;
  sort: any;
  isNew = '1';
}
