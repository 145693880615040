import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HeaderComponent } from './header/header.component';
import { NgxsModule } from '@ngxs/store';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  AngularMaterialModule,
  ComponentsModule,
} from '@app/core/sharedmodules';
import {
  CommonState,
  BillingHistoryState,
  IntegrationsState,
  InvoiceState,
  PaymentMethodState,
  ProductState,
  SmeProductState,
  SubscriptionPortalState,
} from '@app/core/store';

import {
  BillingHistoryService,
  CommonService,
  IntegrationsService,
  InvoiceService,
  LocalStorageService,
  PaymentMethodService,
  ProductService,
  SmeProductService,
  SubscriptionPortalService,
} from '@app/core/services';

import {
  ContactFormComponent,
  DailogContactFormComponent,
  PaymentSuccessfullComponent,
  ProductAddonsComponent,
  ProductCartComponent,
  ProductFullSuiteComponent,
  ProductIndividualComponent,
  ProductReviewOrderComponent,
  ProductsComponent,
  ProductSmeComponent,
} from '..';
import { ShortNamePipe } from '@app/core/pipes';
import { ProductCapium365Component } from './products/product-capium365/product-capium365/product-capium365.component';

@NgModule({
  declarations: [
    ShortNamePipe,
    HomeComponent,
    ProductsComponent,
    HeaderComponent,
    ProductCartComponent,
    ProductReviewOrderComponent,
    ProductFullSuiteComponent,
    ContactFormComponent,
    PaymentSuccessfullComponent,
    ProductIndividualComponent,
    ProductAddonsComponent,
    DailogContactFormComponent,
    ProductSmeComponent,
    ProductCapium365Component,
  ],
  providers: [
    CommonService,
    ProductService,
    BillingHistoryService,
    InvoiceService,
    PaymentMethodService,
    IntegrationsService,
    SubscriptionPortalService,
    LocalStorageService,
    SmeProductService,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    AngularMaterialModule,
    ComponentsModule,
    NgxsModule.forFeature([
      CommonState,
      SubscriptionPortalState,
      ProductState,
      BillingHistoryState,
      InvoiceState,
      PaymentMethodState,
      IntegrationsState,
      SmeProductState,
    ]),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
  ],
})
export class HomeModule {}
