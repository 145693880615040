export enum BilingHistoryStatus {
  InProgress = 1,
  Success = 2,
  Failure = 4,
}

export enum BilingHistoryPaymentMethod {
  Card = 1,
  Cash = 2,
  'Direct Debit' = 8,
}
