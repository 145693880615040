<div>
  <h2 mat-dialog-title>{{ confirmationList?.headerText }}</h2>
  <mat-dialog-content>
    <p
      class="font-size-14 text-align-center mb-1"
      [innerHTML]="confirmationList?.detailText"
    ></p>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
      >
        Yes
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        (click)="onNoClick()"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>
