import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-address-details',
  templateUrl: './payment-address-details.component.html',
  styleUrls: ['./payment-address-details.component.scss'],
})
export class PaymentAddressDetailsComponent {
  isShowAddressForm = false;

  onAddClick(): void {
    this.isShowAddressForm = true;
  }
}
