import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GlobalComponent } from '@app/core/models';
import { CommonService, LocalStorageService } from '@app/core/services';
import * as theme from 'src/assets/json/themes.json';
import { Store } from '@ngxs/store';
import { GetConnectLink, UpdateThemeColor } from '@app/core/store';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { RoutingPath } from '@app/core/enums';
import jwt_decode from 'jwt-decode';
declare const tinycolor: any;
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  environment = environment;
  helpDeskUrl = environment.helpDeskUrl;
  menuData = [
    {
      icon: 'space_dashboard',
      id: 1,
      name: 'Products',
      url: '/home/products',
    },
    {
      icon: 'shopping_basket',
      id: 2,
      name: 'Subscription',
      url: '/home/subscriptions',
    },
    {
      icon: 'receipt_long',
      id: 3,
      name: 'Billing History',
      url: '/home/billingistory',
    },
    {
      icon: 'payment',
      id: 4,
      name: 'Payment Methods',
      url: '/home/paymentmethods',
    },
    {
      icon: 'text_snippet',
      id: 5,
      name: 'Invoices',
      url: '/home/invoices',
    },
  ];

  // Multicolor theme
  colorSelected = '#6d41a1';
  singletheme: any;
  themes = JSON.parse(JSON.stringify(theme));
  colors: string[] = [
    '#6d41a1',
    '#6661b8',
    '#4461d7',
    '#a0144f',
    '#045b62',
    '#14539a',
    '#a76f05',
    '#000000',
  ];

  primaryColor: string;
  primaryColorPalette: Color[] = [];
  commonService: CommonService;
  // End Intalization of Muticolor theme
  subscriptionModuleList: any = [];
  myAdminUrl: string;
  profileUrl: any=null;
  ecoSpaceLogo: any=this.environment.imagePath + '/capium-logo.png';
  favIcon: any=this.environment.imagePath + '/company-logo.svg';
  userData: any;
  totalCartItem = 0;
  isSME: boolean;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    public globalComponent: GlobalComponent,
    private store: Store,
    private injector: Injector,
    public storage: LocalStorageService,
    private cookieService: CookieService
  ) {
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.onSelectionChange(this.globalComponent.themecolor(), false);

    this.globalComponent.totalCartItem.subscribe((x) => {
      this.totalCartItem = x;
    });
  }

  ngOnInit(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    this.userData=decoded;

    this.isSME = this.userData.companyType == 'Accountants' ? false : true;
    this.cookieService.set('customerId', this.userData.subcriptionClientId);
    this.cookieService.set('externalUserGUID', this.userData.ExternalUserGUID);
    this.storage.set('isSME', this.isSME);
    console.log(this.isSME);
    if (!this.isSME) {
      this.getConnectLink();
    }
    // this.cookieService.set('customerId', 'Azq8ZfTvqmqf2CaC');
    // this.cookieService.set('externalUserGUID','B2AA3F36-9559-444F-8016-505EA49DE217');
    // this.cookieService.set('isSME','true');
  }

  logout(): void {
    this.oidcSecurityService.logoff();
  }

  onLetConnect(): void {
    this.router.navigate([RoutingPath.letsConnect]);
  }

  goToProfile(): void {
    window.location.href = environment.stsServerUrl + '/Manage';
  }

  //Event for Multicolor theme
  onSelectionChange(event: any, isThemeColor: boolean) {
    this.colorSelected = event;
    this.primaryColor = event;

    this.computeColors(this.primaryColor);

    if (isThemeColor) {
      this.store.dispatch(new UpdateThemeColor(this.primaryColor)).subscribe();
    }
  }

  computeColors(hex: string): void {
    for (let index = 0; index < Object.keys(this.themes).length - 2; index++) {
      if (this.themes[index].id === hex) {
        const selectedThemeArray = this.themes[index];

        this.primaryColorPalette = [
          this.getColorObject(selectedThemeArray?.id, '500'),
          this.getColorObject(selectedThemeArray?.color50, '50'),
          this.getColorObject(selectedThemeArray?.color100, '100'),
          this.getColorObject(selectedThemeArray?.color200, '200'),
          this.getColorObject(selectedThemeArray?.color300, '300'),
        ];
      }
    }

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  //   retrieveSubscription(): void {
  //     this.store.dispatch(new RetrieveSubscription()).subscribe(
  //       (res) => {
  //         this.subscriptionModuleList =
  //           res.subscription.retrieveSubscriptionList.data.listOfAddons ?? [];
  //             this.profileUrl =
  //             res.subscription.retrieveSubscriptionList.data.picture;
  //         this.subscriptionModuleList.forEach((element) => {
  //           if (element.externalName === 'My Admin') {
  //             this.myAdminUrl = element.appUrl;
  //           }
  //         });

  //       }
  //     );
  //   }

  getConnectLink(): void {

   
    const userName = this.userData.email;

    this.store.dispatch(new GetConnectLink(userName)).subscribe((res) => {
      console.log(res);
      this.subscriptionModuleList = res.product.connectLinkList ?? [];
      this.profileUrl = res.product.profilePic ?? null;
      this.ecoSpaceLogo = res.product.ecoSpaceLogo
        ? res.product.ecoSpaceLogo
        : this.environment.imagePath + '/capium-logo.png';
        
      this.favIcon = res.product.favIcon
        ? res.product.favIcon
        : this.environment.imagePath + '/company-logo.svg';
      this.subscriptionModuleList.forEach((element) => {
        if (element.externalName === 'My Admin') {
          this.myAdminUrl = element.appUrl;
        }
      });
      this.subscriptionModuleList.sort((a, b) => a.orderNo - b.orderNo);
    });
    console.log(this.ecoSpaceLogo);
  }
  appUrl = '';
  redirectToModule(subscriptionModule, event: Event): void {
    if (subscriptionModule.isDisabled) {
      this.appUrl = '';
      event.preventDefault();
    } else {
      this.appUrl = subscriptionModule.appUrl;
    }
  }

  redirectToHome(): void {
    window.location.replace(environment.stsServerUrl);
  }

  myAdminClick() {
    window.location.replace(this.myAdminUrl);
  }
  //End of multicolor theme code
  onHelpClick(): void {
    window.open(this.helpDeskUrl, '_blank');
  }
}
