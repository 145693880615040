export class PaymentCardModel {
  customerId?: string;
  firstName: string;
  lastName: string;
  setAsPrimary?: boolean;
  cardNumber?: string;
  cardCvv?: string;
  cardExpiryYear: number;
  cardExpiryMonth: number;
  paymentSourceId?: string;
}

export class PaymentBankModel {
  paymentSourceId?: string;
  customerId?: string;
  firstName: string;
  lastName: string;
  setAsPrimary?: boolean;
  companyName?: string;
  issuingCountry?: string;
  email: string;
  accountNumber?: string;
  branchCode?: string;
  iscompany?: boolean;
}
