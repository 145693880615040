import { Injectable } from '@angular/core';
import { InvoiceService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { tap } from 'rxjs';
import {
  CollectPaymentForAnInvoice,
  CollectPaymentForSmeInvoice,
  DownloadInvoiceListByPDF,
  DownloadSmeInvoiceListByPDF,
  DownloadCapiumInvoiceListByPDF,
  GetInvoiceList,
  GetSmeInvoiceList,
} from './invoice.action';

export class InvoiceStateInfo {
  invoiceList?: Array<any>;
  invoiceDownloadUrl?: string;
  CapiumInvBytes?: string;
  CapiumInvStatus?: boolean;
  CapiumInvName?: string;
  payNowUrl?: string;
  totalRecord?: number;
}

@State<InvoiceStateInfo>({
  name: 'invoice',
  defaults: {
    invoiceList: [],
  },
})
@Injectable()
export class InvoiceState {
  constructor(private invoiceService: InvoiceService) {}

  @Selector()
  static totalRecord(state: InvoiceStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(GetInvoiceList)
  getInvoiceList(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceList
  ) {
    return this.invoiceService.getInvoiceList(action.queryParams).pipe(
      tap((res) => {
        patchState({
          invoiceList: res.valueResult.invoices,
          totalRecord: res.valueResult.invoices.length,
        });
      })
    );
  }

  @Action(DownloadInvoiceListByPDF)
  downloadInvoiceListByPDF(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: DownloadInvoiceListByPDF
  ) {
    return this.invoiceService.downloadInvoiceListByPDF(action.invoiceId).pipe(
      tap((res) => {
        patchState({
          invoiceDownloadUrl: res.valueResult.downloadUrl,
        });
      })
    );
  }

  @Action(DownloadCapiumInvoiceListByPDF)
  downloadCapiumInvoiceListByPDF(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: DownloadCapiumInvoiceListByPDF
  ) {
    return this.invoiceService
      .downloadCapiumInvoiceListByPDF(action.invoiceId, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            CapiumInvStatus: JSON.parse(res.valueResult.cnt).status,
            CapiumInvBytes: JSON.parse(res.valueResult.cnt).response,
            CapiumInvName: JSON.parse(res.valueResult.cnt).filename,
          });
        })
      );
  }

  @Action(CollectPaymentForAnInvoice)
  getPayment(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: CollectPaymentForAnInvoice
  ) {
    return this.invoiceService.getPayment(action.redirectUrl).pipe(
      tap((res) => {
        patchState({
          payNowUrl: res.valueResult.url,
        });
      })
    );
  }

  @Action(GetSmeInvoiceList)
  getSmeInvoiceList(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetSmeInvoiceList
  ) {
    return this.invoiceService.getSmeInvoiceList(action.queryParams).pipe(
      tap((res) => {
        patchState({
          invoiceList: res.valueResult.invoices,
          totalRecord: res.valueResult.invoices.length,
        });
      })
    );
  }

  @Action(DownloadSmeInvoiceListByPDF)
  downloadInvoiceSmeListByPDF(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: DownloadSmeInvoiceListByPDF
  ) {
    return this.invoiceService
      .downloadSmeInvoiceListByPDF(action.invoiceId)
      .pipe(
        tap((res) => {
          patchState({
            invoiceDownloadUrl: res.valueResult.downloadUrl,
          });
        })
      );
  }

  @Action(CollectPaymentForSmeInvoice)
  getSmePayment(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: CollectPaymentForSmeInvoice
  ) {
    return this.invoiceService.getSmePayment(action.redirectUrl).pipe(
      tap((res) => {
        patchState({
          payNowUrl: res.valueResult.url,
        });
      })
    );
  }
}
