import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    public globalComponent: GlobalComponent
  ) {}

  getIntegrationsDetails(): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }BillingHistory/RetrievingTransactionByCustomerId?customerId=${this.globalComponent.customerId()}`
    );
  }
}
