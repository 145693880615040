<div class="side-list-actions footer-list-action">
  <div class="content-body">
    <button
      mat-button
      class="close-button"
      disableRipple
      (click)="cancelSelection()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <div class="d-flex align-items-center">
      <div class="mr-4">
        <div class="mb-1">
          <p class="text-muted fw-bold m-0">Selected items</p>
        </div>
        <div class="d-flex align-items-center justify-space-between">
          <p class="font-size-16 fw-bold m-0 mr-2">
            <span class="text-muted font-size-18">Records:</span>
            {{ totalSelectedRecords }}
          </p>
          <a class="link" href="javascript:void(0)" (click)="cancelSelection()"
            >Cancel selection</a
          >
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-3">
          <p class="font-size-16 fw-bold">Download</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.CSV)"
            >
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
              <p>CSV</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
