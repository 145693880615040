export enum SubscribtionsType {
  FullSuite = 0,
  Indidvidual = 1,
  CapiumAddons = 2,
  Capium365=3
}

export enum SubscriptionsEnum {
  small = 1,
  medium = 2,
  large = 3,
  unlimited = 4,
  capiumAddOn = 16,
  trail = 17,
  fullSuiteMicroBite = 19,
  fullSuiteMicro = 20,
  fullSuiteSmall = 21,
  fullSuiteMedium = 22,
  fullSuiteLarge = 23,
  individual = 24,
  fullSuiteEnterprise = 50,
  capium365=51
}
export enum SMESubscriptionsEnum {
  trail = 1,
  capium365 = 2,
  diyboox = 3,
}

export enum PurchasedEnum {
  Future = 1,
  InTrial = 2,
  Active = 3,
  nonRenewing = 4,
  Paused = 5,
  Cancelled = 6,
}

export enum AddOnsId {
  TimeAndFee = 25,
  Charity = 26,
  SelfAssessment = 27,
  CorporationTax = 28,
  AccountsProduction = 29,
  PracticeManagement = 32,
  AdvancePayroll = 33,
  BookeepingMTD = 34,
  Individual = 24,
  CapiumHubGBPMonthly = 40,
  CompanySecretarial = 41,
  Capisign = 43,
  SMS = 42,
  BankFeeds = 44,
  CorporationTaxNew = 46,
  BookkeepingNew=47,
  SAMTD=48,
  _365Licenses=49
}

export enum ClientRange {
  smallClientRange = '100 clients',
  mediumClientRange = '300 clients',
  largeClientRange = '500 clients',
  unlimitClientRange = 'No Limit',
}

export enum PlanName {
  small = 'Small',
  medium = 'Medium',
  large = 'Large',
  unlimited = 'Unlimited',
  enterprise = 'Enterprise',
  xEnterprise = 'X Enterprise',
}

export enum PlanTypeEnumForCapiumAddons {
  small = 1,
  medium = 101,
  large = 301,
  unlimited = 501,
  mediumSMS = 501,
  largeSMS = 1001,
  unlimitedSMS = 1501,
  unlimitedSMSYearly = 2001,
  smallCSS = 1,
  mediumCSS = 101,
  largeCSS=301,
  unlimitedCSS=501,
  smallHUB = 1,
  mediumHUB = 26,
  largeHUB = 51,
  unlimitedHUB = 101,
}

export enum PlanTypeEnumForIndividualAddons {
  small = 1,
  medium = 101,
  large = 301,
  unlimited = 501,
  mediumCharity = 11,
  largeCharity = 26,
  enterpriseCharity = 51,
  xEnterpriseCharity = 76,
}

export enum Modules_5_0 {
  TimeAndFee = '25-GBP-Monthly',
  Charity = '26-GBP-Monthly',
  TimeAndFeeYearly = '25-GBP-Yearly',
  CharityYearly = '26-GBP-Yearly',
  TimeAndFeeQuarterly = '25-GBP-Every-3-months',
  CharityQuarterly = '26-GBP-Every-3-months',
  BookkeepingNew='47-GBP-Monthly',
  BookkeepingNewQuarterly='47-GBP-Every-3-months',
  BookkeepingNewYearly='47-GBP-Yearly',
  CorporationTaxNew='46-GBP-Monthly',
  CorporationTaxNewQuarterly='46-GBP-Every-3-months',
  CorporationTaxNewYearly='46-GBP-Yearly',
  SAMTD='48-GBP-Monthly',
  SAMTDQuarterly='48-GBP-Every-3-months',
  SAMTDYearly='48-GBP-Yearly'
}
export enum CharityModules_5_0 {
  Charity = '26-GBP-Monthly',
  CharityYearly = '26-GBP-Yearly',
  CharityQuarterly = '26-GBP-Every-3-months',
}
export enum TimeAndFeeModules_5_0 {
  TimeAndFee = '25-GBP-Monthly',
  TimeAndFeeYearly = '25-GBP-Yearly',
  TimeAndFeeQuarterly = '25-GBP-Every-3-months',
}

export enum CorporationTaxNewModules_5_0 {
  CorporationTaxNew='46-GBP-Monthly',
  CorporationTaxNewQuarterly='46-GBP-Every-3-months',
  CorporationTaxNewYearly='46-GBP-Yearly'  
}

export enum SAMTDModules_5_0 {
  SAMTD='48-GBP-Monthly',
  SAMTDQuarterly='48-GBP-Every-3-months',
  SAMTDYearly='48-GBP-Yearly' 
}

export enum BookkeepingNewModules_5_0 {
  BookkeepingNew='47-GBP-Monthly',
  BookkeepingNewQuarterly='47-GBP-Every-3-months',
  BookkeepingNewYearly='47-GBP-Yearly'
}

export enum Modules_3_0 {
  SelfAssessment = '27-GBP-Monthly',
  CorporationTax = '28-GBP-Monthly',
  AccountsProduction = '29-GBP-Monthly',
  PracticeManagement = '32-GBP-Monthly',
  AdvancePayroll = '33-GBP-Monthly',
  BookeepingMTD = '34-GBP-Monthly',
  SelfAssessmentYearly = '27-GBP-Yearly',
  CorporationTaxYearly = '28-GBP-Yearly',
  AccountsProductionYearly = '29-GBP-Yearly',
  PracticeManagementYearly = '32-GBP-Yearly',
  AdvancePayrollYearly = '33-GBP-Yearly',
  BookeepingMTDYearly = '34-GBP-Yearly',
  SelfAssessmentQuarterly = '27-GBP-Every-3-months',
  CorporationTaxQuarterly = '28-GBP-Every-3-months',
  AccountsProductionQuarterly = '29-GBP-Every-3-months',
  PracticeManagementQuarterly = '32-GBP-Every-3-months',
  AdvancePayrollQuarterly = '33-GBP-Every-3-months',
  BookeepingMTDQuarterly = '34-GBP-Every-3-months',
}

export enum IsBankFeed {
  BankFeed = '44-GBP-Monthly',
  BankFeedYearly = '44-GBP-Yearly',
  BankFeedQuarterly = '44-GBP-Every-3-months',
}

export enum Is365Licenses{
  _365Licenses = '49-GBP-Monthly',
  _365LicensesYearly = '49-GBP-Yearly',
  _365LicensesQuarterly = '49-GBP-Every-3-months',
}
export enum IsAllocate {
  BankFeed = '44-GBP-Monthly',
  BankFeedYearly = '44-GBP-Yearly',
  BankFeedQuarterly = '44-GBP-Every-3-months',
  CapiumHubGBPMonthly = '40-GBP-Monthly',
  CapiumHubGBPYearly = '40-GBP-Yearly',
  CapiumHubGBPQuarterly = '40-GBP-Every-3-months',
}

export enum CapiumAddons {
  CapiumHubGBPMonthly = '40-GBP-Monthly',
  CompanySecretarial = '41-GBP-Monthly',
  Capisign = '43-GBP-Monthly',
  SMS = '42-GBP-Monthly',
  BankFeeds = '44-GBP-Monthly',
  CapiumHubGBPYearly = '40-GBP-Yearly',
  CompanySecretarialYearly = '41-GBP-Yearly',
  CapisignYearly = '43-GBP-Yearly',
  SMSYearly = '42-GBP-Yearly',
  BankFeedsYearly = '44-GBP-Yearly',
  CapiumHubGBPQuarterly = '40-GBP-Every-3-months',
  CompanySecretarialQuarterly = '41-GBP-Every-3-months',
  CapisignQuarterly = '43-GBP-Every-3-months',
  SMSQuarterly = '42-GBP-Every-3-months',
  BankFeedsQuarterly = '44-GBP-Every-3-months',
}

export enum Capium365 {
  _365LicensesGBPMonthly = '49-GBP-Monthly',
  _365LicensesGBPYearly = '49-GBP-Yearly',
  _365LicensesGBPQuarterly = '49-GBP-Every-3-months'  
}

export enum AddOnsName {
  TimeAndFee = '25-GBP-Monthly',
  Charity = '26-GBP-Monthly',
  SelfAssessment = '27-GBP-Monthly',
  CorporationTax = '28-GBP-Monthly',
  AccountsProduction = '29-GBP-Monthly',
  PracticeManagement = '32-GBP-Monthly',
  AdvancePayroll = '33-GBP-Monthly',
  BookeepingMTD = '34-GBP-Monthly',
  Individual = '24-GBP-Monthly',
  BookkeepingNew='47-GBP-Monthly',
  CorporationTaxNew='46-GBP-Monthly',
  SAMTD='48-GBP-Monthly',
  _365Licenses='49-GBP-Monthly',

  TimeAndFeeYearly = '25-GBP-Yearly',
  CharityYearly = '26-GBP-Yearly',
  SelfAssessmentYearly = '27-GBP-Yearly',
  CorporationTaxYearly = '28-GBP-Yearly',
  AccountsProductionYearly = '29-GBP-Yearly',
  PracticeManagementYearly = '32-GBP-Yearly',
  AdvancePayrollYearly = '33-GBP-Yearly',
  BookeepingMTDYearly = '34-GBP-Yearly',
  IndividualYearly = '24-GBP-Yearly',
  BookkeepingNewYearly='47-GBP-Yearly',
  CorporationTaxNewYearly='46-GBP-Yearly',
  SAMTDYearly='48-GBP-Yearly',
  _365LicensesYearly='49-GBP-Yearly',

  TimeAndFeeQuarterly = '25-GBP-Every-3-months',
  CharityQuarterly = '26-GBP-Every-3-months',
  SelfAssessmentQuarterly = '27-GBP-Every-3-months',
  CorporationTaxQuarterly = '28-GBP-Every-3-months',
  AccountsProductionQuarterly = '29-GBP-Every-3-months',
  PracticeManagementQuarterly = '32-GBP-Every-3-months',
  AdvancePayrollQuarterly = '33-GBP-Every-3-months',
  BookeepingMTDQuarterly = '34-GBP-Every-3-months',
  IndividualQuarterly = '24-GBP-Every-3-months',
  BookkeepingNewQuarterly='47-GBP-Every-3-months',
  CorporationTaxNewQuarterly='46-GBP-Every-3-months',
  SAMTDQuarterly='48-GBP-Every-3-months',
  _365LicensesQuarterly='49-GBP-Every-3-months'
}

export enum modulesName {
  'Bookkeeping' = 1,
  'Accounts Production' = 2,
  'Payroll' = 3,
  'Corporation Tax' = 4,
  'Self Assessment' = 5,
  'Practice Management' = 7,
  'Advanced Payroll' = 8,
  'Bookkeeping MTD' = 13,
  'Time & Fee' = 35,
  'Charity' = 36,
  'Capium Hub' = 40,
  'Company Secretarial' = 41,
  'SMS' = 42,
  'Capisign' = 43,
  'BankFeeds' = 44,
  'Bookkeeping New'=37,
  'Corporation Tax New'=38,
  'SAMTD'=45,
  '365Licences'=49
}
export enum recommendedModules {
  TimeAndFee = '35',
  Charity = '36',
}
