import { Guid } from 'guid-typescript';

export class FileUploadResponseModel {
  isSuccess?: boolean;
  fileUrl?: string;
  fileName?: string;
  fileSize?: string;
}

export class FileUploadRequestModel {
  file: Array<File>;
  attachmentType: number;
  userId?: Guid;
}

export class MultipleFileDownloadModel {
  fileURLs: Array<string>;
}
