<div class="sidenav-body-content">
  <div class="content-header">
    <div class="content-header-input gap-15">
      <mat-form-field appearance="fill" class="search-field m-0">
        <input
          matInput
          [(ngModel)]="searchValue"
          placeholder="Search"
          autocomplete="off"
          (keyup)="onSearch($event)"
        />
        <mat-icon>search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-120 m-0">
        <mat-select
          [(ngModel)]="statusValue"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          (selectionChange)="onDropdownChange()"
        >
          <mat-option value="0">Status - All</mat-option>
          <mat-option
            *ngFor="let sl of statusList | useEnumValues"
            [value]="sl.value"
            >{{ sl.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-170 m-0">
        <mat-select
          [(ngModel)]="paymentMethodValue"
          panelClass="myPanelClass"
          (selectionChange)="onDropdownChange()"
        >
          <mat-option value="0">Payment method - All</mat-option>
          <mat-option
            *ngFor="let sl of paymentMethodList | useEnumValues"
            [value]="sl.value"
            >{{ sl.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="mat-table-wrapper">
    <table
      mat-table
      [dataSource]="billingHistoryList"
      aria-describedby="Billing History Table"
      matSort
      (matSortChange)="sorting(sort.active, sort.direction)"
    >
      <ng-container matColumnDef="invoiceId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Invoice Id</th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paidAmount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Paid Amount</th>
        <td mat-cell *matCellDef="let element">£ {{ element.paidAmount }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span
            [ngClass]="[element.status === 'Success' ? 'success' : 'danger']"
          >
            {{ element.status }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Invoice Date</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center justify-space-between">
            <div>
              {{ element.invoiceDate }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment Date</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center justify-space-between">
            <div>{{ element.paymentDate }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="method">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Payment Method
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center justify-space-between">
            {{ element.method }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td
          class="mat-cell"
          [ngClass]="'text-align-center'"
          [attr.colspan]="displayedColumns.length"
        >
          {{ notificationTextMessage.noDataFound }}
        </td>
      </tr>
    </table>
  </div>
</div>
