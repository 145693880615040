<div class="sucess-card wp-100">
  <div
    *ngIf="isPaymentShow"
    class="card text-align-center alert-card d-flex justify-content-center align-items-center flex-column"
    [ngClass]="[isPaymentSuccessful ? 'alert-success' : 'alert-danger']"
  >
    <div>
      <mat-icon
        role="img"
        class="mat-icon check-icon"
        [ngClass]="[isPaymentSuccessful ? 'success' : 'danger']"
        aria-hidden="true"
        data-mat-icon-type="font"
        >check_circle</mat-icon
      >
    </div>
    <div class="line-height-normal">
      <p
        class="font-size-24 fw-bold"
        [ngClass]="[isPaymentSuccessful ? 'success' : 'danger']"
      >
        {{ headerText }}
      </p>
      <p class="text-black">{{ detailText }}</p>
    </div>
  </div>
</div>
