import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorageService } from '@app/core/services';
import {
  BilingHistoryPaymentMethod,
  BilingHistoryStatus,
  ExportFormat,
  Modules,
  NotificationTextMessage,
} from '@app/core/enums';
import { GlobalComponent, MainListParameters } from '@app/core/models/common';
import {
  BillingHistoryState,
  GetBillingHistoryBillDetails,
  GetSmeBillingHistoryBillDetails,
} from '@app/core/store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-billinghistory-list',
  templateUrl: './billinghistory-list.component.html',
  styleUrls: ['./billinghistory-list.component.scss'],
})
export class BillinghistoryListComponent implements OnInit {
  displayedColumns: string[] = [
    'invoiceId',
    'paidAmount',
    'status',
    'invoiceDate',
    'paymentDate',
    'method',
  ];
  listParameters: MainListParameters = new MainListParameters();
  @ViewChild(MatSort) sort: MatSort;

  @Select(BillingHistoryState.totalRecord)
  totalRecord$: Observable<number>;

  billingHistoryList: any;
  masterBillingHistoryList: any = [];

  totalSelectedRecords = 0;
  statusValue = '0';
  searchValue = '';
  paymentMethodValue = '0';
  isSME: boolean;

  moduleId: Modules.BillingHistory;

  statusList = BilingHistoryStatus;
  paymentMethodList = BilingHistoryPaymentMethod;
  exportType = ExportFormat;
  notificationTextMessage = NotificationTextMessage;

  filterDictionary = new Map<string, number>();

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private datePipe: DatePipe,
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');
    console.log(this.isSME);
    this.getList();
  }

  getParameters(): any {
    const queryParams = {
      limit: this.listParameters.pageSize,
      offset: this.listParameters.pageNumber.toString(),
      status: +this.statusValue,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      paymentMethod: +this.paymentMethodValue,
      customerId: this.globalComponent.customerId(),
    };

    return queryParams;
  }

  getList(): void {
    this.getParameters();
    if (!this.isSME) {
      this.store
        .dispatch(new GetBillingHistoryBillDetails(this.getParameters()))
        .subscribe((res) => {
          let list: any = [];

          //This need to be done becoz of filtering data
          res.billingHistory.billingHistoryData.forEach((element) => {
            list.push({
              invoiceId: element.invoiceId,
              paidAmount: element.paidAmount,
              status: element.status,
              invoiceDate: this.datePipe.transform(element.invoiceDate),
              paymentDate: this.datePipe.transform(element.paymentDate),
              method: element.paymentMethod,
            });
          });
          this.masterBillingHistoryList = list;
          this.billingHistoryList = new MatTableDataSource(list);
          this.billingHistoryList.sort = this.sort;
        });
    } else {
      this.store
        .dispatch(new GetSmeBillingHistoryBillDetails(this.getParameters()))
        .subscribe((res) => {
          let list: any = [];

          //This need to be done becoz of filtering data
          res.billingHistory.billingHistoryData.forEach((element) => {
            list.push({
              invoiceId: element.invoiceId,
              paidAmount: element.paidAmount,
              status: element.status,
              invoiceDate: this.datePipe.transform(element.invoiceDate),
              paymentDate: this.datePipe.transform(element.paymentDate),
              method: element.paymentMethod,
            });
          });
          this.masterBillingHistoryList = list;
          this.billingHistoryList = new MatTableDataSource(list);
          this.billingHistoryList.sort = this.sort;
        });
    }

    this.onDropdownChange();
  }

  onSearch(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.billingHistoryList.filter = filterValue.trim().toLowerCase();
  }

  onDropdownChange(): void {
    if (this.statusValue !== '0' && this.paymentMethodValue !== '0') {
      this.billingHistoryList = this.masterBillingHistoryList.filter(
        (item: any) =>
          item.status === this.statusValue &&
          item.method === this.paymentMethodValue
      );
    } else if (this.statusValue !== '0' && this.paymentMethodValue === '0') {
      this.billingHistoryList = this.masterBillingHistoryList.filter(
        (item: any) => item.status === this.statusValue
      );
    } else if (this.statusValue === '0' && this.paymentMethodValue !== '0') {
      this.billingHistoryList = this.masterBillingHistoryList.filter(
        (item: any) => item.method === this.paymentMethodValue
      );
    } else {
      this.billingHistoryList = this.masterBillingHistoryList;
    }

    this.billingHistoryList = new MatTableDataSource(this.billingHistoryList);
    this.billingHistoryList.sort = this.sort;
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }
}
