<div class="sidenav-body-content content-body-scroll">
  <div class="content-header">
    <div class="content-header-input gap-15">
      <mat-form-field appearance="fill" class="search-field m-0">
        <input
          matInput
          placeholder="Search"
          autocomplete="off"
          (keyup)="onSearch($event)"
        />
        <mat-icon>search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-120">
        <mat-select
          [(ngModel)]="statusValue"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          (selectionChange)="onDropdownChange($event)"
        >
          <mat-option value="0">Status - All</mat-option>
          <mat-option
            *ngFor="let sl of statusList | useEnumValues"
            [value]="sl.value"
            >{{ sl.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-120" *ngIf="!isSME">
        <mat-select
          [(ngModel)]="invValue"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          (selectionChange)="onDropdownInvChange($event)"
        >
          <mat-option value="1">New Invoices</mat-option>
          <mat-option value="0">Old Invoices</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="mat-table-wrapper">
    <table
      mat-table
      [dataSource]="invoiceList"
      aria-describedby="Invoice List"
      matSort
      (matSortChange)="sorting(sort.active, sort.direction)"
    >
      <ng-container matColumnDef="invoice">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Invoice ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoice }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span [ngClass]="[element.status === 'Paid' ? 'success' : 'danger']">
            {{ element.status }}</span
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="dueOn">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Due on</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueOn }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paid">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Paid on</th>
        <td mat-cell *matCellDef="let element">
          {{ element.paid }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center justify-space-between">
            <div>£ {{ element.total }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell class="w-50" *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center grid-actions">
            <button
              mat-button
              disableRipple
              title="Pay Now"
              fontSet="material-icons-outlined"
              *ngIf="
                element.status === statusList[statusList.PaymentDue] ||
                element.status === statusList[statusList.NotPaid]
              "
              (click)="payNow()"
            >
              <mat-icon>payment</mat-icon>
            </button>
            <button
              mat-button
              disableRipple
              title="Download"
              (click)="exportToPDF(element.invoice, true)"
              *ngIf="invValue === '1'"
            >
              <mat-icon>save_alt</mat-icon>
            </button>
            <button
              mat-button
              disableRipple
              title="Download"
              (click)="exportCapiumInvToPDF(element, true)"
              *ngIf="invValue !== '1'"
            >
              <mat-icon>save_alt</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td
          class="mat-cell"
          [ngClass]="'text-align-center'"
          [attr.colspan]="displayedColumns.length"
        >
          {{ notificationTextMessage.noDataFound }}
        </td>
      </tr>
    </table>
  </div>
</div>
