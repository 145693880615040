import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ProductTrailType,
  PeriodUnitType,
  SubscriptionsEnum,
  PurchasedEnum,
  SMESubscriptionsEnum,
  SubscribtionsType,
} from '@app/core/enums';
import { GlobalComponent, ProductTabDetails } from '@app/core/models';
import { CommonService, LocalStorageService } from '@app/core/services';
import {
  CheckProductDisable,
  GetConnectLink,
  GetIndividualProductList,
  GetProductList,
  GetProductTabDetails,
  GetSubscriptionPortalDetails,
  GetSubscriptionPortalForCapiumAddonsDetails,
} from '@app/core/store';
import { GetSmeProductList, GetSmeSubscriptionPortalDetails } from '@app/core/store/sme-products';
import {
  ProductAddonsComponent,
  ProductFullSuiteComponent,
  ProductIndividualComponent,
  ProductSmeComponent,
} from '@app/modules';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs';
import { ProductCapium365Component } from './product-capium365/product-capium365/product-capium365.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  productDataList: ProductTabDetails[];
  periodUnitType = PeriodUnitType;
  frequency: number = PeriodUnitType.Month;
  addonFrequency: number = PeriodUnitType.Month;
  capium365Frequency: number = PeriodUnitType.Month;


  @ViewChild(ProductFullSuiteComponent, { static: false })
  productDetails;

  @ViewChild(ProductAddonsComponent, { static: false })
  productAddonsDetails;

  @ViewChild(ProductIndividualComponent, { static: false })
  productIndividualDetails;

  @ViewChild(ProductSmeComponent, { static: false })
  ProductSmeComponent;

  @ViewChild(ProductCapium365Component, { static: false })
  productCapium365Component;

  isShowReviewOrder: boolean;
  isPaymentDone: boolean;
  isPlanUpgraded: boolean;
  disableButton: boolean;
  isSME: boolean;
  isSMEShow: boolean;

  selectedTab: number = 0;
  showAddonTab = false;
  isSubscriptionCancelled = false;
  isAddonSubscriptionCancelled = false;
  isProductDisabled = false;  
  constructor(
    public dialog: MatDialog,
    private store: Store,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    public storage: LocalStorageService,
    public commonService: CommonService,
    public globalComponent: GlobalComponent,
    
public oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
   
    console.log(this.storage.get('frequency'));
    this.isSME = this.storage.get('isSME');
    if (
      this.storage.get('fullSuiteAddonList') != null &&
      Object.keys(this.storage.get('fullSuiteAddonList')).length !== 0
    ) {
      this.frequency = this.storage.get('frequency')
        ? this.storage.get('frequency')
        : this.frequency;
    }else if(Object.keys(this.storage.get('cartDetails')).length !== 0) {     
        if(this.storage.get('cartDetails')[0].subscribtionsType=== SubscribtionsType.Indidvidual)
          {
            this.frequency = this.storage.get('frequency')
            ? this.storage.get('frequency')
            : this.frequency;
          }
    }

    this.storage.set('frequency', this.frequency);
    this.isPaymentDone =
      this.activatedRoute.snapshot.queryParams['state'] !== undefined;
    console.log(this.isPaymentDone);
    if (!this.isSME) {
      

      if (!this.isPaymentDone) {
        this.getSubscriptionData();
        this.getConnectLink();
      }
      this.checkProductDisable();
    } else {
      this.getSmeSubscriptionDetailData();
    }
  }

  checkProductDisable(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    const userName = decoded.email;

    this.store.dispatch(new CheckProductDisable(userName)).subscribe((res) => {
      this.isProductDisabled = res.product.isProductDisabled;
    });
  }

  setFrequency(defaultFrequency: any, period): void {
    
    let frequency;
    if (defaultFrequency === ProductTrailType.TrailPeriodUnitType) {
      frequency = PeriodUnitType.Month;
    } else if (
      (defaultFrequency === PeriodUnitType.Month ||
        defaultFrequency === PeriodUnitType.Year) &&
      period === 1
    ) {
      frequency = defaultFrequency;
    } else {
      frequency = PeriodUnitType.Quarterly;
    }

    if(this.isSME){
      this.frequency=frequency;
      this.storage.set('frequency',this.frequency);
    }else
    {
      this.storage.set('frequency',frequency);
    }

    if(!this.storage.get('frequency')){
    this.frequency = frequency;
    }

    // if (!this.isSME) {
    //   this.getProjectDetailData('1');
    // }
  }

  getConnectLink(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    const userName = decoded.email;

    // this.store.dispatch(new GetConnectLink(userName)).subscribe();
  }

  getSubscriptionData(): void {
    this.spinner.show();
    this.store.dispatch(new GetSubscriptionPortalDetails()).subscribe((x) => {
      if (x.subscriptionPortal.subscriptionPortalData.length > 0) {
        let isTrailPeriod = x.subscriptionPortal.subscriptionPortalData.some(
          (item: any) => {
            const isTrue =
              +parseInt(item.itemPrice.itemId) === +SubscriptionsEnum.trail ||
              +parseInt(item.status) === +PurchasedEnum.Cancelled;

            if (+parseInt(item.status) === +PurchasedEnum.Cancelled) {
              this.isSubscriptionCancelled = true;
            }

            return isTrue;
          }
        );

        this.disableButton =
          !isTrailPeriod && !this.isSubscriptionCancelled ? true : false;

        this.showAddonTab = !isTrailPeriod && !this.isSubscriptionCancelled;

        this.setFrequency(
          x.subscriptionPortal.subscriptionPortalData[0].itemPrice.periodUnit,
          x.subscriptionPortal.subscriptionPortalData[0].itemPrice.period
        );

        if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
          this.selectedTab =
            this.storage.get('cartDetails')[0].subscribtionsType;
        } else {
          this.selectedTab =
            x.subscriptionPortal.subscriptionPortalData[0].itemPrice
              .itemFamilyId - 1;
        }
        this.onTabClick(this.selectedTab);

        this.storage.set('isTrailPeriod', isTrailPeriod);
        this.storage.set(
          'isSubscriptionCancelled',
          this.isSubscriptionCancelled
        );
        if (isTrailPeriod && this.isSubscriptionCancelled) {
          // this.getSubscriptionAddonsData();
        }
      } else {
        this.getSubscriptionAddonsData();
      }
    });
  }

  getSubscriptionAddonsData(): void {
    this.store
      .dispatch(new GetSubscriptionPortalForCapiumAddonsDetails())
      .subscribe((res) => {
        let subscriptionList =
          res.subscriptionPortal.subscriptionPortalDataForCapiumAddons;
        if (subscriptionList.length) {
          let isTrailPeriod =
            res.subscriptionPortal.subscriptionPortalDataForCapiumAddons.some(
              (item: any) => {
                const isTrue =
                  +item.itemPrice.itemId === +SubscriptionsEnum.trail ||
                  +item.status === +PurchasedEnum.Cancelled;

                if (+item.status === +PurchasedEnum.Cancelled) {
                  this.isAddonSubscriptionCancelled = true;
                }
                return isTrue;
              }
            );
          // this.getProjectDetailData('1');

          if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
            this.selectedTab =
              this.storage.get('cartDetails')[0].subscribtionsType;
          } else {
            this.selectedTab =
              res.subscriptionPortal.subscriptionPortalDataForCapiumAddons[0]
                .itemPrice.itemFamilyId - 1;
          }
          this.onTabClick(this.selectedTab);
        } else {
          this.showAddonTab = false;

          if (
            this.storage.get('subscribtionsType') !== null &&
            this.storage.get('subscribtionsType') !== undefined &&
            Object.keys(this.storage.get('cartDetails')).length !== 0
          ) {
            this.selectedTab = this.storage.get('subscribtionsType');
            if (Object.keys(this.storage.get('cartDetails')).length === 0) {
              this.storage.removeItem('subscribtionsType');
            }
          } else {
            this.selectedTab = 0;
          }
          // this.getProjectDetailData('1');

          this.onTabClick(this.selectedTab);
        }
      });
  }

  getProductTabDetails(): void {
    this.store
      .dispatch(new GetProductTabDetails())
      .pipe(
        tap((res) => {
          this.productDataList = res.product.productList;
          this.getSubscriptionData();
        })
      )
      .subscribe();
  }

  toggleReviewOrder(isUpgrade: any): void {
    this.isPlanUpgraded = isUpgrade;
    this.isShowReviewOrder = !this.isShowReviewOrder;
  }

  getProjectDetailData(id: string): void {
    this.spinner.show();
    this.store.dispatch(new GetProductList(id, this.frequency)).subscribe();
  }

  getProjectIndividualDetailData(id: string): void {
    this.spinner.show();
    this.store.dispatch(new GetIndividualProductList(id, this.frequency)).subscribe();
  }

  getSmeProjectDetailData(id: string): void {
    this.spinner.show();
    this.store.dispatch(new GetSmeProductList(id, this.frequency)).subscribe();
  }
  getSmeSubscriptionDetailData() {
    this.store.dispatch(new GetSmeSubscriptionPortalDetails()).subscribe((x) => {
      console.log(x);
      if (x.smeproduct.smeSubscriptionPortalData.length > 0) {
        let isTrailPeriod = x.smeproduct.smeSubscriptionPortalData.some(
          (item: any) => {
            const isTrue =
              +parseInt(item.itemPrice.itemId) === +SMESubscriptionsEnum.trail ||
              +parseInt(item.status) === +PurchasedEnum.Cancelled;

              if (+parseInt(item.status) === +PurchasedEnum.Cancelled) {
                this.isSubscriptionCancelled = true;
              }

              return isTrue;
            }
          );

          this.disableButton =
            !isTrailPeriod && !this.isSubscriptionCancelled ? true : false;
          console.log(this.disableButton);

          this.showAddonTab = !isTrailPeriod && !this.isSubscriptionCancelled;

          this.setFrequency(
            x.smeproduct.smeSubscriptionPortalData[0].itemPrice.periodUnit,
            x.smeproduct.smeSubscriptionPortalData[0].itemPrice.period
          );
          this.isSMEShow = true;

          if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
            this.selectedTab =
              this.storage.get('cartDetails')[0].subscribtionsType;
          } else {
            this.selectedTab =
              x.smeproduct.smeSubscriptionPortalData[0].itemPrice.itemFamilyId -
              1;
          }
          this.onTabClick(this.selectedTab);

          this.storage.set('isTrailPeriod', isTrailPeriod);
          this.storage.set(
            'isSubscriptionCancelled',
            this.isSubscriptionCancelled
          );
        } else {
          this.isSMEShow = true;
        }
      });
  }

  onCancel(): void {
    this.isShowReviewOrder = false;
  }

  onTabClick(event: any): void {
    if (this.selectedTab !== undefined) {
      this.selectedTab = event;
    }
    this.onFrequencyChange(this.frequency,false);
  }

  onFrequencyChange(value,isFilterChanges:boolean) { 
    let Sessionfrequency=this.storage.get('frequency');
    if(isFilterChanges)
      {
        this.frequency = parseInt(value.value);
      }else{
        this.frequency =Sessionfrequency;
      }   
    this.storage.set('frequency', this.frequency);
   
    if (!this.isSME) {     
      if (this.selectedTab === 2) {
        this.productAddonsDetails.ngOnInit();
      }else if(this.selectedTab === 1){
        // this.getProjectIndividualDetailData('1');
        this.productIndividualDetails.ngOnInit();
      }else if(this.selectedTab ===3){
        this.productCapium365Component.ngOnInit();
      }else
      {        
        this.getProjectDetailData('1');
        this.commonService.storeCartItemDataLocally();
      } 
    }  

    if(Sessionfrequency!=this.frequency){       
    this.storage.removeItem('cartDetails');
    this.globalComponent.totalCartItem.next(0);     
    }  
  }
}
