import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PeriodUnitType, PurchasedEnum, SubscribtionsType, SubscriptionsEnum,Is365Licenses, PeriodUnitText, AddOnsName, PeriodUnitTextShort, AddOnsId, modulesName, CapiumAddons, Capium365 } from '@app/core/enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { Select, Store } from '@ngxs/store';
import { GetCapium365ProductDetailAddonsByData,  GetSubscriptionPortalForCapium365, GetSubscriptionPortalForCapiumAddonsDetails, SetProductDetailAcrdngPlan, SubscriptionPortalState } from '@app/core/store';
import { environment } from '@environments/environment';
import { CommonService, LocalStorageService } from '@app/core/services';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { OidcSecurityService } from 'angular-auth-oidc-client';
@Component({
  selector: 'app-product-capium365',
  templateUrl: './product-capium365.component.html',
  styleUrls: ['./product-capium365.component.scss']
})
export class ProductCapium365Component implements OnInit {

  @Output()
  readonly toggleReviewOrder = new EventEmitter<any>();
  
  @Input()
  subscribtionsType: number;
  public userName:string;

  productCapium365List: Array<any> = [];
  addonsSubscriptionList: Array<any> = [];
  selectedSubscriptionList: Array<any> = [];
  isAddOnPurshed: boolean;
  planListMonth: Array<any>;
  addonsId = AddOnsId;
  planListYearly: Array<any>;
  planListQuarterly: Array<any>;
  _365LicensesPrice: Array<any>;
  isSubscriptionCancelled = false; 
  isTrailPeriod = false;
  noOfLicences = 10;
  connectLinkList: any;
  periodUnitType = PeriodUnitType;
  modulesName = modulesName;
  capiumAddons = CapiumAddons;

  today = moment();

  @Input()
  globalFrequency: number;

  @Select(SubscriptionPortalState.subscriptionPortalData)
  subscriptionPortalData$: Observable<Array<any>>;
  isTrialSubscription: boolean;
  trialEndDate: Date;

  constructor(public spinner: NgxSpinnerService,
    private store: Store,
    public storage: LocalStorageService,
    public commonService: CommonService,
    public oidcSecurityService: OidcSecurityService,) { 
    
  }

  ngOnInit(): void {
    this.productCapium365List=[];
    
    if (this.subscribtionsType === SubscribtionsType.Capium365) {
      this.getSubscriptionData();
      // this.connectLinkList$.subscribe((x) => {
      //   this.connectLinkList = x;
      // });
      
    }
  }

  onUpgradeClick(item: any): void {
    item.showPlans = true;
  }
  onConnectToSalesClick(item: any): void {
    this.commonService.triggerConnectUs.next(item.itemId);
  }

  getSubscriptionData(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    this.userName = decoded.email;
    this.spinner.show();  
    this.store
      .dispatch(new GetSubscriptionPortalForCapium365(this.userName))
      .subscribe((res) => {
        console.log(res);
        let subscriptionList =
          res.subscriptionPortal.subscriptionPortalDataForCapium365;

        this.addonsSubscriptionList = subscriptionList.filter((item) => {
          return +item.itemPrice.itemId !== +SubscriptionsEnum.capium365;
        });

        this.isAddOnPurshed = this.addonsSubscriptionList.length > 0;

        this.getList();
      });
  }

  getList(): any {
    
    this.productCapium365List = [];

    if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
      if (
        this.storage
          .get('cartDetails')
          .some((e) => e.subscribtionsType === SubscribtionsType.CapiumAddons)
      ) {
        this.productCapium365List = this.storage.get('cartDetails');
        this.commonService.storeCartItemDataLocally(this.productCapium365List);
        this.spinner.hide();
      } else {
        this.getAddonsList();
      }
    } else {
     
      this.getAddonsList();
    }
  }

  setAddToCartDisable(item: any): boolean {
    let isDisbaleAddToCart = false;
    if (item.subscriptionList !== undefined && !item.Is365Licenses) {
      isDisbaleAddToCart = item.selectedPlanId === item.subscriptionList.tier;
    } else if (item.subscriptionList !== undefined && item.Is365Licenses) {
      isDisbaleAddToCart = this.noOfLicences === item.subscriptionList.tier;
    }
    return isDisbaleAddToCart;
  }

  getAddonsList(): void {
    
    this.spinner.show();
    this.store
      .dispatch(
        new GetCapium365ProductDetailAddonsByData("1")
      )
      .subscribe((res) => {
        console.log(res);
        let addonsList: any = [];
        let quarterlyAddons;
        let monthlyAddons;
        let yearlyAddons;
        
        res.product.Capium365productDetailAddonsData.forEach((element) => {
          if (element.frequency === PeriodUnitType.Month) {
            monthlyAddons = element.addons;
          } else if (element.frequency === PeriodUnitType.Year) {
            yearlyAddons = element.addons;
          } else {
            quarterlyAddons = element.addons;
          }
        });

        for (let i = 0; i < monthlyAddons.length; i++) {
          let montly = monthlyAddons[i].item;
          montly['isDisable'] = monthlyAddons[i].isDisable;
          montly['cardContent'] = monthlyAddons[i].cardContent;

          addonsList.push({
            monthlyObj: montly,
            tiersYearly: "",
            yearlyObj: "",
            quarterlyObj:"",
          });
          
          // for (let j = 0; j < yearlyAddons.length; j++) {
          //   if (monthlyAddons[i].item.itemId === yearlyAddons[j].item.itemId) {
          //     let montly = monthlyAddons[i].item;
          //     montly['isDisable'] = monthlyAddons[i].isDisable;
          //     montly['cardContent'] = monthlyAddons[i].cardContent;

          //     let yearly = yearlyAddons[j].item;
          //     yearly['isDisable'] = yearlyAddons[j].isDisable;
          //     yearly['cardContent'] = yearlyAddons[j].cardContent;             


          //     let quarterly = quarterlyAddons.filter(
          //       (qitem) => qitem.item.itemId === monthlyAddons[i].item.itemId
          //     );

          //     if (quarterly.length > 0) {
          //       quarterly['isDisable'] = monthlyAddons[i].isDisable;
          //       quarterly['cardContent'] = monthlyAddons[i].cardContent;
          //     }

          //     addonsList.push({
          //       monthlyObj: montly,
          //       tiersYearly: yearlyAddons[j].item.tiers,
          //       yearlyObj: yearly,
          //       quarterlyObj: quarterly[0].item,
          //     });
          //   }
          // }
        }
        console.log(addonsList);

        addonsList.forEach((y) => {
          this.planListMonth = [];
          this.planListYearly = [];
          this.planListQuarterly = [];
          this._365LicensesPrice = [];

          let subscriptionList;
          let isModuleSubscribed = false;
          let selectedPlanId = 1;
          let selectedRange = '';
          let frequency = 3;
          let isNonRenewing = false;

          let is365Licenses = Object.values(Is365Licenses)?.includes(y.monthlyObj.id);
         
          // let isAllocate = Object.values(IsAllocate)?.includes(y.monthlyObj.id);
         
          if (this.isAddOnPurshed) {
            this.addonsSubscriptionList.forEach((element) => {
              const isMonthly =
                element.itemPrice.periodUnit === PeriodUnitType.Month &&
                element.itemPrice.period == 1;
              const isYearly =
                element.itemPrice.periodUnit === PeriodUnitType.Year &&
                element.itemPrice.period == 1;
              const selectedId = isMonthly
                ? y.monthlyObj.id
                : isYearly
                ? y.yearlyObj.id
                : y.quarterlyObj.id;

              let selectedPeriodUnit;
              if (isMonthly) {
                selectedPeriodUnit = PeriodUnitType.Month;
              } else if (isYearly) {
                selectedPeriodUnit = PeriodUnitType.Year;
              } else {
                selectedPeriodUnit = PeriodUnitType.Quarterly;
              }

              const isModuleSub = element.itemPrice.id === selectedId;

              if (isModuleSub) {
                isModuleSubscribed = true;
                selectedPlanId = element.tier;
                // frequency = selectedPeriodUnit;
                isNonRenewing = element.status === PurchasedEnum.nonRenewing;
                //To set subscriptionList of the purchased subscribtion
                subscriptionList = element;
              }
            });
          }

          if (!is365Licenses) {
           
          } else {
            this._365LicensesPrice.push({
              monthly: y.monthlyObj.price,
              yearly: y.yearlyObj.price,
              quarterly: y.quarterlyObj.price,
            });

            if (subscriptionList !== undefined) {
              this.noOfLicences = subscriptionList.tier;
            }          
          }

          let frequencyText;
          if (frequency === PeriodUnitType.Month) {
            frequencyText = PeriodUnitTextShort.Month;
          } else if (frequency === PeriodUnitType.Year) {
            frequencyText = PeriodUnitTextShort.Year;
          } else {
            frequencyText = PeriodUnitTextShort.Quarterly;
          }

          let addOnsId;
          if (frequency === PeriodUnitType.Month) {
            addOnsId = y.monthlyObj.id;
          } else if (frequency === PeriodUnitType.Year) {
            addOnsId = y.yearlyObj.id;
          } else {
            addOnsId = y.quarterlyObj.id;
          }

          

          let planId;
          
          let addonsArray:any[]=res.product.Capium365productDetailAddonsData;
          addonsArray.forEach((Element)=>{
            if(frequency === Element.frequency){
              planId=Element.plan;
            }
          });
            

          // if (frequency === PeriodUnitType.Month) {
          //     planId = res.product.productDetailAddonsData[1].plan;
          // } else if (frequency === PeriodUnitType.Year) {
          //     planId = res.product.productDetailAddonsData[2].plan;
          // } else {
          //     planId = res.product.productDetailAddonsData[0].plan;
          // }

          let _365LicensesPrice;
          if (Is365Licenses) {
            if (frequency === PeriodUnitType.Month) {
              _365LicensesPrice = this._365LicensesPrice[0].monthly;
            } else if (frequency === PeriodUnitType.Year) {
              _365LicensesPrice = this._365LicensesPrice[0].yearly;
            } else {
              _365LicensesPrice = this._365LicensesPrice[0].quarterly;
            }
          } else {
            _365LicensesPrice = 0;
          }

          let headerText;
          if (frequency === PeriodUnitType.Month) {
            headerText = PeriodUnitText.Month;
          } else if (frequency === PeriodUnitType.Year) {
            headerText = PeriodUnitText.Year;
          } else {
            headerText = PeriodUnitText.Quarterly;
          }
          this.productCapium365List=[];
          this.productCapium365List.push({
            lineItems1: y.monthlyObj.cardContent.split('\n')[0],
            lineItems2: y.monthlyObj.cardContent.split('\n')[1],
            lineItems3: y.monthlyObj.cardContent.split('\n')[2],
            // lineItems1:'1',
            // lineItems2: '2',
            // lineItems3: '3',
            externalName: y.monthlyObj.externalName.replace('Individual', ''),
            description:
              y.monthlyObj.description === null ? '' : y.monthlyObj.description,
            planListMonth: this.planListMonth,
            planListYearly: this.planListYearly,
            planListQuarterly: this.planListQuarterly,
            frequencyText: frequencyText,
            frequency: frequency,
            addOnsId: addOnsId,
            itemId: y.monthlyObj.itemId,
            planId: planId,
            selectedPlanId: selectedPlanId,
            selectedPlanName: !Is365Licenses
              ? this.getSelectedPlanName(
                  isModuleSubscribed,
                  selectedPlanId,
                  frequency === PeriodUnitType.Month
                    ? this.planListMonth
                    : frequency === PeriodUnitType.Year
                    ? this.planListYearly
                    : this.planListQuarterly
                )
              : this.noOfLicences +
                (this.noOfLicences == 1 ? ' License' : ' Licenses'),
            selectedClientRange: selectedRange,
            noOfTrailsDaysLeft: this.commonService.getNoOfDays(
              this.isTrailPeriod,
              this.isSubscriptionCancelled,
              subscriptionList !== undefined
                ? subscriptionList.nextBillingDate
                : 0,
              this.selectedSubscriptionList
            ),
            nextBillingDate: this.commonService.getNextBillingDate(
              this.isTrailPeriod,
              this.isSubscriptionCancelled,
              subscriptionList !== undefined
                ? subscriptionList.nextBillingDate
                : new Date(),
              this.selectedSubscriptionList
            ),
            subscriptionList: subscriptionList,
            isModuleSubscribed: isModuleSubscribed,
            Is365Licenses: Is365Licenses,
            isAllocate: false,
            showPlans: false,
            isAddedToCart: false,
            isNonRenewing: isNonRenewing,
            isDisable: y.monthlyObj.isDisable,
            headerText: headerText,
            isTrailPeriod: this.isTrailPeriod, //needed in order page
            addOnPurshed: this.isAddOnPurshed, //needed in order page
            subscribtionsType: this.subscribtionsType, //needed in order page
            _365LicensesPrice: _365LicensesPrice,
            noOfLicences: this.noOfLicences, //needed in order page
          });
          console.log(this.productCapium365List, 'sample data');
          isModuleSubscribed = false;
          isNonRenewing = false;
        });
      });
      
  }

  setDisablePlan(item: any, isDisable: boolean, isAddToCart): void {
    this.productCapium365List.forEach((element) => {
      //Disable other plans apart from that plan id for the selected module
      if (item.addOnsId === element.addOnsId) {
        element.planListMonth.forEach((y) => {
          y.disablePlan = isAddToCart
            ? isDisable
            : element.subscriptionList !== undefined
            ? y.startingUnit < item.subscriptionList.tier
            : false;
        });
      }
    });
  }

  goToCart(isPlanUpgraded: boolean, item: any): void {
    item.isAddedToCart = true;
    this.setDisablePlan(item, true, true);

    this.productCapium365List.forEach((x) => {

      x.noOfLicences =
        x.addOnsId === Capium365._365LicensesGBPMonthly ||
        x.addOnsId === Capium365._365LicensesGBPQuarterly ||
        x.addOnsId === Capium365._365LicensesGBPYearly
          ? this.noOfLicences
          : 0;
    });

    this.store.dispatch(new SetProductDetailAcrdngPlan()).subscribe();
    this.storage.set('subscribtionsType', this.subscribtionsType);
    this.commonService.storeCartItemDataLocally(this.productCapium365List);
    this.toggleReviewOrder.emit(isPlanUpgraded);
    console.log(this.productCapium365List);
  }
  onBackClick(item: any): void {
    item.showPlans = false;
  }

  checkingAddons(item: any): boolean {
    if (parseInt(item.itemId) !== this.modulesName['Company Secretarial']) {
      return true;
    } else if (
      parseInt(item.itemId) === this.modulesName['Company Secretarial'] &&
      item.isModuleSubscribed
    ) {
      return true;
    } else {
      return false;
    }
  }

  onConnectClick(item: any): void {
    let redirection = this.connectLinkList.find(
      (e) => e.individualAddonId === item.itemId
    ).demoVideoUrl;

    const url =
      redirection === undefined || redirection === null
        ? environment.stsServerUrl
        : redirection;
    window.open(url, '_blank');
  }

  onPlanChange(selectedPlanvalue: string, item: any) {
    item.selectedPlanId = +selectedPlanvalue;
  }

  getSelectedPlanName(
    isModuleSubscribed,
    selectedPlanId: number,
    planList: any
  ) {
    let planName;
    if (isModuleSubscribed) {
      planName = planList.find(
        (e) => e.startingUnit === +selectedPlanId
      ).planName;
    }
    return planName;
  }

  onFrequencyChange(item: any, value) {
    item.frequency = parseInt(value.value);
    let frequencyTextShort;
    if (item.frequency === PeriodUnitType.Quarterly) {
      frequencyTextShort = PeriodUnitTextShort.Quarterly;
    } else {
      frequencyTextShort = PeriodUnitTextShort.Month;
    }
    item.frequencyText = frequencyTextShort;

    let headerText;
    if (item.frequency === PeriodUnitType.Year) {
      headerText = PeriodUnitText.Year;
    } else {
      headerText =
        item.frequency === PeriodUnitType.Quarterly
          ? PeriodUnitText.Quarterly
          : PeriodUnitText.Month;
    }
    item.headerText = headerText;

    switch (item.frequency) {
      case PeriodUnitType.Month:
        item.addOnsId = item.addOnsId
          .replace('Yearly', 'Monthly')
          .replace('Every-3-months', 'Monthly');
        break;
      case PeriodUnitType.Year:
        item.addOnsId = item.addOnsId
          .replace('Monthly', 'Yearly')
          .replace('Every-3-months', 'Yearly');
        break;
      case PeriodUnitType.Quarterly:
        item.addOnsId = item.addOnsId
          .replace('Yearly', 'Every-3-months')
          .replace('Monthly', 'Every-3-months');
        break;
    }
    switch (item.frequency) {
      case PeriodUnitType.Month:
        item.planId = item.planId
          .replace('Yearly', 'Monthly')
          .replace('Every-3-months', 'Monthly');
        break;
      case PeriodUnitType.Year:
        item.planId = item.planId
          .replace('Monthly', 'Yearly')
          .replace('Every-3-months', 'Yearly');
        break;
      case PeriodUnitType.Quarterly:
        item.planId = item.planId
          .replace('Yearly', 'Every-3-months')
          .replace('Monthly', 'Every-3-months');
        break;
    }

    if (item.Is365Licenses) {
      let License365Price;
      if (item.frequency === PeriodUnitType.Month) {
        License365Price = this._365LicensesPrice[0].monthly;
      } else if (item.frequency === PeriodUnitType.Year) {
        License365Price = this._365LicensesPrice[0].yearly;
      } else {
        License365Price = this._365LicensesPrice[0].quarterly;
      }
      item.License365Price = License365Price;
    }
  }

  handleMinus(item: any): void {
    if (this.noOfLicences > 1) {
      if (
        item.subscriptionList !== undefined &&
        this.noOfLicences !== item.subscriptionList.tier
      ) {
        this.lessNoOfLicences();
      } else if (item.subscriptionList === undefined) {
        this.lessNoOfLicences();
      }
    }
  }

  AllocateRedirectUrl(item: any): void {
    let redirection = this.connectLinkList.find(
      (e) => e.individualAddonId === item.itemId
    ).appUrl;

    const url =
      redirection === undefined || redirection === null
        ? environment.stsServerUrl
        : redirection;
    window.open(url, '_blank');
  }

  handlePlus(): void {
    this.noOfLicences+=10;
  }
  lessNoOfLicences(): void {
    if(this.noOfLicences > 10)
    this.noOfLicences-=10;
  }

}

