import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  PeriodUnitType,
  PlanFullDescription,
  PlanName,
  PlanShortDescription,
  PurchasedEnum,
  SubscriptionsEnum,
} from '@app/core/enums';
import { GlobalComponent } from '@app/core/models';
import { CommonService, LocalStorageService } from '@app/core/services';
import { GetAddonListByPlanId, ProductState, SubscriptionPortalState } from '@app/core/store';
import { environment } from '@environments/environment';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-product-full-suite',
  templateUrl: './product-full-suite.component.html',
  styleUrls: ['./product-full-suite.component.scss'],
})
export class ProductFullSuiteComponent implements OnInit {
  @Output()
  readonly toggleReviewOrder = new EventEmitter<any>();

  @Select(ProductState.productDetailData)
  productDetailData$: Observable<Array<any>>;

  @Select(SubscriptionPortalState.subscriptionPortalData)
  subscriptionPortalData$: Observable<Array<any>>;

  shortDesc = PlanShortDescription;
  fullDesc = PlanFullDescription;
  planName = PlanName;

  isPlanSelected = false;
  isTrailPeriod = false;

  isSubscriptionCancelled = false;

  selectedPlanId = '';
  CancelledPlanId = '';
  headerText = '';

  noOfTrailsDaysLeft: number;
  nextBillingDate: any;
  trialEndDate: any;

  @Input()
  subscribtionsType: number;

  periodUnitType = PeriodUnitType;

  @Input()
  frequency: PeriodUnitType;
  isTrialSubscription: boolean;

  constructor(
    private store: Store,
    public globalComponent: GlobalComponent,
    public commonService: CommonService,
    public storage: LocalStorageService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    console.log(this.frequency);

    this.subscriptionPortalData$.subscribe((res) => {
      console.log(res);
      if (res.length > 0) {
        this.selectedPlanId = res[0].itemPrice.itemId;

        this.isTrailPeriod = res.some((item: any) => {
          const isTrue =
            +item.itemPrice.itemId === +SubscriptionsEnum.trail ||
            +item.status === +PurchasedEnum.Cancelled;

          if (+item.itemPrice.itemId === +SubscriptionsEnum.trail) {
            this.headerText = 'Trial';
            this.isTrialSubscription = true;
          }
          if (
            +item.status === +PurchasedEnum.Cancelled &&
            parseInt(item.itemPrice.itemId) !== +SubscriptionsEnum.trail
          ) {
            this.isSubscriptionCancelled = true;
            this.selectedPlanId = '';
            this.CancelledPlanId = res[0].itemPrice.itemId;
            this.headerText = 'Subscription';
          }

          return isTrue;
        });

        let billingDate = new Date(res[0].nextBillingDate);
        let endDate = res[0].endDate;
        const words = endDate.split('T');
        if (words.length) {
          this.trialEndDate = new Date(words[0]);
        }
        this.nextBillingDate = this.commonService.getNextBillingDate(
          this.isTrailPeriod,
          this.isSubscriptionCancelled,
          billingDate,
          res
        );

        this.noOfTrailsDaysLeft = this.commonService.getNoOfDays(
          this.isTrailPeriod,
          this.isSubscriptionCancelled,
          billingDate,
          res
        );
      }
    });
  }

  onBuyNowClick(planId: any, isUpgrade: boolean): void {
    console.log(planId);
    this.spinner.show();
    this.store.dispatch(new GetAddonListByPlanId(planId, this.frequency)).subscribe((x) => {
      console.log(x.product.AddonListByPlanIdData);    
      this.storage.set('subscribtionsType', this.subscribtionsType);   
      this.storage.set('fullSuiteAddonList', x.product.AddonListByPlanIdData);
      this.storage.set('isUpgrade', isUpgrade);
      this.commonService.storeCartItemDataLocally();
      this.globalComponent.totalCartItem.next(x.product.AddonListByPlanIdData.addons.length);
      this.toggleReviewOrder.emit(isUpgrade);
    });
   
  }

  onConnectClick(): void {
    window.location.href = environment.stsServerUrl;
  }

  onManyMoreClick(): void {
    window.open('https://www.capium.com/pricing/', '_blank', 'noopener');
  }
}
