<div class="mt-1 product-list-wrap">
  <h2 class="text-primary font-size-20 mb-1 d-none">For Accountants</h2>
  <div class="card-wrapper mb-1">
    <div
      class="card position-relative pl-0 pr-0"
      *ngFor="let item of productIndividualList"
    >
      <div class="card-head pl-1 pr-1">
        <div class="d-flex align-items-center gap-10">
          <img
            src="{{ commonService.getImagePath(item.addOnsId) }}"
            alt="icon"
          />
          <div>
            <p class="m-0">{{ item.externalName }}</p>
            <span>{{ item.description }} </span>
          </div>
        </div>
      </div>
      <div class="card-list">
        <ul class="pl-1 pr-1">
          <li>
            <mat-icon class="success mr-5">check_circle</mat-icon>
            {{ item.lineItems1 }}
          </li>
          <li>
            <mat-icon class="success mr-5">check_circle</mat-icon>
            {{ item.lineItems2 }}
          </li>
          <li>
            <mat-icon class="success mr-5">check_circle</mat-icon>
            {{ item.lineItems3 }}
          </li>
        </ul>
        <div class="connect-button">
          <div class="plan-details">
            <!-- when module is not Subscribe then only toggle button will be visible -->
            <div class="plan-list-view" *ngIf="item.showPlans">
              <mat-button-toggle-group value="{{ item.selectedPlanId }}">
                <div
                  class="package-selection"
                  *ngFor="let pl of item.planList; let i = index"
                >
                  <mat-button-toggle
                    [ngClass]="{ 'toggle-disable': pl.disablePlan }"
                    value="{{ pl.startingUnit }}"
                    (change)="onPlanChange($event.value, item)"
                    checked
                    disableRipple
                    [disabled]="pl.disablePlan"
                    >{{ pl.planName }}
                  </mat-button-toggle>
                </div>
              </mat-button-toggle-group>

              <!-- when module is not Subscribe then only toggle button will be visible -->
              <div class="plan-list">
                <div *ngFor="let pl1 of item.planList">
                  £ <strong>{{ pl1.price / 100 }}</strong
                  >/{{ item.frequencyText }}
                  <br />
                  {{ pl1.clientsRange }}
                </div>
              </div>
            </div>

            <div
              *ngIf="!item.showPlans"
              class="connect-button pt-1 pb-5 pl-1 pr-1"
            >
              <div class="connect-button-warapper watch-button mb-5">
                <!-- when module is not Subscribe readonly button -->
                <h4 class="cursor-pointer mb-0 pb-10">Product Overview</h4>
                <div
                  [ngClass]="
                    (item.isModuleSubscribed ||
                      isTrailPeriod ||
                      isFullSuitePurchased) &&
                    !isSubscriptionCancelled
                      ? ''
                      : 'readonly-button lock-button no-border'
                  "
                  (click)="onConnectClick(item)"
                >
                  <div class="d-flex align-items-center gap-10 cursor-pointer">
                    <img src="assets/img/Thumbnail.svg" alt="Watch Now" />
                    <a class="link">Watch Now</a>
                  </div>
                </div>
              </div>

              <!-- if in trail plan -->
              <p
                class="danger"
                *ngIf="isTrailPeriod && item.noOfTrailsDaysLeft > 0"
              >
                {{ item.noOfTrailsDaysLeft }}
                {{ item.noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
                {{ headerText }} ends on :
                {{
                  (isTrialSubscription ? trialEndDate : item.nextBillingDate)
                    | date
                }}
              </p>

              <!-- if  trail plan ended -->
              <ng-container
                *ngIf="isTrailPeriod && item.noOfTrailsDaysLeft <= 0"
              >
                <p
                  class="danger"
                  *ngIf="
                    isTrialSubscription ||
                    (isIndividualCancelled && item.isModuleCancelled)
                  "
                >
                  {{ headerText }} ended on :
                  {{ item.nextBillingDate | date }}
                </p>
                <p
                  class="danger"
                  *ngIf="
                    !isTrialSubscription &&
                    isSubscriptionCancelled &&
                    !isIndividualCancelled
                  "
                >
                  {{ headerText }} ended on :
                  {{ item.nextBillingDate | date }}
                </p>
              </ng-container>

              <!-- if not trail plan -->
              <p *ngIf="!isTrailPeriod && item.isModuleSubscribed">
                {{ item.noOfTrailsDaysLeft }}
                {{ item.noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
                {{ item.selectedPlanName }} | Subscription due:
                {{ item.nextBillingDate | date }}
              </p>

              <p *ngIf="isFullSuitePurchased && fullSuiteNoOfDaysLeft > 0">
                {{ fullSuiteNoOfDaysLeft }}
                {{ item.noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
                Subscription due :
                {{ fullSuiteNextBillingDate | date }}
              </p>

              <p
                *ngIf="
                  !isFullSuitePurchased &&
                  !isTrailPeriod &&
                  !item.isModuleSubscribed
                "
              >
                No Subscription Yet!
              </p>
            </div>

            <div *ngIf="item.showPlans" class="card-foot">
              <div
                *ngIf="!item.isAddedToCart"
                class="card-foot-button d-flex justify-space-between"
              >
                <button
                  (click)="onBackClick(item)"
                  class="action-button text-primary"
                  mat-button
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >arrow_back</mat-icon
                  >
                  <span>Go Back</span>
                </button>

                <div class="connect-button-warapper">
                  <button
                    (click)="addToCart(item)"
                    class="action-button primary-button"
                    mat-button
                    [ngClass]="{
                      'readonly-button ':
                        setAddToCartDisable(item) || isFullSuitePurchased
                    }"
                  >
                    <mat-icon class="m-0">shopping_cart_checkout</mat-icon>
                    <span>Add to cart</span>
                  </button>
                </div>
              </div>

              <!-- if added to cart -->
              <div
                *ngIf="item.isAddedToCart"
                class="cart-button d-flex justify-space-between"
              >
                <button
                  (click)="goToCart()"
                  class="action-button primary-button"
                  mat-button
                >
                  <mat-icon class="m-0">shopping_cart_checkout</mat-icon>
                  <span>Go to cart</span>
                </button>
                <button
                  (click)="removeFromCart(item)"
                  class="action-button close-cart text-primary"
                  mat-button
                >
                  <mat-icon>close</mat-icon>
                  <span>Cancel</span>
                </button>
              </div>
            </div>
            <div *ngIf="!item.showPlans" class="card-foot">
              <button
                class="action-button primary-button gap-20 upgrade-btn"
                mat-button
                disableRipple
                (click)="onUpgradeClick(item)"
              >
                <mat-icon fontSet="material-icons-outlined" class="m-0"
                  >shopping_bag</mat-icon
                >
                <span *ngIf="isTrailPeriod"> Subscribe now</span>
                <span *ngIf="!isTrailPeriod && !item.is5_0Module">
                  Buy Now
                </span>
                <span
                  *ngIf="
                    !isTrailPeriod &&
                    item.is5_0Module &&
                    !item.isModuleSubscribed
                  "
                >
                  Buy Now
                </span>
                <span
                  *ngIf="
                    !isTrailPeriod &&
                    item.is5_0Module &&
                    item.isModuleSubscribed
                  "
                >
                  Upgrade
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
