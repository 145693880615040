import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { CommonService } from '@app/core/services';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ContactFormComponent } from './products';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('sideNav', { static: true }) sideNav: MatDrawer;
  @ViewChild(ContactFormComponent, { static: true }) contact;
  @Output()
  readonly moduleId = new EventEmitter<any>();

  constructor(
    public oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: Document,
    public spinner: NgxSpinnerService,
    public commonService: CommonService
  ) {
    const token = this.oidcSecurityService.getToken();
    if (!token) {
      this.document.location.href = environment.identityLoginUrl;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.commonService.triggerConnectUs.subscribe((value) => {
      if (value) {
        this.sideNav.toggle();
      }

      setTimeout(() => {
        this.moduleId.emit(value);
      }, 100);
    });
  }
  triggerPopup(): void {
    this.sideNav.toggle();
    this.contact.connectWithUsCancelForm();
  }
   closeForm(submitted: boolean): void {    
    if(submitted){
      this.sideNav.close();
    }
  }
}
