import { Injectable } from '@angular/core';
import { BillingHistoryService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  GetBillingHistoryBillDetails,
  GetSmeBillingHistoryBillDetails,
} from './billing-history.action';

export class BillingHistoryStateInfo {
  id?: number;
  billingHistoryData?: Array<any>;
  billingHistoryoffset?: any;
  totalRecord?: number;
}

@State<BillingHistoryStateInfo>({
  name: 'billingHistory',
  defaults: {
    id: 0,
    billingHistoryData: [],
  },
})
@Injectable()
export class BillingHistoryState {
  constructor(private billingHistoryService: BillingHistoryService) {}

  @Selector()
  static totalRecord(state: BillingHistoryStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(GetBillingHistoryBillDetails)
  getBillingHistoryBillDetails(
    { patchState }: StateContext<BillingHistoryStateInfo>,
    action: GetBillingHistoryBillDetails
  ) {
    return this.billingHistoryService
      .getBillingHistoryBillDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            billingHistoryData:
              res.valueResult.transactions.length > 0
                ? res.valueResult.transactions
                : [],
            totalRecord:
              res.valueResult.transactions.length > 0
                ? res.valueResult.transactions.length
                : 0,
          });
        })
      );
  }

  @Action(GetSmeBillingHistoryBillDetails)
  getSmeBillingHistoryBillDetails(
    { patchState }: StateContext<BillingHistoryStateInfo>,
    action: GetBillingHistoryBillDetails
  ) {
    return this.billingHistoryService
      .getSmeBillingHistoryBillDetails(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            billingHistoryData:
              res.valueResult.transactions.length > 0
                ? res.valueResult.transactions
                : [],
            totalRecord:
              res.valueResult.transactions.length > 0
                ? res.valueResult.transactions.length
                : 0,
          });
        })
      );
  }
}
