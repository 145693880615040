export enum InvoiceStatus {
  Paid = 1,
  Posted = 2,
  PaymentDue = 3,
  NotPaid = 4,
  Voided = 5,
  Pending = 6,
}

export enum OldInvoiceStatus {
  Pending = 1,
  Processing = 2,
  Paid = 3,
  Cancelled = 4,
  Failed = 5,
  CarriedForward = 6,
  Gocardlesscancel = 7,
}
