import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  CommonService,
  DummyService,
  NotificationService,
} from '@app/core/services';
import CustomYearPickerComponent from './custom/custom-year-picker/custom-year-picker.component';
import {
  CustomNotificationsComponent,
  CustomPaginatorComponent,
} from './custom';
import { TitleCaseWord, UseEnumValuePipe } from '@app/core/pipes';
import {
  CardFooterComponent,
  ConfirmationBoxComponent,
  NoDataFoundComponent,
} from './common';

@NgModule({
  declarations: [
    CardFooterComponent,
    ConfirmationBoxComponent,
    CustomPaginatorComponent,
    CustomYearPickerComponent,
    CustomNotificationsComponent,
    UseEnumValuePipe,
    TitleCaseWord,
    UseEnumValuePipe,
    NoDataFoundComponent,
  ],

  imports: [
    CommonModule,
    AngularMaterialModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
  ],
  providers: [CommonService, NotificationService, DummyService],
  exports: [
    CardFooterComponent,
    NoDataFoundComponent,
    ConfirmationBoxComponent,
    CustomPaginatorComponent,
    CustomYearPickerComponent,
    CustomNotificationsComponent,
    NgxSpinnerModule,
    TitleCaseWord,
    UseEnumValuePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
