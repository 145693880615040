<div class="product-fullsuite">
  <div class="plan-detail">
    <div class="list plan-list">
      <div class="plan-title mb-1">
        <img alt="Icon" src="assets/img/account-detail.svg" width="90px" />
      </div>
      <div class="plan-dec">Explore all modules at one go</div>
      <div class="plan-icon">
        <img src="assets/img/tandf.svg" alt="logo" />
        <img src="assets/img/ap.svg" alt="logo" />
        <img src="assets/img/cs.svg" alt="logo" />
        <img src="assets/img/bk.svg" alt="logo" />
        <img src="assets/img/capidrive.svg" alt="logo" />
        <img src="assets/img/payroll.svg" alt="logo" />
      </div>
      <a class="plan-client pb-5" (click)="onManyMoreClick()">Capium Pricing</a>
    </div>
    <div class="list plan-list" *ngFor="let item of productDetailData$ | async">
      <div *ngIf="item.plan.itemId === selectedPlanId" class="current-paln">
        Current Plan
      </div>
      <div *ngIf="item.plan.externalName === 'Medium'" class="popular-wrap">
        <div class="popular-paln">Popular</div>
      </div>

      <div
        class="plan-title d-flex align-items-center justify-content-center gap-10"
      >
        <img
          *ngIf="item.plan.externalName === planName.small"
          alt="Icon"
          src="assets/img/simple.svg"
        />
        <img
          *ngIf="item.plan.externalName === planName.medium"
          alt="Icon"
          src="assets/img/medium.svg"
        />
        <img
          *ngIf="item.plan.externalName === planName.large"
          alt="Icon"
          src="assets/img/large.svg"
        />
        {{ item.plan.externalName }} Plan
      </div>
      <div class="plan-amount">
        £
        <span class="font-size-24 fw-bold">{{ item.planData.vatMonth }}</span> +
        VAT /
        {{
          frequency === periodUnitType.Month
            ? "Month"
            : frequency === periodUnitType.Year
            ? "Year"
            : "Quarterly"
        }}
      </div>
      <div class="plan-dec">
        ({{ item.planData.vatClient }} + VAT per client) for the full Accounting
        suite
      </div>
      <div
        class="plan-client d-flex align-items-center justify-content-center gap-10"
      >
        <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
        Up to {{ item.planData.cf_clients }} Clients
      </div>
      <div class="plan-button mt-10 connect-button-warapper">
        <button
          [ngClass]="{
            'readonly-button lock-button':
              item.plan.itemId < selectedPlanId && selectedPlanId !== ''
          }"
          (click)="onBuyNowClick(item.plan.itemId, false)"
          *ngIf="
            isTrailPeriod ||
            selectedPlanId === '' ||
            item.plan.itemId < selectedPlanId
          "
          class="action-button primary-button"
          mat-button
          disableRipple
        >
          Buy now
        </button>

        <button
          (click)="onConnectClick()"
          *ngIf="item.plan.itemId === selectedPlanId"
          class="action-button primary-button"
          mat-button
          disableRipple
        >
          Connect
        </button>

        <button
          (click)="onBuyNowClick( item.plan.itemId, true)"
          *ngIf="
            item.plan.itemId > selectedPlanId &&
            selectedPlanId !== '' &&
            !isTrailPeriod
          "
          class="action-button primary-button"
          mat-button
          disableRipple
        >
          Upgrade
        </button>

        <p
          class="pt-1 text-primary"
          *ngIf="item.plan.itemId === selectedPlanId"
        >
          {{ noOfTrailsDaysLeft }}
          {{ noOfTrailsDaysLeft == 1 ? "day" : "days" }} left | Subscription due
          :
          {{ nextBillingDate | date }}
        </p>

        <p class="pt-1 danger" *ngIf="isTrailPeriod && noOfTrailsDaysLeft > 0">
          {{ noOfTrailsDaysLeft }}
          {{ noOfTrailsDaysLeft == 1 ? "day" : "days" }} left |
          {{ headerText }} ends on :
          {{ (isTrialSubscription ? trialEndDate : nextBillingDate) | date }}
        </p>
        <ng-container *ngIf="isTrailPeriod && noOfTrailsDaysLeft <= 0">
          <p
            class="pt-1 danger"
            *ngIf="!isTrialSubscription && item.plan.itemId === CancelledPlanId"
          >
            {{ headerText }} ended on :
            {{ nextBillingDate | date }}
          </p>
          <p class="pt-1 danger" *ngIf="isTrialSubscription">
            {{ headerText }} ended on :
            {{ nextBillingDate | date }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="plan-detail">
    <div class="list">
      <p class="d-flex align-items-center gap-5">
        <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
        Unlimited User Licences
      </p>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
  </div>
  <div class="plan-detail">
    <div class="list">
      <p class="d-flex align-items-center gap-5">
        <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
        Unlimited E-Sign
      </p>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
  </div>
  <div class="plan-detail">
    <div class="list">
      <p class="d-flex align-items-center gap-5">
        <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
        Unlimited SMS
      </p>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
  </div>
  <div class="plan-detail">
    <div class="list">
      <p class="d-flex align-items-center gap-5">
        <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
        Unlimited Storage
      </p>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
  </div>
  <div class="plan-detail">
    <div class="list">
      <p class="d-flex align-items-center gap-5">
        <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon> MTD
        and GDPR compliant
      </p>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
    <div class="list text-align-center">
      <mat-icon class="success">done</mat-icon>
    </div>
  </div>
</div>
