import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ConfirmationType,
  AddOnsName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  PeriodUnitType,
  PlanName,
  SubscriptionsEnum,
  PeriodUnitText,
  Modules_5_0,
  PeriodUnitTextShort,
  SubscribtionsType,
  Modules_3_0,
  PurchasedEnum,
} from '@app/core/enums';
import { GlobalComponent } from '@app/core/models';
import { CommonService, LocalStorageService } from '@app/core/services';
import {
  GetIndividualProductList,
  ProductState,
  SetProductDetailAcrdngPlan,
  SubscriptionPortalState,
} from '@app/core/store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConfirmationBoxComponent } from '@app/core/sharedmodules';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product-individual',
  templateUrl: './product-individual.component.html',
  styleUrls: ['./product-individual.component.scss'],
})
export class ProductIndividualComponent implements OnInit {
  @Output()
  readonly toggleReviewOrder = new EventEmitter<any>();

  @Input()
  subscribtionsType: number;

  @Select(ProductState.productDetailIndividualData)
  productDetailIndividualData$: Observable<any>;

  @Select(SubscriptionPortalState.subscriptionPortalData)
  subscriptionPortalData$: Observable<Array<any>>;

  @Select(ProductState.connectLinkList)
  connectLinkList$: Observable<Array<any>>;

  productIndividualList: Array<any> = [];
  selectedSubscriptionList: Array<any> = [];
  planList: Array<any>;

  isAddedToCart: boolean;
  isTrailPeriod: boolean;
  isTrialSubscription: boolean;
  isIndividualPurchased = false;
  isIndividualCancelled = false;
  isFullSuitePurchased = false;
  isSubscriptionCancelled = false;
  fullSuiteNextBillingDate: any;
  fullSuiteNoOfDaysLeft: any;

  environment = environment;
  addOnsName = AddOnsName;
  headerText = '';
  connectLinkList: any;

  @Input()
  frequency: number;
  trialEndDate: Date;

  constructor(
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService,
    public commonService: CommonService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private store: Store
  ) {}

  ngOnInit(): void {
    // console.log(this.frequency);
    // if (this.subscribtionsType === SubscribtionsType.Indidvidual) {
    //   this.getSubscriptionData();

    //   this.connectLinkList$.subscribe((x) => {
    //     this.connectLinkList = x;
    //   });
    // }
  }

  getSubscriptionData(): void {
    this.subscriptionPortalData$.subscribe((res) => {
      let subscriptionList = res;

      if (subscriptionList.length > 0) {
        this.isFullSuitePurchased = subscriptionList.some((item: any) => {
          return (
            (+item.itemPrice.itemId === +SubscriptionsEnum.fullSuiteMicroBite ||
              +item.itemPrice.itemId === +SubscriptionsEnum.fullSuiteMicro ||
              +item.itemPrice.itemId === +SubscriptionsEnum.fullSuiteSmall ||
              +item.itemPrice.itemId === +SubscriptionsEnum.fullSuiteMedium ||
              +item.itemPrice.itemId === +SubscriptionsEnum.fullSuiteLarge ||
              +item.itemPrice.itemId ===
                +SubscriptionsEnum.fullSuiteEnterprise) &&
            item.status !== +PurchasedEnum.Cancelled
          );
        });

        this.isTrailPeriod = subscriptionList.some((item: any) => {
          const isTrue =
            +item.itemPrice.itemId === +SubscriptionsEnum.trail ||
            +item.status === +PurchasedEnum.Cancelled;

          if (+item.itemPrice.itemId === +SubscriptionsEnum.trail) {
            this.headerText = 'Trial';
            this.isTrialSubscription = true;
          }
          if (
            +item.status === +PurchasedEnum.Cancelled &&
            parseInt(item.itemPrice.itemId) !== +SubscriptionsEnum.trail
          ) {
            this.isSubscriptionCancelled = true;
            this.headerText = 'Subscription';
          }

          return isTrue;
        });

        if (this.isFullSuitePurchased) {
          this.fullSuiteNextBillingDate = this.commonService.getNextBillingDate(
            this.isTrailPeriod,
            this.isSubscriptionCancelled,
            new Date(res[0].nextBillingDate),
            res
          );

          this.fullSuiteNoOfDaysLeft = this.commonService.getNoOfDays(
            this.isTrailPeriod,
            this.isSubscriptionCancelled,
            new Date(res[0].nextBillingDate),
            res
          );
        }
        let endDate = res[0].endDate;
        const words = endDate.split('T');
        if (words.length) {
          this.trialEndDate = new Date(words[0]);
        }

        //we can have one sub at one time, either  Individual or Trail
        this.isIndividualPurchased = subscriptionList.some((item: any) => {
          return (
            +item.itemPrice.itemId === +SubscriptionsEnum.individual &&
            +item.status !== +PurchasedEnum.Cancelled
          );
        });

        this.isIndividualCancelled = subscriptionList.some((item: any) => {
          return (
            +item.itemPrice.itemId === +SubscriptionsEnum.individual &&
            +item.status === +PurchasedEnum.Cancelled
          );
        });

        this.selectedSubscriptionList = subscriptionList.filter((item) => {
          return (
            +item.itemPrice.itemId !== +SubscriptionsEnum.individual ||
            +item.itemPrice.itemId === +SubscriptionsEnum.trail
          );
        });
      }
      this.spinner.show();
      this.store.dispatch(new GetIndividualProductList("1", this.frequency)).subscribe((x) => {
        console.log(x.product.productDetailIndividualData)
        this.getList(x.product.productDetailIndividualData);
      });
      // this.productDetailIndividualData$.subscribe((x) => {
      //   this.getList(x);
      // });
    });
  }

  getList(x?: any): any {
    this.productIndividualList = [];

    if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
      if (
        this.storage
          .get('cartDetails')
          .some((e) => e.subscribtionsType === SubscribtionsType.Indidvidual)
      ) {
        this.productIndividualList = this.storage.get('cartDetails');
        this.commonService.storeCartItemDataLocally(this.productIndividualList);
      } else {
        this.getIndividualList(x);
      }
    } else {
      this.getIndividualList(x);
    }
  }

  getIndividualList(x): void {
    x.addons.forEach((y) => {
      this.planList = [];

      let selectedPlanId = 1;
      let isModuleSubscribed = false;
      let isModuleCancelled = false;
      let selectedPlanIdFor30Modules;
      let subscriptionList;
      let selectedRange = '';

      let is5_0Module = Object.values(Modules_5_0)?.includes(y.item.id);

      if (this.isIndividualPurchased) {
        this.selectedSubscriptionList.forEach((element) => {
          if (element.itemPrice.id === y.item.id) {
            isModuleSubscribed = true;
            selectedPlanId = element.tier;
            //To set subscriptionList of the purchased subscribtion
            subscriptionList = element;
          }
        });

        //To set same Plan Id of other 3.0 modules's Plan Id if any 3.0 modules is purchased
        if (!Object.values(Modules_5_0)?.includes(y.item.id)) {
          this.selectedSubscriptionList.forEach((element) => {
            if (!Object.values(Modules_5_0)?.includes(element.itemPrice.id)) {
              selectedPlanId = element.tier;
              selectedPlanIdFor30Modules = selectedPlanId;
            }
          });
        }
      }

      if (this.isIndividualCancelled) {
        this.selectedSubscriptionList.forEach((element) => {
          if (element.itemPrice.id === y.item.id) {
            isModuleCancelled = true;
            // selectedPlanId = element.tier;
            //To set subscriptionList of the purchased subscribtion
            // subscriptionList = element;
          }
        });
      }

      y.item.tiers.forEach((z, i) => {
        if (selectedPlanId === z.startingUnit) {
          if (
            y.item.id === AddOnsName.Charity ||
            y.item.id === AddOnsName.CharityYearly ||
            y.item.id === AddOnsName.CharityQuarterly
          ) {
            selectedRange = `${z.startingUnit} - ${
              z.endingUnit === null ? '100' : z.endingUnit
            }`;
          } else {
            selectedRange = `${z.startingUnit} - ${
              z.endingUnit === null ? 'unlimited' : z.endingUnit
            }`;
          }
        }

        this.planList.push({
          price: z.price,
          planName: this.getPlanName(y.item.id, i),
          startingUnit: z.startingUnit,
          clientsRange: is5_0Module
            ? this.getClientsRange(y.item.id, z.startingUnit, z.endingUnit)
            : 'NA',
          disablePlan: this.disablePlanSelection(
            is5_0Module,
            subscriptionList,
            selectedPlanIdFor30Modules,
            z.startingUnit
          ),
        });
      });

      let headerText;
      if (this.frequency === PeriodUnitType.Month) {
        headerText = PeriodUnitText.Month;
      } else if (this.frequency === PeriodUnitType.Year) {
        headerText = PeriodUnitText.Year;
      } else {
        headerText = PeriodUnitText.Quarterly;
      }

      this.productIndividualList.push({
        lineItems1: y.cardContent.split('\n')[0],
        lineItems2: y.cardContent.split('\n')[1],
        lineItems3: y.cardContent.split('\n')[2],

        externalName: y.item.externalName.replace(/individual|new/gi, '').trim(),
        description: y.item.description === null ? 'Addons' : y.description,
        planList: this.planList,
        itemId: y.item.itemId,
        planId: x.plan.id,
        frequencyText: this.getFrequencyText(),
        addOnsId: y.item.id,
        selectedPlanId: selectedPlanId,
        selectedPlanName: this.getSelectedPlanName(
          isModuleSubscribed,
          selectedPlanId,
          this.planList
        ),
        selectedClientRange: selectedRange,
        noOfTrailsDaysLeft: this.commonService.getNoOfDays(
          this.isTrailPeriod,
          this.isSubscriptionCancelled,
          subscriptionList !== undefined ? subscriptionList.nextBillingDate : 0,
          this.selectedSubscriptionList
        ),
        nextBillingDate: this.commonService.getNextBillingDate(
          this.isTrailPeriod,
          this.isSubscriptionCancelled,
          subscriptionList !== undefined
            ? subscriptionList.nextBillingDate
            : new Date(),
          this.selectedSubscriptionList
        ),
        subscriptionList: subscriptionList,
        is5_0Module: is5_0Module,
        isModuleSubscribed: isModuleSubscribed,
        isModuleCancelled: isModuleCancelled,
        showPlans: false,
        isAddedToCart: false,
        headerText: headerText, //needed in order page
        isTrailPeriod: this.isTrailPeriod, //needed in order page
        planData: x.planData, //needed in order page
        isIndividualPurchased: this.isIndividualPurchased, //needed in order page
        isIndividualCancelled: this.isIndividualCancelled,
        subscribtionsType: this.subscribtionsType, //needed in order page
      });
      isModuleSubscribed = false;
    });
    console.log(this.productIndividualList);
  }
  getFrequencyText(): string {
    if (this.frequency === PeriodUnitType.Month) {
      return PeriodUnitTextShort.Month;
    } else if (this.frequency == PeriodUnitType.Year) {
      return PeriodUnitTextShort.Year;
    } else {
      return PeriodUnitTextShort.Quarterly;
    }
  }
  getSelectedPlanName(
    isModuleSubscribed,
    selectedPlanId: number,
    planList: any
  ) {
    let planName;
    if (isModuleSubscribed) {
      planName = planList.find(
        (e) => e.startingUnit === +selectedPlanId
      ).planName;
    }
    return planName;
  }

  onPlanChange(selectedPlanvalue: string, item: any) {
    item.selectedPlanId = +selectedPlanvalue;
  }

  setAddToCartDisable(item: any): boolean {
    let isDisbaleAddToCart = false;
    if (item.subscriptionList !== undefined && !this.isSubscriptionCancelled) {
      isDisbaleAddToCart = item.selectedPlanId === item.subscriptionList.tier;
    }

    return isDisbaleAddToCart;
  }

  setPlanSelectedId(item: any): void {
    this.productIndividualList.forEach((element) => {
      if (!item.is5_0Module && !element.is5_0Module) {
        element.selectedPlanId = item.selectedPlanId;
      }
    });
  }

  disablePlanSelection(
    is5_0Module: boolean,
    subscriptionList: any,
    selectedPlanIdFor30Modules,
    startingUnit: any
  ): boolean {
    let disablePlan = false;
    if (!this.isTrailPeriod && this.selectedSubscriptionList.length !== 0) {
      // Disable the lower plans
      if (subscriptionList !== undefined && is5_0Module) {
        disablePlan = startingUnit < subscriptionList.tier;
      }
      // Set all plan ids disable For all the 3.0 modules which is not purchased
      else if (!is5_0Module && selectedPlanIdFor30Modules !== undefined) {
        disablePlan = startingUnit !== selectedPlanIdFor30Modules;
      }
    }

    return disablePlan;
  }

  getPlanName(addonId: string, index: number): any {
    if (
      addonId === AddOnsName.Charity ||
      addonId === AddOnsName.CharityYearly ||
      addonId === AddOnsName.CharityQuarterly
    ) {
      switch (index) {
        case 0:
          return PlanName.small;
        case 1:
          return PlanName.medium;
        case 2:
          return PlanName.large;
        case 3:
          return PlanName.enterprise;
        case 4:
          return PlanName.xEnterprise;
        default:
          return PlanName.small;
      }
    } else {
      switch (index) {
        case 0:
          return PlanName.small;
        case 1:
          return PlanName.medium;
        case 2:
          return PlanName.large;
        case 3:
          return PlanName.enterprise;
        default:
          return PlanName.small;
      }
    }
  }

  getClientsRange(id: string, startingUnit: any, endingUnit: any) {
    let clientsRange = '';

    if (endingUnit !== null) {
      clientsRange = `${startingUnit} -  ${endingUnit}`;
    } else {
      clientsRange ='unlimited';       
    }

    return clientsRange;
  }

  goToCart(): void {
    let isPlanUpgraded = this.selectedSubscriptionList.length > 0;

    this.store.dispatch(new SetProductDetailAcrdngPlan()).subscribe();
    this.commonService.storeCartItemDataLocally(this.productIndividualList);
    this.toggleReviewOrder.emit(isPlanUpgraded);
  }

  onUpgradeClick(item: any): void {
    item.showPlans = true;
  }

  onBackClick(item: any): void {
    item.showPlans = false;
  }

  setDisablePlan(item: any, isDisable: boolean, isAddToCart): void {
    if (!item.is5_0Module) {
      this.productIndividualList.forEach((element) => {
        if (!element.is5_0Module) {
          //Set Plan Id and Disable other plans apart from that plan id for the all modules
          element.selectedPlanId = item.selectedPlanId;
          element.planList.forEach((y) => {
            //If remove from cart clicked
            if (!isAddToCart) {
              //To Disable plan of other 3.0 when remove from cart is done in case if any 3.0 is already purchased
              if (
                element.subscriptionList === undefined &&
                this.selectedSubscriptionList.length > 0
              ) {
                y.disablePlan = y.startingUnit !== item.selectedPlanId;
              }

              //To enable plans of 3.0 if any 3.0 module is not added to the purchased
              if (this.selectedSubscriptionList.length === 0) {
                y.disablePlan = isDisable;
              }
            } else {
              y.disablePlan = isDisable;
            }
          });
        }
      });
    } else {
      this.productIndividualList.forEach((element) => {
        //Disable other plans apart from that plan id for the selected module
        if (element.is5_0Module && item.addOnsId === element.addOnsId) {
          element.planList.forEach((y) => {
            y.disablePlan = isAddToCart
              ? isDisable
              : element.subscriptionList !== undefined
              ? y.startingUnit < item.subscriptionList.tier
              : false;
          });
        }
      });
    }
  }

  addToCart(item: any): void {
    this.storage.set('fullSuiteAddonList', null);
    this.storage.set('isUpgrade', false);
    this.storage.set('subscribtionsType', this.subscribtionsType);
    item.isAddedToCart = true;
    this.setDisablePlan(item, true, true);

    this.commonService.storeCartItemDataLocally(this.productIndividualList);
  }

  onConnectClick(item?: any): void {
    let redirection = this.connectLinkList.find(
      (e) => e.individualAddonId === item.itemId
    ).demoVideoUrl;

    const url =
      redirection === undefined || redirection === null
        ? environment.stsServerUrl
        : redirection;

    window.open(url, '_blank');
  }

  removeFromCart(item: any): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          type: ConfirmationType.Delete,
          ids: item.id,
          moduleId: Modules.Products,
          headerText: NotificationHeader.removeFromCart,
          detailText: NotificationDetails.terminateDetailText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          item.isAddedToCart = false;
          this.setDisablePlan(item, false, false);
          this.commonService.storeCartItemDataLocally(
            this.productIndividualList
          );

          let cartDetailList = 0;
          let is3_0ModulePurchased;

          if (Object.keys(this.storage.get('cartDetails')).length !== 0) {
            cartDetailList = this.storage.get('cartDetails').filter((obj) => {
              return !obj.is5_0Module && obj.isAddedToCart;
            }).length;
          }

          this.selectedSubscriptionList.forEach((obj) => {
            if (Object.values(Modules_3_0)?.includes(obj.itemPrice.id)) {
              is3_0ModulePurchased = true;
            }
          });

          //To all the enable the plans of 3.0 if cart is empty
          this.productIndividualList.forEach(function (obj) {
            if (!obj.is5_0Module) {
              obj.planList.map(function (o) {
                o.disablePlan =
                  cartDetailList === 0 && !is3_0ModulePurchased ? false : true;
              });
            }
          });
        }
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    
    if(changes['frequency']){
      console.log(this.frequency);
      if (this.subscribtionsType === SubscribtionsType.Indidvidual) {
        this.getSubscriptionData();
  
        this.connectLinkList$.subscribe((x) => {
          this.connectLinkList = x;
        });
      }
    }
  }
}
