import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss'],
})
export class InvoicePreviewComponent implements OnInit {
  previewData: any;
  nextBillingDate: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InvoicePreviewComponent>
  ) {}

  ngOnInit(): void {
    this.previewData = this.data.invoiceList.invoice;
    this.nextBillingDate = new Date(
      this.previewData.line_items[0].date_to * 1000
    );
    this.nextBillingDate.setDate(this.nextBillingDate.getDate());
  }
}
