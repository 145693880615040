import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  Capium365,
  CapiumAddons,
  NotificationHeader,
  NotificationTextMessage,
  PeriodUnitType,
  RoutingPath,
  SubscribtionsType,
  recommendedModules,
} from '@app/core/enums';
import { GlobalComponent, PayNowModel } from '@app/core/models';
import {
  CommonService,
  LocalStorageService,
  NotificationService,
} from '@app/core/services';
import {
  PayNowForSubscribtion,
  ProductState,
  SetProductDetailAcrdngPlan,
  SubscriptionPortalState, 
  UpgradePlanForSubscribtion, 
  PayNowForSmeSubscription,
} from '@app/core/store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-review-order',
  templateUrl: './product-review-order.component.html',
  styleUrls: ['./product-review-order.component.scss'],
})
export class ProductReviewOrderComponent implements OnInit {
  headerText = '';
  planPriceId = '';
  selectedPlanId = '';
  subscriptionId = '';
  isContractTermsChecked:boolean;

  subTotal = 0;
  total = 0;
  addtionalDiscount = 0;

  productAddOnList: Array<any> = [];

  charityClients: number = 0;
  timenFeeClients: number = 0;
  payNowData: PayNowModel;
  subscribtionsTypeEnum = SubscribtionsType;
  recommendedModules = recommendedModules;

  @Select(ProductState.productDetailAccordingPlan)
  productDetailAccordingPlan$: Observable<any>;

  @Select(SubscriptionPortalState.subscriptionPortalData)
  subscriptionPortalData$: Observable<Array<any>>;

  planData: any;

  @Output()
  readonly toggleReviewOrder = new EventEmitter<any>();

  @Input()
  isPlanUpgraded: boolean;

  @Input()
  frequency: number;

  @Input()
  isSME: boolean;

  @Input()
  subscribtionsType: number;

  isBankFeed = false;
  isOnlyBankFeed=false;

  constructor(
    private store: Store,
    public commonService: CommonService,
    private router: Router,
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService,
    private spinner: NgxSpinnerService,
    private notifier: NotificationService
  ) {
    if (
      this.storage.get('subscribtionsType') === SubscribtionsType.FullSuite &&
      this.storage.get('fullSuiteAddonList') &&
      !this.isSME
    ) {
      let ar: any = [];
      ar = this.storage.get('fullSuiteAddonList');
      this.globalComponent.totalCartItem.next(ar.addons.length);
      this.store
        .dispatch(
          new SetProductDetailAcrdngPlan(this.storage.get('fullSuiteAddonList'))
        )
        .subscribe();
      this.toggleReviewOrder.emit(this.storage.get('isUpgrade'));
    }
  }

  ngOnInit(): void {
    this.isSME = this.storage.get('isSME');
    if (this.frequency == undefined) {
      this.frequency = this.storage.get('frequency');
    }
    console.log(this.isSME);
    if (!this.isSME) {
      this.productDetailAccordingPlan$?.subscribe((data) => {
        //full suit
        console.log(this.subscribtionsType);
        if (this.subscribtionsType === undefined) {
          this.subscribtionsType = this.storage.get('subscribtionsType');
        }
        if (this.subscribtionsType === SubscribtionsType.FullSuite) {
          this.planData = data.planData;

          this.productAddOnList = data.addons.map((addon) => addon.item);
          console.log(this.productAddOnList);
          this.headerText = `(Full Suite ${data.plan.externalName}  ${data.planData.cf_clients} Clients)`;
          this.planPriceId = data.plan.id;
          this.selectedPlanId = data.plan.itemId;
          this.getSubscriptionData();
        } else if (
          Object.keys(this.storage.get('cartDetails') !== 0) &&
          this.subscribtionsType === SubscribtionsType.Indidvidual
        ) {
          this.productAddOnList = [];
          this.spinner.hide();
          this.storage.get('cartDetails').forEach((x) => {
            this.subscribtionsType = x.subscribtionsType;

            if (x.subscriptionList !== undefined) {
              this.subscriptionId = x.subscriptionList.subscriptionId;
              this.storage.set('subscriptionId', this.subscriptionId);
            }
            if (x.isTrailPeriod) {
              this.getSubscriptionData();
            }

            this.headerText = x.headerText;
            this.planPriceId = x.planId;
            this.planData = x.planData;

            if (x.isAddedToCart) {
              this.isPlanUpgraded =
                this.subscribtionsType === SubscribtionsType.Indidvidual
                  ? x.isIndividualPurchased
                  : x.isModuleSubscribed;

              x.planList.forEach((element) => {
                if (x.selectedPlanId === element.startingUnit) {
                  this.productAddOnList.push({
                    externalName: x.externalName,
                    planName: element.planName,
                    clientsRange: element.clientsRange,
                    price: element.price,
                    planId: x.planId,
                    addOnsId: x.addOnsId,
                    startingUnit: element.startingUnit,
                    is5_0Module: x.is5_0Module,
                    subscriptionList: x.subscriptionList,
                    selectedPlanId: x.selectedPlanId,
                  });
                }
              });

              if (
                x.planList.length === 0 &&
                (x.addOnsId === CapiumAddons.BankFeeds ||
                  x.addOnsId === CapiumAddons.BankFeedsYearly ||
                  x.addOnsId === CapiumAddons.BankFeedsQuarterly)
              ) {
                this.isBankFeed = true;
                this.isOnlyBankFeed=true;

                this.productAddOnList.push({
                  externalName: x.externalName,
                  planName: '',
                  clientsRange: x.noOfBankFeed,
                  price: x.bankFeedPrice * x.noOfBankFeed,
                  planId: x.planId,
                  addOnsId: x.addOnsId,
                  startingUnit: x.bankFeedPrice,
                  is5_0Module: x.is5_0Module,
                  subscriptionList: x.subscriptionList,
                  selectedPlanId: x.selectedPlanId,
                });
              }
            }
          });

          this.globalComponent.totalCartItem.next(this.productAddOnList.length);
        } else if (
          Object.keys(this.storage.get('cartDetails') !== 0) &&
          this.subscribtionsType === SubscribtionsType.CapiumAddons
        ) {
          this.productAddOnList = [];
          this.spinner.hide();
          this.storage.get('cartDetails').forEach((x) => {
            this.subscribtionsType = x.subscribtionsType;

            if (x.isAddedToCart) {
              this.headerText = x.headerText;
              this.planPriceId = x.planId;
              this.frequency = x.frequency;

              this.isPlanUpgraded =
                this.subscribtionsType === SubscribtionsType.Indidvidual
                  ? x.isIndividualPurchased
                  : x.isModuleSubscribed;

              let planList;
              if (parseInt(x.frequency) === PeriodUnitType.Year) {
                planList = x.planListYearly;
              } else if (parseInt(x.frequency) === PeriodUnitType.Month) {
                planList = x.planListMonth;
              } else {
                planList = x.planListQuarterly;
              }

              planList.forEach((element) => {
                if (x.selectedPlanId === element.startingUnit) {
                  this.productAddOnList.push({
                    externalName: x.externalName,
                    planName: element.planName,
                    clientsRange: element.clientsRange,
                    price: element.price,
                    planId: x.planId,
                    addOnsId: x.addOnsId,
                    startingUnit: element.startingUnit,
                    is5_0Module: x.is5_0Module,
                    subscriptionList: x.subscriptionList,
                    selectedPlanId: x.selectedPlanId,
                    subscriptionId:
                      x.subscriptionList !== undefined
                        ? x.subscriptionList.subscriptionId
                        : '',
                  });
                }
              });

              if (
                planList.length === 0 &&
                (x.addOnsId === CapiumAddons.BankFeeds ||
                  x.addOnsId === CapiumAddons.BankFeedsYearly ||
                  x.addOnsId === CapiumAddons.BankFeedsQuarterly)
              ) {
                this.isBankFeed = true;
                this.isOnlyBankFeed=true;

                this.productAddOnList.push({
                  externalName: x.externalName,
                  planName: '',
                  clientsRange: x.noOfBankFeed,
                  price: x.bankFeedPrice * x.noOfBankFeed,
                  planId: x.planId,
                  addOnsId: x.addOnsId,
                  startingUnit: x.bankFeedPrice,
                  is5_0Module: x.is5_0Module,
                  subscriptionList: x.subscriptionList,
                  selectedPlanId: x.selectedPlanId,
                  subscriptionId:
                    x.subscriptionList !== undefined
                      ? x.subscriptionList.subscriptionId
                      : '',
                });
              }

              if (
                this.productAddOnList[0] !== undefined &&
                x.subscriptionList !== undefined
              ) {
                this.subscriptionId = this.productAddOnList[0].subscriptionId;
                this.storage.set('subscriptionId', this.subscriptionId);
              }
            }
          });

          this.globalComponent.totalCartItem.next(this.productAddOnList.length);
        }
        else if (
          Object.keys(this.storage.get('cartDetails') !== 0) &&
          this.subscribtionsType === SubscribtionsType.Capium365
        ) {
          this.productAddOnList = [];
          this.spinner.hide();
          this.storage.get('cartDetails').forEach((x) => {
            this.subscribtionsType = x.subscribtionsType;

            if (x.isAddedToCart) {
              this.headerText = x.headerText;
              this.planPriceId = x.planId;
              this.frequency = x.frequency;

              this.isPlanUpgraded =
                this.subscribtionsType === SubscribtionsType.Indidvidual
                  ? x.isIndividualPurchased
                  : x.isModuleSubscribed;

              let planList;
              if (parseInt(x.frequency) === PeriodUnitType.Year) {
                planList = x.planListYearly;
              } else if (parseInt(x.frequency) === PeriodUnitType.Month) {
                planList = x.planListMonth;
              } else {
                planList = x.planListQuarterly;
              }

              planList.forEach((element) => {
                if (x.selectedPlanId === element.startingUnit) {
                  this.productAddOnList.push({
                    externalName: x.externalName,
                    planName: element.planName,
                    clientsRange: element.clientsRange,
                    price: element.price,
                    planId: x.planId,
                    addOnsId: x.addOnsId,
                    startingUnit: element.startingUnit,
                    is5_0Module: x.is5_0Module,
                    subscriptionList: x.subscriptionList,
                    selectedPlanId: x.selectedPlanId,
                    subscriptionId:
                      x.subscriptionList !== undefined
                        ? x.subscriptionList.subscriptionId
                        : '',
                  });
                }
              });

              if (
                planList.length === 0 &&
                (x.addOnsId === Capium365._365LicensesGBPMonthly ||
                  x.addOnsId ===Capium365._365LicensesGBPQuarterly ||
                  x.addOnsId === Capium365._365LicensesGBPYearly)
              ) {
                this.isBankFeed = true;

                this.productAddOnList.push({
                  externalName: x.externalName,
                  planName: '',
                  clientsRange: x.noOfLicences,
                  price: x._365LicensesPrice * x.noOfLicences,
                  planId: x.planId,
                  addOnsId: x.addOnsId,
                  startingUnit: x._365LicensesPrice,
                  is5_0Module: x.is5_0Module,
                  subscriptionList: x.subscriptionList,
                  selectedPlanId: x.selectedPlanId,
                  subscriptionId:
                    x.subscriptionList !== undefined
                      ? x.subscriptionList.subscriptionId
                      : '',
                });
              }

              if (
                this.productAddOnList[0] !== undefined &&
                x.subscriptionList !== undefined
              ) {
                this.subscriptionId = this.productAddOnList[0].subscriptionId;
                this.storage.set('subscriptionId', this.subscriptionId);
              }
            }
          });

          this.globalComponent.totalCartItem.next(this.productAddOnList.length);
        }
      });
      this.calTotal();
    } else {
      this.subscribtionsType=0;
      this.productAddOnList = [];
      this.spinner.hide();
      this.storage.get('cartDetails').forEach((x) => {
        if (x.isAddedToCart) {
          this.headerText = x.headerText;
          this.planPriceId = x.planId;
          this.isPlanUpgraded = false;

          this.productAddOnList.push({
            externalName: x.externalName,
            planName: 'Capium365',
            clientsRange: '',
            price: x.price,
            planId: x.planId,
            addOnsId: x.addOnsId,
            startingUnit: 0,
            is5_0Module: x.is5_0Module,
            subscriptionList: x.subscriptionList,
            selectedPlanId: x.selectedPlanId,
            subscriptionId:
              x.subscriptionList !== undefined
                ? x.subscriptionList.subscriptionId
                : '',
          });
        }
      });
      console.log(this.productAddOnList);
      this.globalComponent.totalCartItem.next(this.productAddOnList.length);
      this.calTotal();
    }
   
  }
  get readonlyButtonClass() { 
    if(!this.isPlanUpgraded&&!this.isOnlyBankFeed)  {
      return this.isContractTermsChecked  ? '' : 'cusrsor-not-allowed';
    }else{
      return '';
    }
    
  }
  onContract(event: any) {
    const isChecked = event.checked;
    this.isContractTermsChecked=isChecked;
  }
  getSubscriptionData(): void {
    this.subscriptionPortalData$.subscribe((res) => {
      this.subscriptionId = res[0].subscriptionId;
      this.storage.set('subscriptionId', res[0].subscriptionId);
    });
  }

  removeFromCart(item: any, i: number): void {
    if (this.subscribtionsType === SubscribtionsType.CapiumAddons) {
      this.storage.removeItem('cartDetails');
      this.globalComponent.totalCartItem.next(0);
      this.productAddOnList = [];
    } else if (this.subscribtionsType === SubscribtionsType.FullSuite) {
      this.productAddOnList.splice(i, 1);
      this.calTotal();
    } else {
      let cartDetailList = this.storage.get('cartDetails');

      this.productAddOnList.forEach((element, index) => {
        if (element.addOnsId == item.addOnsId)
          this.productAddOnList.splice(index, 1);
      });

      if (Object.keys(this.storage.get('cartDetails') !== 0)) {
        if (!item.is5_0Module) {
          cartDetailList.forEach((element) => {
            if (!element.is5_0Module) {
              element.selectedPlanId = item.selectedPlanId;
              element.planList.forEach((y) => {
                if (element.subscriptionList === undefined) {
                  y.disablePlan = y.startingUnit !== item.selectedPlanId;
                } else {
                  y.disablePlan = false;
                }
              });

              if (item.addOnsId === element.addOnsId)
                element.isAddedToCart = false;
            }
          });
        } else {
          cartDetailList.forEach((element) => {
            if (element.is5_0Module && item.addOnsId === element.addOnsId) {
              element.planList.forEach((y) => {
                y.disablePlan =
                  element.subscriptionList !== undefined
                    ? y.startingUnit < item.subscriptionList.tier
                    : false;
              });
              element.isAddedToCart = false;
            }
          });
        }
      }

      const cartDetail = cartDetailList.filter((obj) => {
        return !obj.is5_0Module && obj.isAddedToCart;
      });

      //To all the enable the plans of 3.0 if cart is empty
      cartDetailList.forEach(function (obj) {
        if (!obj.is5_0Module) {
          obj.planList.map(function (o) {
            o.disablePlan =
              cartDetail.length === 0 && !obj.isIndividualPurchased
                ? false
                : true;
          });
        }
      });

      if (this.productAddOnList.length === 0) {
        this.onContinueShopping();
      }

      this.commonService.storeCartItemDataLocally(cartDetailList);
      this.calTotal();
    }
  }

  calTotal(): void {
    this.subTotal = 0;

    this.productAddOnList.forEach((x) => {
      this.subTotal = +(+this.subTotal + +x.price / 100).toFixed(2);
    });

    this.total = +(+this.subTotal - +this.addtionalDiscount).toFixed(2);
  }

  dataSubmitForIndividual(): void {
    let addonPriceId: Array<any> = [];

    this.productAddOnList.forEach((element) => {
      addonPriceId.push({
        addonId: element.addOnsId,
        addonQuantity: element.startingUnit.toString(),
      });
    });

    if (this.isPlanUpgraded) {
      this.payNowData = {
        subscriptionId: this.subscriptionId,
        planPriceId: this.planPriceId,
        addonPriceId: addonPriceId,
        redirectionUrl:
          window.location.origin.toString() + RoutingPath.Products,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: this.planData.cf_employees,
        cf_users: this.planData.cf_users,
        cf_clients: this.planData.cf_clients,
        cf_charityclients: this.planData.cf_charityclients,
        cf_timenfeeclients: this.planData.cf_timenfeeclients,
        cf_bookkeepingclients: this.planData.cf_bookkeepingclients,
        cf_corporationtaxclients: this.planData.cf_corporationtaxclients,
        cf_samtdclients: this.planData.cf_samtdclients
      };
    } else {
      this.payNowData = {
        customerId: this.globalComponent.customerId(),
        planPriceId: this.planPriceId,
        redirectionUrl:
          window.location.origin.toString() + RoutingPath.Products,
        addonPriceId: addonPriceId,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: this.planData.cf_employees,
        cf_users: this.planData.cf_users,
        cf_clients: this.planData.cf_clients,
        cf_charityclients: this.planData.cf_charityclients,
        cf_timenfeeclients: this.planData.cf_timenfeeclients,
        cf_bookkeepingclients: this.planData.cf_bookkeepingclients,
        cf_corporationtaxclients: this.planData.cf_corporationtaxclients,
        cf_samtdclients: this.planData.cf_samtdclients,
        frequency: this.frequency,
      };
    }
  }

  dataSubmitForFullSuite(): void {
    let addonPriceId: Array<any> = [];

    this.productAddOnList.forEach((element) => {
      // console.log(element)
      if (element.itemId === '35' || element.itemId === '36') {
        addonPriceId.push({
          addonId: element.id,
          addonQuantity: '1',
        });
      }
      if (element.itemId === '35') {
        this.timenFeeClients = this.planData.cf_timenfeeclients;
      } else if (element.itemId === '36') {
        this.charityClients = this.planData.cf_charityclients;
      }
    });

    if (this.isPlanUpgraded) {
      this.payNowData = {
        subscriptionId: this.subscriptionId,
        planPriceId: this.planPriceId,
        addonPriceId: addonPriceId,
        subscribtionsType: this.subscribtionsType,
        redirectionUrl:
          window.location.origin.toString() + RoutingPath.Products,
        cf_employees: this.planData.cf_employees,
        cf_users: this.planData.cf_users,
        cf_clients: this.planData.cf_clients,
        cf_charityclients: this.charityClients,
        cf_timenfeeclients: this.timenFeeClients,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_bookkeepingclients: this.planData.cf_bookkeepingclients,
        cf_corporationtaxclients: this.planData.cf_corporationtaxclients,
        cf_samtdclients: this.planData.cf_samtdclients
      };
    } else {
      this.payNowData = {
        customerId: this.globalComponent.customerId(),
        planPriceId: this.planPriceId,
        addonPriceId: addonPriceId,
        redirectionUrl:
          window.location.origin.toString() + RoutingPath.Products,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: this.planData.cf_employees,
        cf_users: this.planData.cf_users,
        cf_clients: this.planData.cf_clients,
        cf_charityclients: this.charityClients,
        cf_timenfeeclients: this.timenFeeClients,
        cf_bookkeepingclients: this.planData.cf_bookkeepingclients,
        cf_corporationtaxclients: this.planData.cf_corporationtaxclients,
        cf_samtdclients: this.planData.cf_samtdclients,
        frequency: this.frequency,
      };
    }
  }

  dataSubmitForCapiumAddons(): void {
    let addonPriceId: Array<any> = [];

    this.productAddOnList.forEach((element) => {
      addonPriceId.push({
        addonId: element.addOnsId,
        addonQuantity:
          element.addOnsId === CapiumAddons.BankFeeds ||
          element.addOnsId === CapiumAddons.BankFeedsYearly ||
          element.addOnsId === CapiumAddons.BankFeedsQuarterly ||
          element.addOnsId === Capium365._365LicensesGBPMonthly||
          element.addOnsId === Capium365._365LicensesGBPQuarterly||
          element.addOnsId === Capium365._365LicensesGBPYearly
            ? element.clientsRange.toString()
            : element.startingUnit.toString(),
      });
    });

    if (this.isPlanUpgraded) {
      this.payNowData = {
        subscriptionId: this.subscriptionId,
        planPriceId: this.planPriceId,
        addonPriceId: addonPriceId,
        redirectionUrl: window.location.origin.toString() + this.router.url,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: 0,
        cf_users: 0,
        cf_clients: 0,
        cf_charityclients: 0,
        cf_timenfeeclients: 0,
        cf_bookkeepingclients: 0,
        cf_corporationtaxclients: 0,
        cf_samtdclients: 0
      };
    } else {
      this.payNowData = {
        customerId: this.globalComponent.customerId(),
        planPriceId: this.planPriceId,
        redirectionUrl: window.location.origin.toString() + this.router.url,
        addonPriceId: addonPriceId,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: 0,
        cf_users: 0,
        cf_clients: 0,
        cf_charityclients: 0,
        cf_timenfeeclients: 0,
        cf_bookkeepingclients: 0,
        cf_corporationtaxclients: 0,
        cf_samtdclients:0,
        frequency: this.frequency,
      };
    }
  }

  dataSubmitForSme(): void {
    let addonPriceId: Array<any> = [];

    this.productAddOnList.forEach((element) => {
      addonPriceId.push({
        addonId: element.addOnsId,
        addonQuantity: '1',
      });
    });

    if (this.isPlanUpgraded) {
      this.payNowData = {
        subscriptionId: this.subscriptionId,
        planPriceId: this.planPriceId,
        addonPriceId: addonPriceId,
        redirectionUrl:
          window.location.origin.toString() + RoutingPath.Products,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: this.planData.cf_employees,
        cf_users: this.planData.cf_users,
        cf_clients: this.planData.cf_clients,
      };
    } else {
      this.payNowData = {
        customerId: this.globalComponent.customerId(),
        planPriceId: this.planPriceId,
        redirectionUrl:
          window.location.origin.toString() + RoutingPath.Products,
        addonPriceId: addonPriceId,
        subscribtionsType: this.subscribtionsType,
        cancelUrl: window.location.origin.toString() + RoutingPath.Cart,
        cf_employees: 0,
        cf_users: 0,
        cf_clients: 0,
        frequency: this.frequency,
      };
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      if (!this.isSME) {
        if (this.subscribtionsType === SubscribtionsType.Indidvidual) {
          this.dataSubmitForIndividual();
        } else if (this.subscribtionsType === SubscribtionsType.FullSuite) {
          this.dataSubmitForFullSuite();
        } else if (this.subscribtionsType === SubscribtionsType.CapiumAddons ||
          this.subscribtionsType === SubscribtionsType.Capium365
        ) {
          this.dataSubmitForCapiumAddons();
        }
      } else {
        this.dataSubmitForSme();
      }
    } catch (error) {
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onPayNow(): void {
    if (this.dataSubmit()) {
      console.log(this.payNowData);
      if (!this.isSME) {
        let actionName;
        actionName = this.isPlanUpgraded
          ? UpgradePlanForSubscribtion
          : PayNowForSubscribtion;
        this.store.dispatch(new actionName(this.payNowData)).subscribe({
          next: (res) => {
            if (res !== undefined) {
              window.open(res.product.payNowResposeURL, '_self');
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          error: (error) => {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage + error.valueResult
            );
          },
        });
      }else
      {
      this.store.dispatch(new PayNowForSmeSubscription(this.payNowData)).subscribe({
        next: (res) => {
          console.log(res);
          if (res !== undefined) {
            window.open(res.smeproduct.payNowResposeURL, '_self');
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        error: (error) => {
          this.notifier.error(
            NotificationHeader.error,
            NotificationTextMessage.errorMessage + error.valueResult
          );
        },
      });
      }
    }
  }

  onContinueShopping(): void {
    this.storage.set('frequncy',this.frequency);
    if (this.subscribtionsType === SubscribtionsType.CapiumAddons
      ||this.subscribtionsType === SubscribtionsType.FullSuite ||
      this.subscribtionsType === SubscribtionsType.Capium365

    ) {
      this.storage.removeItem('cartDetails');
      this.globalComponent.totalCartItem.next(0);
    }
    if (this.isSME) {
      this.storage.removeItem('cartDetails');
      this.globalComponent.totalCartItem.next(0);
    }
    this.router.url === RoutingPath.Products
      ? this.toggleReviewOrder.emit()
      : this.router.navigate([RoutingPath.Products]);
  }

  goToProductPage(): void {
    this.globalComponent.totalCartItem.next(0);
    this.router.url === RoutingPath.Products
      ? this.toggleReviewOrder.emit()
      : this.router.navigate([RoutingPath.Products]);

    localStorage.clear();
  }
}
