import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NotificationTextMessage, modulesName } from '@app/core/enums';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  SendEmailForBookDemoModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import { SendEmailForBookADemo } from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxSpinnerService } from 'ngx-spinner';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-dialog-contact-form',
  templateUrl: './dialog-contact-form.component.html',
  styleUrls: ['./dialog-contact-form.component.scss'],
})
export class DailogContactFormComponent implements OnInit {
  @ViewChild('selectmodulename') selectmodulename: MatSelect;

  selectedFile: File[] = [];
  contactForm: FormGroup;
  sendEmailForBookADemo: SendEmailForBookDemoModel;
  environment = environment;
  userData: any;
  allSelected = false;
  moduleList = modulesName;
  isImageSelected = false;
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  fileList: any[] = [];
  selectedFiles: FileList;
  filesForm: FormGroup;
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private store: Store,
    public globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<DailogContactFormComponent>,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.setForm();
  }

  cancelForm(): void {
    this.setForm();
    this.selectedFile = [];
    this.fileList = [];
  }

  setForm(): void {
    const token = this.oidcSecurityService.getToken();
    const decoded: any = jwt_decode(token);
    this.userData = decoded;
    this.contactForm = this.fb.group({
      customerid: [this.userData?.CustomerId, Validators.required],
      customername: [this.userData?.name, Validators.required],
      modulename: [[this.data.moduleId], Validators.required],
      emailid: [
        this.userData?.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      phonenumber: ['', [Validators.required]],
      msg: ['', Validators.required],
      subject: ['', [Validators.required]],
    });

    this.contactForm.controls.customerid.disable();
    this.contactForm.controls.customername.disable();
    this.contactForm.controls.modulename.disable();
    this.contactForm.controls.emailid.disable();
  }

  sendEmail(): void {
    if (this.contactForm.invalid) {
      this.commonService.addValidation(this.contactForm);
    } else if (this.dataSubmit()) {
      this.store
        .dispatch(new SendEmailForBookADemo(this.sendEmailForBookADemo))
        .subscribe(
          (x) => {
            if (x.product.isEmailSendForBookDemo) {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
              this.ngOnInit();
              this.dialogRef.close();
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (error) => {
            this.spinner.hide();
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        );
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.selectmodulename.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectmodulename.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  addonsClick() {
    let newStatus = true;

    this.selectmodulename.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.sendEmailForBookADemo = {
        name: this.contactForm.controls.customername.value,
        emailId: this.contactForm.controls.emailid.value,
        phoneNumber: this.contactForm.controls.phonenumber.value,
        clientName: this.contactForm.controls.customername.value,
        supportTopic: 'Support Group',
        modules: this.contactForm.controls.modulename.value,
        subject: this.contactForm.controls.subject.value,
        message: this.contactForm.controls.msg.value,
        externalUserGuid: this.userData.ExternalUserGUID,
        file: this.selectedFile,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onBookADemoClick(): void {
    window.open(
      `https://liveengage247.myclients.io/site/6a8450de184e8b36/online-scheduling?action=etfv4etpwqb12giy`,
      '_blank'
    );
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
        ) {
          if (file.size / 1024 / 1024 < 2) {
            this.fileList.push(file);
            this.selectedFile = this.fileList;
          } else {
            this.commonService.onFailure(NotificationTextMessage.fileSize2mb);
          }
        } else {
          this.commonService.onFailure(NotificationTextMessage.fileJpgPng);
        }
      });
    }
  }

  onCancel(): void {
    this.filesForm.reset();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  removeFile(index: any): void {
    this.fileList.splice(index, 1);
    this.fileUploadResponse.splice(index, 1);
  }
}
