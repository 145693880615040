<div class="payment-card-details" *ngIf="!isShowAddressForm">
  <div class="heading mb-2">
    <h3>Saved Addresses</h3>
  </div>
  <div class="d-flex gap-20">
    <div class="card">
      <div class="d-flex gap-10">
        <div>
          <mat-icon
            fontSet="material-icons-outlined"
            class="text-primary icon-bg"
            >home</mat-icon
          >
        </div>
        <div>
          <p class="text-primary font-size-18 fw-bold">Billing Address</p>
          <hr />
          <p>
            22 - Adam Street, West Field Road, Near Foundation Zone, Finberg,
            London, UK - 320422
          </p>
        </div>
      </div>
    </div>
    <div
      class="card d-flex align-items-center justify-content-center flex-column add-new-card"
      (click)="onAddClick()"
    >
      <mat-icon class="text-gray-A100">add</mat-icon>
      <p class="mt-5 mb-0 text-gray-A100">Add New Address</p>
    </div>
  </div>
</div>
<div *ngIf="isShowAddressForm">
  <app-payment-address-form></app-payment-address-form>
</div>
