<div class="main-contain">
  <div class="header-wrapper show-header">
    <div class="navigation-container">
      <app-header></app-header>
    </div>
  </div>
  <div class="content-body-wrapper show-header content-body-scroll side-drawer">
    <mat-drawer-container>
      <mat-drawer #sideNav mode="push" position="end">
        <div
          class="contact-title d-flex justify-space-between align-items-center"
        >
          <h2>Speak to Sales Team</h2>
          <button class="action-button" mat-button (click)="sideNav.close()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <app-contact-form [moduleId]="moduleId" (formSubmitted)="closeForm($event)"></app-contact-form>
      </mat-drawer>
      <mat-drawer-content>
        <router-outlet></router-outlet>
        <div class="d-flex justify-content-end pr-15">
          <button
            (click)="triggerPopup()"
            mat-button
            class="primary-button mt-1 connect-with-button"
          >
            <mat-icon fontSet="material-icons-outlined">comment</mat-icon>
            Chat with us!
          </button>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
    <app-custom-notifications></app-custom-notifications>
  </div>
</div>
<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/img/loader.svg' />
  <span class='loader'></span>"
></ngx-spinner>
