import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPortalService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    public globalComponent: GlobalComponent
  ) {}

  getSubscriptionPortalDetails(): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }Subscriptions/RetrieveSubscriptionByCustomerIdAsync?customerId=${this.globalComponent.customerId()}`
    );
  }

  getSubscriptionPortalForCapiumAddonsDetails(): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }CapiumAddons/RetrieveCapiumAddonsSubscriptionByCustomerIdAsync?customerId=${this.globalComponent.customerId()}`
    );
  }

  getSubscriptionPortalForCapium365(userName: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Capium/RetrieveCapium365SubscriptionByCustomerIdAsync?customerId=${this.globalComponent.customerId()}&username=${userName}`
    );
  }
}
