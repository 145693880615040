export enum NotificationHeader {
  deleteConfirmation = 'Confirm delete',
  terminateConfirmation = 'Terminate Process',
  success = 'Success!',
  error = 'Error!',
  failure = 'Failure!',
  confirmationText = 'Confirm Plan Change',
  removeFromCart = 'Remove',
  confirmCancellingSubscription = 'Confirm Cancelling subscription',
}

export enum NotificationDetails {
  terminateDetailText = 'Sure, you want to remove from <br/> the cart? ',
  deleteAllDetailText = 'Please confirm you want to delete these things. Deleting cannot be undone.',
  confirmationDetailText = 'When your next billing cycle begins, your subscription <br/> plan will be updated. You can enjoy the benefit of <br/> all the features of your current plan until then.',
  deletePaymentCardDetailText = 'The processing of any outstanding charges on this <br/>  card will continue even after you remove it.  <br/> Do you wish to remove this card? <br/> <br/> or else <br/>  <br/> To be able to deduct money from the new card,  <br/> add another card and set it as the default.',
  deletePaymentBankDetailText = 'The processing of any outstanding charges on this <br/>  bank will continue even after you remove it.  <br/> Do you wish to remove this bank? <br/> <br/> or else <br/>  <br/> To be able to deduct money from the new bank,  <br/> add another bank and set it as the default.',
  confirmCancellingSubscriptionDetailText = 'When your next billing cycle begins, your <br/> subscription will be cancelled. You can <br/> enjoy the benefit of all the features of your <br/> current plan until then.',
}

export enum NotificationTextMessage {
  successMessage = 'Your request sent successfully.',
  cardSuccessMessage = 'Card added successfully.',
  cardUpdatedSuccessMessage = 'Card updated successfully.',
  bankSuccessMessage = 'Bank added successfully.',
  bankUpdatedSuccessMessage = 'Bank updated successfully.',
  errorMessage = 'There might be some error occured!',
  invalidCoupon = 'Invalid coupon, please add valid coupon!',
  noRecordFoundText = 'No record found.',
  recordDeletedSuccessfully = 'Record deleted successfully.',
  cardDeletedSuccessfully = 'Card deleted successfully.',
  bankDeletedSuccessfully = 'Bank deleted successfully.',
  couponAddedSuccessfully = 'Coupon added successfully.',
  paymentSuccessful = 'Payment done successfully.',
  paymentFailed = 'Payment failed.',
  noDataFound = 'No data found.',
  invalidPeriodicYear = 'Invalid periodic year',
  subCancelledSuccessfully = 'Subscription cancelled successfully. <br/> Your subscription will not get renew after current subscription is over.',
  fileSize2mb = 'File should be less than 2 MB',
  fileJpgPng = 'File should be .png or .jpg extension',
}
