import { PaymentCardModel, PaymentBankModel } from '@app/core/models';

export class GetPaymentList {
  static readonly type = '[PaymentMethod] Get Payment Details List';
}

export class GetCountryList {
  static readonly type = '[PaymentMethod] Get Country List';
}

export class SavePaymentCardDetails {
  static readonly type = '[PaymentMethod] Save Payment Method';
  constructor(public queryParams: PaymentCardModel) {}
}

export class UpdatePaymentCardDetails {
  static readonly type = '[PaymentMethod] Update Payment Method';
  constructor(public queryParams: PaymentCardModel) {}
}

export class DeletePaymentCard {
  static readonly type = '[PaymentMethod] Delete Payment Method';
  constructor(public paymentCardId: string) {}
}

export class RetrieveHostedPage {
  static readonly type = '[PaymentMethod] Retrieve Hosted Page';
  constructor(
    public paymentMethodId: string,
    public subscriptionId: string,
    public isTrailPeriod: boolean,
    public IsCancelTrial:boolean
  ) {}
}

export class RetrieveSmeHostedPage {
  static readonly type = '[PaymentMethod] Retrieve Sme Hosted Page';
  constructor(
    public paymentMethodId: string,
    public subscriptionId: string,
    public isTrailPeriod: boolean
  ) {}
}

export class SaveBankDetails {
  static readonly type = '[BankList] Save Bank Method';
  constructor(public queryParams: PaymentBankModel) {}
}

export class UpdateBankDetails {
  static readonly type = '[BankList] Update Bank Method';
  constructor(public queryParams: PaymentBankModel) {}
}

export class GetSmePaymentList {
  static readonly type = '[PaymentMethod] Get Sme Payment Details List';
}

export class SaveSmePaymentCardDetails {
  static readonly type = '[PaymentMethod] Save Sme Payment Method';
  constructor(public queryParams: PaymentCardModel) {}
}

export class UpdateSmePaymentCardDetails {
  static readonly type = '[PaymentMethod] Update Sme Payment Method';
  constructor(public queryParams: PaymentCardModel) {}
}

export class DeleteSmePaymentCard {
  static readonly type = '[PaymentMethod] Delete Sme Payment Method';
  constructor(public paymentCardId: string) {}
}

export class SaveSmeBankDetails {
  static readonly type = '[BankList] Save Sme Bank Method';
  constructor(public queryParams: PaymentBankModel) {}
}

export class UpdateSmeBankDetails {
  static readonly type = '[BankList] Update Sme Bank Method';
  constructor(public queryParams: PaymentBankModel) {}
}
