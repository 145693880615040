<div class="d-flex justify-space-between align-items-center heading">
  <div>
    <h3 class="pb-5 no-border">Add New Bank</h3>
  </div>
  <button class="action-button" mat-button (click)="cancel()">
    <div>
      <mat-icon>close</mat-icon>
    </div>
  </button>
</div>
<div class="card payment-card-form form-width">
  <div class="mat-dialog-wrapper form-list-content pb-0">
    <div class="contact-title">
      <h2>Enter Bank details</h2>
    </div>
    <form class="mt-1" [formGroup]="bankForm">
      <div>
        <div *ngIf="!isCompany" class="form-field d-flex gap-20 w-387">
          <div class="flex-1">
            <p>First Name <span class="text-danger-300">*</span></p>
            <mat-form-field class="wp-100">
              <input
                matInput
                formControlName="firstName"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="flex-1">
            <p class="d-flex">
              Last Name <span class="text-danger-300">*</span>
            </p>
            <mat-form-field class="wp-100">
              <input
                matInput
                formControlName="lastName"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="form-field">
          <mat-checkbox
            class="p-0"
            [(ngModel)]="isCompany"
            (change)="isCompanySelected($event)"
            >Add Company Name
          </mat-checkbox>
        </div>

        <div *ngIf="isCompany" class="form-field">
          <p>Company Name <span class="text-danger-300">*</span></p>
          <mat-form-field class="w-387">
            <input
              matInput
              maxlength="100"
              formControlName="companyName"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <div class="form-field">
          <p>Email</p>
          <mat-form-field class="w-387">
            <input matInput formControlName="email" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field d-flex gap-20">
          <div class="flex-1">
            <p>Country <span class="text-danger-300">*</span></p>

            <ng-select
              class="w-387"
              #country
              (focus)="country.open()"
              (valueChange)="country.close()"
              formControlName="country"
              appAutoFocusDirective
            >
              <ng-option *ngFor="let clt of countryList" [value]="clt.isO2">
                <div title="{{ clt.name }}">{{ clt.name }}</div>
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="form-field">
          <p>Account Number <span class="text-danger-300">*</span></p>
          <mat-form-field class="w-387">
            <input
              matInput
              maxlength="8"
              formControlName="accountNumber"
              autocomplete="off"
              required
            />
          </mat-form-field>
        </div>

        <div class="form-field">
          <p>Branch Code</p>
          <mat-form-field class="w-387">
            <input
              matInput
              maxlength="6"
              formControlName="branchCode"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <div class="form-field" *ngIf="showSetAsPrimary">
          <mat-checkbox class="p-0" formControlName="setAsPrimary"
            >Set this bank as default
          </mat-checkbox>
        </div>
      </div>
    </form>
    <div class="contact-button">
      <button
        class="action-button primary-button mr-1"
        mat-button
        disableRipple
        (click)="save()"
      >
        Save
      </button>
      <button
        class="action-button secondary-button"
        mat-button
        disableRipple
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
