<div [ngClass]="{ 'readonly-card-warpper': isProductDisabled }">
  <div [ngClass]="{ 'readonly-card': isProductDisabled }">
    <div *ngIf="!isPaymentDone" class="sidenav-body-content pt-10">
      <div class="product-list">
        <ng-container *ngIf="!isSME">
          <mat-tab-group
            disableRipple
            (selectedTabChange)="onTabClick($event.index)"
            [selectedIndex]="selectedTab"
            *ngIf="!isShowReviewOrder"
          >
            <mat-tab label="Full Suite">
              <div class="mt-1">
                <app-product-full-suite
                  (toggleReviewOrder)="toggleReviewOrder($event)"
                  [subscribtionsType]="selectedTab"
                  [frequency]="frequency"
                ></app-product-full-suite>
              </div>
            </mat-tab>

            <mat-tab label="Individual">
              <div class="mt-1">
                <app-product-individual
                  *ngIf="selectedTab === 1"
                  (toggleReviewOrder)="toggleReviewOrder($event)"
                  [frequency]="frequency"
                  [subscribtionsType]="selectedTab"
                ></app-product-individual>
              </div>
            </mat-tab>

            <mat-tab label="Addons">
              <div class="mt-1">
                <app-product-addons
                  *ngIf="selectedTab === 2"
                  (toggleReviewOrder)="toggleReviewOrder($event)"
                  [subscribtionsType]="selectedTab"
                  [globalFrequency]="addonFrequency"
                ></app-product-addons>
              </div>
            </mat-tab>
            <mat-tab label="Capium 365">
              <div class="mt-1">
                <app-product-capium365
                  *ngIf="selectedTab === 3"
                  (toggleReviewOrder)="toggleReviewOrder($event)"  
                  [subscribtionsType]="selectedTab" 
                  [globalFrequency]="capium365Frequency"               
                ></app-product-capium365>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-container *ngIf="isSME">
          <mat-tab-group *ngIf="!isShowReviewOrder">
            <mat-tab label="SME Product">
              <div class="mt-1">
                <app-product-sme
                  *ngIf="isSMEShow"
                  (toggleReviewOrder)="toggleReviewOrder($event)"
                  [frequency]="frequency"
                  [subscribtionsType]="selectedTab"
                ></app-product-sme>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <div
          class="package-selection"
          *ngIf="!isShowReviewOrder && selectedTab !== 2 &&  selectedTab !== 3 && !isSME "
        >
          <div class="align-items-center gap-10 pt-10 d-flex">
            <mat-button-toggle-group
              value="{{ frequency }}"
              name="fontStyle"
              aria-label="Font Style"
              (change)="onFrequencyChange($event,true)"
              [disabled]="disableButton"
            >
              <mat-button-toggle value="{{ periodUnitType.Month }}"
                >Bill monthly
              </mat-button-toggle>
              <mat-button-toggle value="{{ periodUnitType.Quarterly }}"
                >Bill quarterly</mat-button-toggle
              >
              <mat-button-toggle value="{{ periodUnitType.Year }}"
                >Bill yearly</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div *ngIf="isShowReviewOrder ">
          <app-product-review-order
            [isPlanUpgraded]="isPlanUpgraded"
            (toggleReviewOrder)="toggleReviewOrder($event)"
            [subscribtionsType]="selectedTab"
            [frequency]="frequency"
            [isSME]="isSME"
          ></app-product-review-order>
        </div>
      </div>
    </div>
    <div *ngIf="isPaymentDone">
      <app-payment-successfull
      [subscribtionsType]="selectedTab"></app-payment-successfull>
    </div>
  </div>
</div>
