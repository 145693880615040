import { Component, OnInit } from '@angular/core';
import { NotificationType, Notification } from '@app/core/models';
import { NotificationService } from '@app/core/services/common/notification-service.service';

@Component({
  selector: 'app-custom-notifications',
  templateUrl: './custom-notifications.component.html',
  styleUrls: ['./custom-notifications.component.scss'],
})
export class CustomNotificationsComponent implements OnInit {
  notifications: any = [];
  icon: string;

  constructor(public _notificationService: NotificationService) {}

  ngOnInit(): void {
    this._notificationService.getAlert().subscribe((alert: Notification) => {
      this.notifications = [];
      if (!alert) {
        this.notifications = [];
        return;
      }
      this.notifications.push(alert);
      setTimeout(() => {
        this.notifications = this.notifications.filter((x) => x !== alert);
      }, 4000);
    });
  }

  removeNotification(notification: Notification): any {
    this.notifications = this.notifications.filter((x) => x !== notification);
  }

  close(): void {
    this.removeNotification(this.notifications[0]);
  }

  cssClass(notification: Notification): any {
    switch (notification.type) {
      case NotificationType.Success:
        this.icon = 'check_circle';
        return 'success';
      case NotificationType.Error:
        this.icon = 'error';
        return 'danger';
      case NotificationType.Info:
        return 'info';
      case NotificationType.Warning:
        return 'warning';
    }
  }
}
