import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { LocalStorageService, NotificationService } from '@app/core/services';
import { environment } from '@environments/environment';
import {
  AddOnsId,
  AddOnsName,
  CapiumAddons,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  PlanTypeEnumForCapiumAddons,
  PlanTypeEnumForIndividualAddons,
  SubscriptionsEnum,
  modulesName,
} from '@app/core/enums';
import {
  ExportParams,
  GlobalComponent,
  TshqRequestOptions,
} from '@app/core/models/common';
import * as moment from 'moment';
import { FileUploadRequestModel } from '@app/core/models';

@Injectable()
export class CommonService {
  public triggerConnectUs: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(
    private http: HttpClient,
    private notifier: NotificationService,
    public globalComponent: GlobalComponent,
    public storage: LocalStorageService
  ) {}

  currentURl = 'home/products';

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  storeCartItemDataLocally(cartItemList?: any): void {
    let count = 0;
    if (cartItemList !== undefined) {
      cartItemList.forEach((x) => {
        if (x.isAddedToCart) count++;
      });

      this.storage.set('cartDetails', cartItemList);
      this.globalComponent.totalCartItem.next(count);
    }

    if (count === 0) {
      this.storage.removeItem('cartDetails');
      this.globalComponent.totalCartItem.next(count);
    }
  }

  print(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
  }

  download(response: HttpResponse<Blob>, fileName: string): void {
    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  export(exportParams: ExportParams, moduleId: Modules): Observable<any> {
    let url = 'Invoices/RetrieveInvListByCustomerId';

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(exportParams),
      headers
    ).pipe(
      switchMap((response) => {
        const body: Blob = response.body || new Blob();
        if (exportParams.isPrint) {
          this.print(body);
        } else {
          this.download(response, 'downloadedFile');
        }
        return of(true);
      })
    );
  }

  addValidation(form: any): void {
    (Object as any).values(form.controls).forEach((c) => c.markAsTouched());
  }

  onSucess(message?: any): void {
    this.notifier.success(
      NotificationHeader.success,
      message === null ? NotificationTextMessage.successMessage : message
    );
  }

  onFailure(errorMessage: any): void {
    this.notifier.error(NotificationHeader.error, errorMessage);
  }

  updateThemeColor(themecolor: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/updateThemeColor`,
      JSON.stringify(themecolor),
      headers
    );
  }

  getImagePathForFullSuite(id): any {
    switch (+id) {
      case modulesName['Time & Fee']:
        return `${environment.imagePath}/time n fee.svg`;

      case modulesName.Charity:
        return `${environment.imagePath}/charity.svg`;

      case modulesName['Self Assessment']:
        return `${environment.imagePath}/Self assesment.svg`;

      case modulesName['Corporation Tax']:
        return `${environment.imagePath}/corporationtax.svg`;

      case modulesName['Accounts Production']:
        return `${environment.imagePath}/ap.svg`;

      case modulesName['Practice Management']:
        return `${environment.imagePath}/Practice managment.svg`;

      case modulesName['Advanced Payroll']:
        return `${environment.imagePath}/payroll.svg`;

      case modulesName['Bookkeeping MTD']:
        return `${environment.imagePath}/bk.svg`;

      case modulesName.Capisign:
        return `${environment.imagePath}/capidrive.svg`;

      case modulesName['Company Secretarial']:
        return `${environment.imagePath}/cs.svg`;

      case modulesName.BankFeeds:
        return `${environment.imagePath}/bankfeed.svg`;

      case modulesName['Capium Hub']:
        return `${environment.imagePath}/hub.svg`;

      case modulesName.SMS:
        return `${environment.imagePath}/sms.svg`;     

      case modulesName['Corporation Tax New']:
        return `${environment.imagePath}/corporationtax.svg`;

      case modulesName['Bookkeeping New']:
        return `${environment.imagePath}/bk.svg`; 
        
      case modulesName.SAMTD:
        return `${environment.imagePath}/Self assesment.svg`; 

      default:
        return `${environment.imagePath}/account-detail.svg`;
    }
  }

  getImagePath(addOnsId): any {
    switch (addOnsId) {
      case AddOnsName.TimeAndFee:
      case AddOnsName.TimeAndFeeYearly:
      case AddOnsName.TimeAndFeeQuarterly:
        return `${environment.imagePath}/time n fee.svg`;

      case AddOnsName.Charity:
      case AddOnsName.CharityYearly:
      case AddOnsName.CharityQuarterly:
        return `${environment.imagePath}/charity.svg`;

      case AddOnsName.SelfAssessment:
      case AddOnsName.SelfAssessmentYearly:
      case AddOnsName.SelfAssessmentQuarterly:
        return `${environment.imagePath}/Self assesment.svg`;

      case AddOnsName.CorporationTax:
      case AddOnsName.CorporationTaxYearly:
      case AddOnsName.CorporationTaxQuarterly:
        return `${environment.imagePath}/corporationtax.svg`;

      case AddOnsName.AccountsProduction:
      case AddOnsName.AccountsProductionYearly:
      case AddOnsName.AccountsProductionQuarterly:
        return `${environment.imagePath}/ap.svg`;

      case AddOnsName.PracticeManagement:
      case AddOnsName.PracticeManagementYearly:
      case AddOnsName.PracticeManagementQuarterly:
        return `${environment.imagePath}/Practice managment.svg`;

      case AddOnsName.AdvancePayroll:
      case AddOnsName.AdvancePayrollYearly:
      case AddOnsName.AdvancePayrollQuarterly:
        return `${environment.imagePath}/payroll.svg`;

      case AddOnsName.BookeepingMTD:
      case AddOnsName.BookeepingMTDYearly:
      case AddOnsName.BookeepingMTDQuarterly:
        return `${environment.imagePath}/bk.svg`;

      case CapiumAddons.Capisign:
      case CapiumAddons.CapisignYearly:
      case CapiumAddons.CapisignQuarterly:
        return `${environment.imagePath}/capidrive.svg`;

      case CapiumAddons.CompanySecretarial:
      case CapiumAddons.CompanySecretarialYearly:
      case CapiumAddons.CompanySecretarialQuarterly:
        return `${environment.imagePath}/cs.svg`;

      case CapiumAddons.BankFeeds:
      case CapiumAddons.BankFeedsYearly:
      case CapiumAddons.BankFeedsQuarterly:
        return `${environment.imagePath}/bankfeed.svg`;

      case CapiumAddons.CapiumHubGBPMonthly:
      case CapiumAddons.CapiumHubGBPYearly:
      case CapiumAddons.CapiumHubGBPQuarterly:
        return `${environment.imagePath}/hub.svg`;

      case CapiumAddons.SMS:
      case CapiumAddons.SMSYearly:
      case CapiumAddons.SMSQuarterly:
        return `${environment.imagePath}/sms.svg`;
      
      case AddOnsName.BookkeepingNew:
      case AddOnsName.BookkeepingNewQuarterly:
      case AddOnsName.BookkeepingNewYearly:
        return `${environment.imagePath}/bk.svg`;

      case AddOnsName.CorporationTaxNew:
      case AddOnsName.CorporationTaxNewQuarterly:
      case AddOnsName.CorporationTaxNewYearly:
        return `${environment.imagePath}/corporationtax.svg`;

      case AddOnsName.SAMTD:
      case AddOnsName.SAMTDQuarterly:
      case AddOnsName.SAMTDYearly:
        return `${environment.imagePath}/Self assesment.svg`;

      case AddOnsName._365Licenses:
      case AddOnsName._365LicensesQuarterly:
      case AddOnsName._365LicensesYearly:
      return `${environment.imagePath}/cp 1.svg`;
       

      default:
        return `${environment.imagePath}/account-detail.svg`;
    }
  }

  getSubscriptionsName(id): any {
    switch (+id) {
      case SubscriptionsEnum.trail:
        return `Trial`;

      case SubscriptionsEnum.fullSuiteSmall:
      case SubscriptionsEnum.fullSuiteMedium:
      case SubscriptionsEnum.fullSuiteLarge:
      case SubscriptionsEnum.fullSuiteMicro:
      case SubscriptionsEnum.fullSuiteMicroBite:
      case SubscriptionsEnum.fullSuiteEnterprise:
        return `Full Suite`;

      case SubscriptionsEnum.capiumAddOn:
        return `Capium Addons`;

      case AddOnsId.TimeAndFee:
      case AddOnsId.Charity:
      case AddOnsId.SelfAssessment:
      case AddOnsId.CorporationTax:
      case AddOnsId.AccountsProduction:
      case AddOnsId.PracticeManagement:
      case AddOnsId.AdvancePayroll:
      case AddOnsId.BookeepingMTD:
      case AddOnsId.Individual:
      case AddOnsId.CorporationTaxNew:
      case AddOnsId.BookkeepingNew:
      case AddOnsId.SAMTD:
        return `Individual`;

      default:
        return `Trial`;
    }
  }

  getIndividualPlanName(planId): string {
    switch (+planId) {
      case SubscriptionsEnum.small:
      case PlanTypeEnumForCapiumAddons.small:
        return 'Small';
      case SubscriptionsEnum.medium:
      case PlanTypeEnumForCapiumAddons.medium:
        return 'Medium';
      case SubscriptionsEnum.large:
      case PlanTypeEnumForCapiumAddons.large:
        return 'Large';
      case SubscriptionsEnum.unlimited:
      case PlanTypeEnumForCapiumAddons.unlimited:
        return 'Enterprise';

      default:
        return 'Small';
    }
  }

  getSMSPlanName(planId): string {
    switch (+planId) {
      case SubscriptionsEnum.small:
      case PlanTypeEnumForCapiumAddons.small:
        return 'Small';
      case SubscriptionsEnum.medium:
      case PlanTypeEnumForCapiumAddons.mediumSMS:
        return 'Medium';
      case SubscriptionsEnum.large:
      case PlanTypeEnumForCapiumAddons.largeSMS:
        return 'Large';
      case PlanTypeEnumForCapiumAddons.unlimitedSMS:
        return 'Enterprise';

      default:
        return 'Small';
    }
  }

  getCSSPlanName(planId): string {
    switch (+planId) {
      case SubscriptionsEnum.small:
      case PlanTypeEnumForCapiumAddons.smallCSS:
        return 'Small';
      case SubscriptionsEnum.medium:
      case PlanTypeEnumForCapiumAddons.mediumCSS:
        return 'Medium';
      case SubscriptionsEnum.large:
      case PlanTypeEnumForCapiumAddons.largeCSS:
        return 'Large';
      case SubscriptionsEnum.unlimited:
      case PlanTypeEnumForCapiumAddons.unlimitedCSS:
        return 'Enterprise';

      default:
        return 'Small';
    }
  }

  getHUBPlanName(planId): string {
    switch (+planId) {
      case SubscriptionsEnum.small:
      case PlanTypeEnumForCapiumAddons.smallHUB:
        return 'Small';
      case SubscriptionsEnum.medium:
      case PlanTypeEnumForCapiumAddons.mediumHUB:
        return 'Medium';
      case SubscriptionsEnum.large:
      case PlanTypeEnumForCapiumAddons.largeHUB:
        return 'Large';
      case SubscriptionsEnum.unlimited:
      case PlanTypeEnumForCapiumAddons.unlimitedHUB:
        return 'Enterprise';

      default:
        return 'Small';
    }
  }

  getCharityPlanName(planId): string {
    switch (+planId) {
      case SubscriptionsEnum.small:
      case PlanTypeEnumForIndividualAddons.small:
        return 'Small';
      case PlanTypeEnumForIndividualAddons.mediumCharity:
        return 'Medium';
      case PlanTypeEnumForIndividualAddons.largeCharity:
        return 'Large';
      case PlanTypeEnumForIndividualAddons.enterpriseCharity:
        return 'Enterprise';
      case PlanTypeEnumForIndividualAddons.xEnterpriseCharity:
        return 'X Enterprise';
      default:
        return 'Small';
    }
  }

  getTimeAndFeeAndCapisignPlanName(planId): string {
    switch (+planId) {
      case PlanTypeEnumForIndividualAddons.small:
        return 'Small';
      case PlanTypeEnumForIndividualAddons.medium:
        return 'Medium';
      case PlanTypeEnumForIndividualAddons.large:
        return 'Large';
      case PlanTypeEnumForIndividualAddons.unlimited:
        return 'Enterprise';
      default:
        return 'Small';
    }
  }

  getNoOfDays(
    isTrailPeriod: boolean,
    isSubscriptionCancelled: boolean,
    nextBillingDate,
    selectedSubscriptionList?: any
  ): any {
    if (isTrailPeriod && !isSubscriptionCancelled) {
      let date = new Date();
      let newdate;
      let billingdate;
      const currentDate = date.toISOString().split('T');
      if (currentDate.length) {
        newdate = currentDate[0];
      }

      const billdate = selectedSubscriptionList[0].nextBillingDate.split('T');
      if (billdate.length) {
        billingdate = billdate[0];
      }

      return moment(billingdate).diff(newdate, 'days');
    } else {
      return moment(nextBillingDate).diff(new Date(), 'days');
    }
  }

  getNextBillingDate(
    isTrailPeriod: boolean,
    isSubscriptionCancelled: boolean,
    nextBillingDate,
    selectedSubscriptionList?: any
  ): any {
    if (isTrailPeriod && !isSubscriptionCancelled) {
      let billingDate = new Date(selectedSubscriptionList[0].nextBillingDate);
      billingDate.setDate(billingDate.getDate());
      return billingDate;
    } else {
      let billingDate = new Date(nextBillingDate);
      return billingDate;
    }
  }

  getEnumNameFromValue(value: number, enumName: any): string | undefined {
    const enumEntries = Object.entries(enumName);
    const entry = enumEntries.find(([, x]) => x === value);
    return entry ? entry[0] : undefined;
  }
  fileUpload(fileUploadRequestModel: FileUploadRequestModel): Observable<any> {
    const formData = new FormData();

    fileUploadRequestModel.file.forEach((x) =>
      formData.append('files', x, x.name)
    );

    formData.append(
      'attachmentType',
      fileUploadRequestModel.attachmentType.toString()
    );

    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/multipleUpload`,
      formData
    );
  }
}
